<!-- 员工管理 -->
<template>
  <!--  -->
  <div class="staffManage_box">
    <!-- 卡片视图区域 -->
    <!-- 搜索与添加区域 -->
    <div class="header_search_box">
      <el-row :gutter="20">
        <!-- <el-col :span="8">
          <el-input placeholder="请输入内容" clearable v-model="searchInput" >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="4">
          <el-button type="primary" @click="dialogVisible = true"
            >添加员工</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div class="tabel_box">
      <div class="table_cont">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="tableMaxHeight"
          border
          size="medium"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <!-- <el-table-column prop="id" label="员工用户id"></el-table-column> -->
          <!-- <el-table-column prop="username" label="用户名"></el-table-column> -->
          <!-- <el-table-column prop="password" label="密码"></el-table-column> -->
          <el-table-column
            prop="phoneNumber"
            label="电话号码"
          ></el-table-column>
          <el-table-column prop="jobNumber" label="工号"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <!-- <el-table-column prop="companyId" label="公司id"></el-table-column> -->
          <!-- <el-table-column prop="attachment" label="图片"></el-table-column> -->

          <!-- <el-table-column fixed="right" prop="date" label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="showDetail(scope.$index, scope.row, 'detail')"
                >详情</el-button
              >
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column> -->

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="warning"
                size="mini"
                @click="viewUpdate(scope.row)"
                >修改</el-button
              >
              <el-button type="danger" size="mini" @click="del(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="page_box">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 新增 -->
      <el-dialog
        title="新增资产用户"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-form
          label-width="80px"
          :model="formAdd"
          ref="addForm"
          :rules="rules"
        >
          <el-form-item label="手机号" prop="phoneNumber">
            <el-input v-model="formAdd.phoneNumber" clearable></el-input>
          </el-form-item>

          <el-form-item label="工号" prop="jobNumber">
            <el-input v-model="formAdd.jobNumber" clearable></el-input>
          </el-form-item>

          <el-form-item label="姓名" prop="name">
            <el-input v-model="formAdd.name" clearable></el-input>
          </el-form-item>

          <!-- <el-form-item label="权限" prop="type">
            <el-select v-model="formAdd.type" placeholder="请选择">
              <el-option label="管理员" value="1"></el-option>
              <el-option label="普通用户" value="0"></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="addUser">确定</el-button>
        </span>
      </el-dialog>

      <!-- 修改 -->
      <el-dialog
        title="修改资产用户信息"
        :visible.sync="dialogVisible2"
        width="30%"
        :before-close="handleClose2"
      >
        <el-form
          label-width="80px"
          :model="formUpdate"
          ref="updateForm"
          :rules="rules"
        >
          <!-- <el-form-item label="id" prop="id">
            <el-input v-model="formUpdate.id" disabled></el-input>
          </el-form-item> -->
          <el-form-item label="手机号" prop="phoneNumber">
            <el-input v-model="formUpdate.phoneNumber" clearable></el-input>
          </el-form-item>
          <el-form-item label="工号" prop="jobNumber">
            <el-input v-model="formUpdate.jobNumber" clearable></el-input>
          </el-form-item>

          <el-form-item label="姓名" prop="name">
            <el-input v-model="formUpdate.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="formUpdate.password" clearable></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose2">取消</el-button>
          <el-button type="primary" @click="updateUser">修改</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapActions, mapState } from "vuex";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        jobNumber: [{ required: true, message: "请输入工号", trigger: "blur" }],
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          // {  pattern :'/0?(13|14|15|17|18|19)[0-9]{9}/' , message: "请输入正确的手机号", trigger: "blur" },
          //  { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
          // { type: '', message: '请输入正确的手机号', trigger: ['blur', 'change'] }
        ],
      },
      formAdd: {
        //新增
        phoneNumber: "",
        jobNumber: "",
        name: "",
      },
      formUpdate: {
        //修改
        id: "",
        phoneNumber: "",
        jobNumber: "",
        name: "",
        password:'',
      },
      dialogVisible: false,
      dialogVisible2: false,
      searchInput: "",

      searchParam: "",
      tableData: [],
      total: 0,
      multipleSelection: [],
      pageNum: 1,
      pageSize: 20,
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      list: (state) => state.StaffManage.list,
      addStaff: (state) => state.StaffManage.addStaff,
      delStaff: (state) => state.StaffManage.delStaff,
      updateStaff: (state) => state.StaffManage.updateStaff,

    }),

    tableMaxHeight() {
      let h = document.documentElement.clientHeight;
      return h - 220;
    },
  },
  //监控data中的数据变化
  watch: {
    list() {
      // console.log(12121212);
      if (this.list.success) {
        // console.log(this.list);
        this.tableData = this.list.data.list;
        this.total = this.list.data.total;
      } else {
        if(this.list.errorCode==500){
          this.$message({
          showClose: true,
          message: 'Admin无资产员工管理权限',
          type: "error",
        });
        }else{
          this.$message({
          showClose: true,
          message: this.list.message,
          type: "error",
        });
        }
        
      }
    },
    addStaff() {
      if (this.addStaff.success) {
        this.$message({
          showClose: true,
          message: this.addStaff.message,
          type: "success",
        });
        this.dialogVisible = false;
        this.getDataList();
        this.resetForm(); //清空表单
      } else {
        this.$message({
          showClose: true,
          message: this.addStaff.message,
          type: "error",
        });
      }
    },
    delStaff() {
      //删除提示
      if (this.delStaff.success) {
        this.$message({
          showClose: true,
          message: this.delStaff.message,
          type: "success",
        });
        this.getDataList();
      } else {
        this.getDataList();
        this.$message({
          showClose: true,
          message: this.delStaff.message,
          type: "error",
        });
      }
    },
    updateStaff() {
      //修改提示
      if (this.updateStaff.success) {
        this.$message({
          showClose: true,
          message: this.updateStaff.message,
          type: "success",
        });
        this.dialogVisible2 = false;
        this.getDataList();
      } else {
        // this.getDataList();
        this.$message({
          showClose: true,
          message: this.updateStaff.message,
          type: "error",
        });
      }
    },
  },

  //方法集合
  methods: {
    //关闭Dialog时清空表单
    resetForm() {
      //获取ref=addForm的标签
      this.$refs["addForm"].resetFields();
      // this.$refs["updateForm"].resetFields(); ////////////////////////////////////
    },
    resetForm2() {
      //获取ref=updateForm的标签
      this.$refs["updateForm"].resetFields();
    },
    //弹出框关闭事件
    handleClose() {
      this.dialogVisible = false;
      // this.dialogVisible2 = false;
      this.resetForm();
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.resetForm2();

    },
   ...mapActions({
      //查询列表
      getList: "StaffManage/getList",
      //新增
      getAddStaff: "StaffManage/getAddStaff",
       //删除
      getDelStaff: "StaffManage/getDelStaff",
      //修改
      getUpdateStaff: "StaffManage/getUpdateStaff",
    }),

    getDataList() {
      let params = {
        // searchParam:this.searchParam,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.getList(params);
    },
   
    addUser() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          // alert("submit!");
          //添加员工

          let params = {
            phoneNumber: this.formAdd.phoneNumber,
            jobNumber: this.formAdd.jobNumber,
            name: this.formAdd.name,
          };
          this.getAddStaff(params);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
     //页面传值
    viewUpdate(row) {
      this.dialogVisible2 = true;
      // alert(row.id);
      this.formUpdate.id = row.id;
      // this.formUpdate.phoneNumber = row.sex.toString();
      this.formUpdate.phoneNumber = row.phoneNumber;
      this.formUpdate.jobNumber = row.jobNumber;
      this.formUpdate.name = row.name;
      this.formUpdate.password = row.password;
      // console.log(this.formUpdate.name);
    },
    updateUser() {
      this.$refs["updateForm"].validate((valid) => {
        if (valid) {
          // alert("submit!");
          //添加员工

          let params = {
            id: this.formUpdate.id,
            phoneNumber: this.formUpdate.phoneNumber,
            jobNumber: this.formUpdate.jobNumber,
            name: this.formUpdate.name,
            password:this.formUpdate.password
          };
          this.getUpdateStaff(params);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    del(id) {
      //删除
      // alert(id);
      this.$confirm(`确定进行删除操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: id,
          };
          this.getDelStaff(params);
        })
        .catch(() => {});
    },
    //复选框
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val);
    },
    // handleDelete(index, obj) {
    //   //删除
    //   console.log(obj);
    // },

    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDataList();
    },
   
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getDataList();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.staffManage_box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  .header_search_box {
    // margin-bottom: 10px;
    // .input_box {
    //   width: 200px;
    // }
    // .marginRight {
    //   margin-right: 10px;
    // }
  }

  .tabel_box {
    width: 100%;
    height: calc(100% - 40px);
    box-sizing: border-box;
    padding-top: 10px;
    .table_cont {
      width: 100%;
      height: calc(100% - 40px);
    }
    .page_box {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>