<template>
  <div class="chargingStandard_box">
    <el-tabs v-model="activeName">
      <el-tab-pane label="自定义标准" name="first">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input v-model="standardNameCustom" placeholder="请输入标准名称"></el-input>
          </el-col>
          <!-- <el-col :span="9">
            <el-input v-model="companyNameCustom" placeholder="请输入企业名称"></el-input>
          </el-col> -->
          <el-col :span="2">
            <div class="seach_button" @click="seachList">搜索</div>
          </el-col>
        </el-row>

        <!-- 表头 -->
        <div class="tab_top">
          <span class="tab_name">收费标准列表</span>
          <div @click="addStandardCustom">
            <span class="add_border">+</span><span class="add_name">新增</span>
          </div>
        </div>

        <!-- 表格 -->
        <div class="table_center">
          <el-table
            ref="multipleTable"
            :data="tableDataCustom.list"
            tooltip-effect="dark"
            style="width: 100%"
            :height="tableMaxHeight"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="序号" type="index"> </el-table-column>
            <el-table-column prop="standardName" label="标准名称">
            </el-table-column>
            <el-table-column prop="companyName" label="企业名称">
            </el-table-column>
            <el-table-column prop="quantity" label="数量" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="amount" label="金额(元/年)"> </el-table-column>
            <el-table-column prop="costType" label="费用类型"> </el-table-column>
            <el-table-column prop="remarks" label="收费标准说明">
            </el-table-column>
            <el-table-column prop="updateTime" label="操作时间"> </el-table-column>
            <el-table-column prop="operat" label="操作">
              <template  slot-scope="scope">
                <el-button type="primary" plain @click="changeForm(scope.row,1)">编辑</el-button>
                <el-button type="primary" plain @click="delList(scope.row,1)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-pagination
        @size-change="handleSizeChangeCustom"
        @current-change="handleCurrentChangeCustom"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataCustom.total">
        </el-pagination>
      </el-tab-pane>

      <el-tab-pane label="默认标准" name="second">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input v-model="standardName" placeholder="请输入标准名称"></el-input>
          </el-col>
          <el-col :span="2">
            <div class="seach_button" @click="seach">搜索</div>
          </el-col>
        </el-row>

        <!-- 表头 -->
        <div class="tab_top">
          <span class="tab_name">收费标准列表</span>
          <div @click="addStandard">
            <span class="add_border">+</span><span class="add_name">新增</span>
          </div>
        </div>

        <!-- 表格 -->
        <div class="table_center">
          <el-table
            ref="multipleTable"
            :data="tableData.list"
            tooltip-effect="dark"
            style="width: 100%"
            :height="tableMaxHeight"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="序号" type="index"> </el-table-column>
            <el-table-column prop="standardName" label="标准名称">
            </el-table-column>
            <el-table-column prop="quantity" label="数量" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="amount" label="金额(元/年)"> </el-table-column>
            <el-table-column prop="costType" label="费用类型"> </el-table-column>
            <el-table-column prop="remarks" label="收费标准说明">
            </el-table-column>
            <el-table-column prop="updateTime" label="操作时间">
            </el-table-column>
            <el-table-column prop="operat" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  plain
                  @click="changeForm(scope.row,0)"
                  >编辑</el-button
                >
                <el-button type="primary" plain @click="delList(scope.row,0)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

          <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <AddStandard :show="show" :mold="mold"></AddStandard>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddStandard from "../components/addStandard.vue";
export default {
  components: {
    AddStandard,
  },
  data() {
    return {
      activeName: "second",
      input: "",
      mold: "",
      show: false,
      standardNameCustom:'',
      companyNameCustom:'',
      standardName:'',
      currentPage:1,
      pageNum:1,
      pageSize:10,
      pageNumCustom:1,
      pageSizeCustom:10
    };
  },
  computed: {
    ...mapState({
      tableData:(state)=> state.AddStandard.tableData, //标准页表格数据
      tableDataCustom:(state)=> state.AddStandard.tableDataCustom, //自定义页表格数据
      tableUp:(state)=>state.AddStandard.tableUp,
      loading:(state)=>state.AddStandard.loading,
    }),
    // 表格高度
    tableMaxHeight:()=> {
      let h = document.documentElement.clientHeight;
      // console.log(h);
      return h - 300;
    },
  },
  created() {
    // 获取收费标准列表
    this.getTable()
    //自定义页表格数据
    this.getTableCustom()
    
  },
  methods: {
    ...mapMutations("AddStandard", ["changeShow","changeTitle", "changeType", "changeFormEdit"]),
    ...mapActions("AddStandard",[ "getList","getListCustom", "getCompanyList","deleteList","getCostList"]),
      // 标准页分页（每页条数）
      handleSizeChange(val) {
        if (val != this.pageNum) {
          this.pageSize = val
          this.getTable()
        }
        
      },
      // 标准页分页（当前页）
      handleCurrentChange(val) {
        if (val != this.pageNum){
          this.pageNum = val
          this.getTable()
        }
      },
      // 自定义页分页（每页条数）
      handleSizeChangeCustom(val){
        if (val != this.pageNumCustom) {
          this.pageSizeCustom= val
          this.getTableCustom()
        }
        
      },
      // 自定义页分页（当前页）
      handleCurrentChangeCustom(val) {
        if (val != this.pageSizeCustom) {
          this.pageNumCustom = val
          this.getTableCustom()
        }
        
      },

      // 自定义页搜索查询
      seachList(){
        const obj = {
          standardName: this.standardNameCustom,
          standardType: 1,
          pageNum: 1,
          pageSize: 10,
        }
        this.getListCustom(obj)
      },
      seach(){
        const obj = {
          standardName: this.standardName,
          standardType: 0,
          pageNum: 1,
          pageSize: 10,
        }
        this.getList(obj)
      },
      // 获取收费标准列表
      getTable(){
        const obj = {
          standardName: "",
          standardType: 0,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        
        this.getList(obj)
      },
      //获取自定义页列表
      getTableCustom(){
        const Custom = {
          standardName: "",
          standardType: 1,
          pageNum: this.pageNumCustom, 
          pageSize: this.pageSizeCustom
        };
        
        this.getListCustom(Custom)
      },

    // 获取表单数据
    getTableData(num){
      if (this.tableUp) {
        const obj = {
          standardName: "",
          standardType: num,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        };
        if (num==0) {
          this.getList(obj)
        }else{
          this.getListCustom(obj)
        }
      }
    },
    // 自定义标准页新增点击事件
    addStandardCustom() {
      this.$confirm('确认新增标准吗，此标准仅适用于新增的企业前缀！！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.changeShow(true);
          this.changeType("Custom");
          this.changeTitle('收费标准添加') //弹出框标题
          // 获取公司名列表
          this.getCompanyList()
          this.getCostList()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        })
    },
    // 标准页新增点击事件
    addStandard() {
      this.$confirm('确认新增标准吗，此标准仅适用于新增的企业前缀！！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.changeTitle('收费标准添加') //弹出框标题
          this.changeShow(true);
          this.changeType("Standard");
          this.getCostList()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        })
    },
    // 表单编辑按钮点击事件
    changeForm(row,num) {
      this.$confirm('确认修改标准吗，修改后，未交费的企业前缀报表将改用此标准收费！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
            this.changeShow(true);
            this.changeType("edit");
            this.changeFormEdit(row);
            this.changeTitle('标准修改') //弹出框标题
            if (num == 1) {
              this.changeType('editCustom')
            }
            this.getCostList()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        })
    },
    
    delList(row,num){
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteList({id:row.id})
          .then(()=>{
            const obj = {
                standardName: "",
                standardType: num,
                pageNum: 1,
                pageSize: 20
            };
            this.getList(obj)
            if (num == 1) {
              this.getListCustom(obj)
            }
          })
          .then(()=>{
            this.getTableData(num)
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

      
    },
    

  },
};
</script>

<style lang="scss" scoped>
.chargingStandard_box {
  width: 96%;
  height: 100%;
  margin: 0 auto;
  .seach_button {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    background-color: #409eff;
  }
  .tab_top {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tab_name {
      font-weight: 550;
      font-size: 14px;
    }
    .add_border {
      display: inline-block;
      height: 12px;
      line-height: 12px;
      color: #409eff;
      border: 1px solid #000;
      border-radius: 3px;
      padding: 0 1px;
    }
    .add_name {
      color: #409eff;
      margin-left: 5px;
    }
  }
  // .table_center{
  //   height: 70vh;
  // }
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__item {
  font-size: 20px;
  font-family: "黑体";
}
::v-deep .el-pagination{
  position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-30%);
}
</style>
