<template>
  <div class="invoice_box">
    <el-row :gutter="10">
      <el-col :span="4">
        <el-input v-model="companyId" placeholder="订单编号"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input v-model="id" placeholder="发票编号"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input v-model="companyName" placeholder="企业名称"></el-input>
      </el-col>
      <el-col :span="2">
        <div class="seach_button" @click="search">搜索</div>
      </el-col>
    </el-row>

    <!-- 表头 -->
    <div class="tab_top">
      <span class="tab_name">发票管理列表</span>
      <div>
        <span class="add_border">+</span
        ><span class="add_name" @click="addTableList">新增</span>
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      :data="invoiceTableData.list"
      stripe
      tooltip-effect="dark"
      :height="tableMaxHeight"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="序号" type="index"> </el-table-column>
      <el-table-column prop="orderNo" label="发票申请订单号"> </el-table-column>
      <el-table-column
        prop="invoiceState"
        :formatter="changeState"
        label="发票状态"
      >
      </el-table-column>
      <el-table-column prop="invoiceNo" label="发票号码"> </el-table-column>
      <el-table-column prop="buyCompanyName" label="企业名称">
      </el-table-column>
      <el-table-column prop="amount" label="金额"> </el-table-column>
      <el-table-column prop="buyIdentityNo" label="纳税人识别号">
      </el-table-column>
      <el-table-column prop="createTime" label="开票时间"> </el-table-column>
      <el-table-column prop="operat" label="操作" width="300">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="dataUpdate(scope.row)"
            >编辑</el-button
          >
          <el-button type="primary" plain @click="datadelete(scope.row)"
            >删除</el-button
          >
          <!-- <el-button type="primary" plain @click="dataDetail(scope.row)"
            >详情</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="invoiceTableData.total"
    >
    </el-pagination>
    <Invoice></Invoice>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Invoice from "../components/invoice.vue";
export default {
  data() {
    return {
      companyId: "",
      id: "",
      companyName: "",
      currentPage: 1,
      pageNum: 1,
      pageSize: 10,
    };
  },
  components: {
    Invoice,
  },
  computed: {
    ...mapState({
      invoiceTableData: (state) => state.Invoice.invoiceTableData,
      loading:(state)=>state.Invoice.loading,
    }),
    // 表格高度
    tableMaxHeight:()=> {
      let h = document.documentElement.clientHeight;
      // console.log(h);
      return h - 250;
    },
  },
  watch:{
    invoiceTableData:function(){
				if (this.invoiceTableData.list.length > 0) {
					this.loading = false
				}
			}
  },
  methods: {
    ...mapMutations("Invoice", ["changeShow", "changeMold", "changeTitle"]),
    ...mapActions("Invoice", [
      "getFinancialList",
      "getFinancialDetail",
      "updateFinancialList",
      "deleteFinancialList",
      "getCompanyList"
    ]),

    // 搜索查询
    search() {
      const obj = {
        orderNo: this.companyId,
        invoiceNo: this.id,
        buyCompanyName: this.companyName,
        pageNum: 1,
        pageSize: 10,
      };
      this.getFinancialList(obj);
    },

    // 分页（每页条数）
    handleSizeChange(val) {
      if (val != this.pageNum) {
        this.pageNum = val;
        const obj = {
          orderNo: "",
          invoiceNo: "",
          buyCompanyName: "",
          pageNum: this.pageNum,
          pageSize: val,
        };
        this.getFinancialList(obj);
      }
    },
    // 分页（当前页）
    handleCurrentChange(val) {
      if (val != this.pageNum) {
        this.pageNum = val;
        const obj = {
          orderNo: "",
          invoiceNo: "",
          buyCompanyName: "",
          pageNum: val,
          pageSize: this.pageSize,
        };
        this.getFinancialList(obj);
      }
    },
    addTableList() {
      //新增
      this.changeShow(true); //显示弹出框
      this.changeMold("add"); //详情类型说明
      this.changeTitle("发票信息添加");
      this.getCompanyList({})
    },
    dataDetail(row) {
      //详情
      this.changeShow(true); //显示弹出框
      this.changeMold("select"); //详情类型说明
      const obj = {
        id: row.id,
      };
      this.getFinancialDetail(obj);
      this.changeTitle("发票详情");
      this.getCompanyList({})
    },
    dataUpdate(row) {
      //编辑列表
      this.changeShow(true); //显示弹出框
      this.changeMold("update"); //详情类型说明
      const obj = {
        id: row.id,
      };
      this.getFinancialDetail(obj);
      this.changeTitle("发票信息编辑");
      this.getCompanyList({})
    },
    datadelete(row) {
      //删除列表
      const obj = {
        orderNo: "",
        invoiceNo: "",
        buyCompanyName: "",
        pageNum: 1,
        pageSize: this.pageSize,
      };
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteFinancialList({ id: row.id }).then(() =>
            this.getFinancialList(obj)
          );
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changeState(row) {
      //状态格式转换
      if (row.invoiceState == 0) {
        return "开票中";
      } else {
        return "已开票";
      }
    },
  },
  mounted() {
    const obj = {
      orderNo: "",
      invoiceNo: "",
      buyCompanyName: "",
      pageNum: 1,
      pageSize: 10,
    };
    this.getFinancialList(obj);
  },
};
</script>

<style lang="scss" scoped>
.invoice_box {
  width: 96%;
  height: 100%;
  margin: 0 auto;
  .seach_button {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    background-color: #409eff;
  }
  .tab_top {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tab_name {
      font-weight: 550;
      font-size: 14px;
    }
    .add_border {
      display: inline-block;
      height: 12px;
      line-height: 12px;
      color: #409eff;
      border: 1px solid #000;
      border-radius: 3px;
      padding: 0 1px;
    }
    .add_name {
      color: #409eff;
      margin-left: 5px;
    }
  }
}
::v-deep .el-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-30%);
}
</style>
