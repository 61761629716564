<!--  -->
<template>
  <div class="getLog_page">
    <div class="tabel_box">
      <div class="table_cont">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="tableMaxHeight"
          border
          size="medium"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="userId" label="员工用户id"></el-table-column>
          <el-table-column prop="identity" label="物料标识代码"></el-table-column>
          <el-table-column prop="borrowTime" label="领用时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="untie(scope.row)"
                >强制解绑</el-button
              >
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="warning"
                size="mini"
                @click="viewUpdate(scope.row)"
                >修改</el-button
              >
              <el-button type="danger" size="mini" @click="del(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>

        <div class="page_box">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapActions, mapState } from "vuex";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      tableData: [],
      pageNum: 1, //页码
      pageSize: 20, //每页显示条数
      total: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {
   
    ...mapState({ 
      //领用记录
      logList: (state) => state.StaffManage.logList,
        //解绑
      unbind: (state) => state.StaffManage.unbind,

    }),

    tableMaxHeight() {
      let h = document.documentElement.clientHeight;
      return h - 220;
    },
  },
  //监控data中的数据变化
  watch: {
    logList() {
      // console.log(12121212);
      if (this.logList.success) {
        // console.log(this.list);
        this.tableData = this.logList.data.list;
        this.total = this.logList.data.total;
      } else {
        if(this.logList.errorCode==500){
          this.$message({
          showClose: true,
          message: 'Admin无资产员工管理权限',
          type: "error",
        });
        }else{
          this.$message({
          showClose: true,
          message: this.logList.message,
          type: "error",
        });
        }
        
      }
    },

    unbind() {
      if (this.unbind.success) {
        this.$message({
          showClose: true,
          message: this.unbind.message,
          type: "success",
        });
        this.getDataList();
      } else {
        this.$message({
          showClose: true,
          message: this.unbind.message,
          type: "error",
        });
      }
    },
  },
  //方法集合
  methods: {
    ...mapActions({
      //查询列表
      getLogList: "StaffManage/getLogList",
    }),

    getDataList() {
      let params = {
        // searchParam:this.searchParam,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.getLogList(params);
    },
    //解绑
    ...mapActions({
      getUnbind: "StaffManage/getUnbind",
    }),
    untie(row) {
      this.$confirm(`确定进行删除操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // console.log(row.id);
          let params = {
            id: row.id,
          };
          this.getUnbind(params);
        })
        .catch(() => {});
    },
    //复选框
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDataList();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getDataList();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.getLog_page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  .tabel_box {
    width: 100%;
    height: calc(100% - 40px);
    box-sizing: border-box;
    padding-top: 10px;
    .table_cont {
      width: 100%;
      height: calc(100% - 40px);
    }
    .page_box {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>