<template>
	<div class="reportForm_box">
		<el-row :gutter="10">
			<el-col :span="4">
				<el-input v-model="companyName" placeholder="请输入企业名称"></el-input>
			</el-col>
			<el-col :span="4">
				<el-input v-model="entPrefix" placeholder="请输入企业前缀"></el-input>
			</el-col>
			<el-col :span="2">
				<div class="seach_button" @click="search">搜索</div>
			</el-col>
		  </el-row>

		 <!-- 表头 -->
        <div class="tab_top">
          <span class="tab_name">财务报表列表</span>
          <!-- <div>
            <span class="add_border">+</span
            ><span class="add_name" @click="addTableList">新增</span>
          </div> -->
        </div>


		<!-- 表格 -->
        <el-table
          ref="multipleTable"
          :data="tableData.list"
		  stripe
          tooltip-effect="dark"
		  :height="tableMaxHeight"
		  v-loading="loading"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" type="index">
          </el-table-column>
          <el-table-column prop="companyName" label="企业名称">
          </el-table-column>
          <el-table-column prop="entPrefix" label="企业前缀" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="isPay" label="交费状态" :formatter="changePay"> </el-table-column>
          <el-table-column prop="costTotal" label="应交总额(单位:元)"> </el-table-column>
          <el-table-column prop="updateTime" label="操作时间"> </el-table-column>
          <el-table-column prop="operat" label="操作" width="300">
			  <template slot-scope="scope">
				<el-button type="primary" plain @click="dataChange(scope.row)">编辑</el-button>
				<el-button type="primary" plain @click="dataDelete(scope.row)">删除</el-button>
				<el-button type="primary" plain @click="dataDetail(scope.row)">详情</el-button>
			  </template>
		  </el-table-column>
        </el-table>

		<div class="block">
			<el-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="currentPage"
			:page-sizes="[10, 20, 50, 100]"
			:page-size="10"
			layout="total, sizes, prev, pager, next, jumper"
			:total="tableData.total">
			</el-pagination>
		</div>
		<AddReportForm></AddReportForm>
	</div>
</template>

<script>
	import {mapActions ,mapMutations , mapState} from 'vuex'
	import AddReportForm from '../components/addReportForm.vue'
	export default {
		components:{
			AddReportForm
		},
		data(){
			return {
				companyName:'',
				entPrefix:'',
				currentPage:1,
				pageNum:1,
				pageSize:10,
			}
		},
		created(){
			const obj = {
				companyName:'',
				entPrefix:'',
				pageNum:1,
				pageSize:20
			}
			// 财务报表列表
			this.getFinancialList(obj)
		},
		computed:{
			...mapState({
				userInfo:state=>state.userInfo,
				tableData:state=>state.ReportForm.tableData,
				dialogVisible:state=>state.ReportForm.dialogVisible,
				tableDetail:state=>state.ReportForm.tableDetail,
				loading:(state)=>state.ReportForm.loading,
			}),
			// 表格高度
			tableMaxHeight:()=> {
				let h = document.documentElement.clientHeight;
				// console.log(h);
				return h - 250;
			},
			
		},
		watch:{
			tableData:function(){
				if (this.tableData.list.length > 0) {
					this.loading = false
				}
			}
		},
		methods:{
			...mapMutations('ReportForm',['changeShow','changeMold','changeId','changeTitle']),
			...mapActions('ReportForm',['getFinancialList','getFinancialDetail','addFinancialDetail','deleteFinancialDetail','updateFinancialDetail']),

			// 搜索查询
			search(){
				const obj = {
					companyName:this.companyName,
					entPrefix:this.entPrefix,
					pageNum:1,
					pageSize:20
				}
				this.getFinancialList(obj)
			},

			// 分页（每页条数）
			handleSizeChange(val) {
				if (val != this.pageNum) {
				this.pageNum = val
				const obj = {
					orderNo: "",
					invoiceNo: "",
					buyCompanyName: "",
					pageNum: this.pageNum,
					pageSize: val
				}
				this.getFinancialList(obj)
				}
				
			},
			// 分页（当前页）
			handleCurrentChange(val) {
				if (val != this.pageNum){
					this.pageNum = val
					const obj = {
						orderNo: "",
						invoiceNo: "",
						buyCompanyName: "",
						pageNum: val,
						pageSize: this.pageSize
					}
					this.getFinancialList(obj)
				}
			},
			// 新增数据
			// addTableList(){
			// 	this.changeMold('add')
			// 	this.changeShow(true)
			// 	const obj = {

			// 	}
			// 	this.getCompanyList(obj)
			// },
			// 修改数据
			dataChange(row){
				// 财务报表详情显示
				this.changeShow(true)
				this.changeMold('update')
				this.changeTitle('财务报表编辑')
				const obj = {
					companyName:row.companyName,
					entPrefix:row.entPrefix,
					companyId:row.companyId
				}
				this.changeId(row.id)
				this.getFinancialDetail(obj)
			},
			dataDelete(row){
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const obj = {
						companyId:row.companyId
					}
					const res = {
						orderNo: "",
						invoiceNo: "",
						buyCompanyName: "",
						pageNum: this.pageNum,
						pageSize: this.pageSize
					}
					
					this.deleteFinancialDetail(obj).then(()=>{
						this.getFinancialList(res)
					})
				}).then(()=>{
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			},
			// 修改交费状态数据
			changePay(row){
				if (row.isPay == 0) {
					return '未支付'
				}else{
					return '已支付'
				}
			},
			// 财务报表详情点击事件
			dataDetail(row){
				// 财务报表详情显示
				this.changeShow(true)
				// 
				this.changeMold('select')
				this.changeTitle('财务报表详情')
				const obj = {
					companyName:row.companyName,
					entPrefix:row.entPrefix,
					companyId:row.companyId
				}
				this.getFinancialDetail(obj)
			}
		},
	}
</script>

<style lang="scss" scoped>
	.reportForm_box{
		width: 96%;
		height: 100%;
		margin: 0 auto;
		.seach_button {
			width: 100%;
			height: 40px;
			text-align: center;
			line-height: 40px;
			font-size: 16px;
			color: #fff;
			border-radius: 5px;
			background-color: #409eff;
		} 
		.tab_top {
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.tab_name {
			font-weight: 550;
			font-size: 14px;
			}
			.add_border {
				display: inline-block;
				height: 12px;
				line-height: 12px;
			color: #409eff;
			border: 1px solid #000;
			border-radius: 3px;
			padding: 0 1px;
			}
			.add_name {
			color: #409eff;
			margin-left: 5px;
			}
		}
	}
::v-deep .el-pagination{
  position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-30%);
}
</style>
