<template>
  <div class="cockpit_box" :style="isFullScreen ? 'position: absolute;' : ''">
    <img class="bg_img" :src="imgUrl + '/pages/cockpit/background.png'" />
    <div class="cockpit_container" v-if="showContainer">
      <div class="header_box">
        <img class="top_img" :src="imgUrl + '/pages/cockpit/top.png'" />
        <div class="title_name_box">
          <div class="title_color1">云平台</div>
          <div class="title_color2">驾驶舱</div>
        </div>
        <div class="full_screen_box" @click="fullScreen">
          <i class="el-icon-s-help"></i>
        </div>
      </div>
      <div class="cont">
        <div class="side_box">
          <img
            class="border_box"
            src="../../../assets/pages/cockpit/border.png"
          />
          <div class="side_cont">
            <div class="btn_box">
              <div class="btn_item" @click="changeActiveBtn(0)">
                <img
                  class="selected_img"
                  v-if="activeIBtn === 0"
                  src="../../../assets/pages/cockpit/selected.png"
                />
                <img
                  class="selected_img"
                  v-else
                  src="../../../assets/pages/cockpit/unselected.png"
                />
                <div
                  class="item_btn_name"
                  :class="{ active_btn_name: activeIBtn === 0 }"
                >
                  当天
                </div>
              </div>
              <div class="btn_item" @click="changeActiveBtn(1)">
                <img
                  class="selected_img"
                  v-if="activeIBtn === 1"
                  src="../../../assets/pages/cockpit/selected.png"
                />
                <img
                  class="selected_img"
                  v-else
                  src="../../../assets/pages/cockpit/unselected.png"
                />
                <div
                  class="item_btn_name"
                  :class="{ active_btn_name: activeIBtn === 1 }"
                >
                  当月
                </div>
              </div>
              <div class="btn_item" @click="changeActiveBtn(2)">
                <img
                  class="selected_img"
                  v-if="activeIBtn === 2"
                  src="../../../assets/pages/cockpit/selected.png"
                />
                <img
                  class="selected_img"
                  v-else
                  src="../../../assets/pages/cockpit/unselected.png"
                />
                <div
                  class="item_btn_name"
                  :class="{ active_btn_name: activeIBtn === 2 }"
                >
                  今年
                </div>
              </div>
            </div>
            <div class="data_box">
              <div class="data_box_item">
                <div class="icon_bg_box">
                  <div class="icon_cont_box">
                    <img
                      class="icon_png"
                      src="../../../assets/pages/cockpit/icon1.png"
                    />
                  </div>
                </div>
                <div class="data_right_box">
                  <div class="Num_box">
                    {{ getNumText(countDayMouthYear.companyCount) }}
                  </div>
                  <div class="Num_name">企业注册数量</div>
                </div>
              </div>
              <div class="data_box_item">
                <div class="icon_bg_box">
                  <div class="icon_cont_box">
                    <img
                      class="icon_png"
                      src="../../../assets/pages/cockpit/icon2.png"
                    />
                  </div>
                </div>
                <div class="data_right_box">
                  <div class="Num_box">
                    {{ getNumText(countDayMouthYear.identityCount) }}
                  </div>
                  <div class="Num_name">标识注册数量</div>
                </div>
              </div>
              <div class="data_box_item">
                <div class="icon_bg_box">
                  <div class="icon_cont_box">
                    <img
                      class="icon_png"
                      src="../../../assets/pages/cockpit/icon3.png"
                    />
                  </div>
                </div>
                <div class="data_right_box">
                  <div class="Num_box">
                    {{ getNumText(countDayMouthYear.identityResolve) }}
                  </div>
                  <div class="Num_name">标识解析数量</div>
                </div>
              </div>
              <!-- <div class="data_box_item">
                <div class="icon_bg_box">
                  <div class="icon_cont_box">
                    <img
                      class="icon_png"
                      src="../../../assets/pages/cockpit/icon4.png"
                    />
                  </div>
                </div>
                <div class="data_right_box">
                  <div class="Num_box">
                    {{ getNumText(countDayMouthYear.templateCount) }}
                  </div>
                  <div class="Num_name">子系统接入数</div>
                </div>
              </div> -->
            </div>
            <div class="logic_application">
              <div class="title">今年企业注册趋势（个）</div>
              <div id="regist_Chart"></div>
            </div>
            <!-- <div class="analytic_ranking">
							<div class="title">今年标识解析量</div>
							<div class="analytic_Charts" id="analyticCharts"></div>
						</div> -->
          </div>
        </div>
        <div class="center_box">
          <div class="total_box">
            <div class="total_item">
              <img
                class="middle_img"
                src="../../../assets/pages/cockpit/middle.png"
              />
              <div class="total_val_box">
                <span class="val_text">{{
                  companyCount ? companyCount : 0
                }}</span>
                <span class="val_name">企业注册总量</span>
              </div>
            </div>
            <div class="total_item">
              <img
                class="middle_img"
                src="../../../assets/pages/cockpit/middle.png"
              />
              <div class="total_val_box">
                <span class="val_text">{{
                  identityCount ? identityCount : 0
                }}</span>
                <span class="val_name">标识注册总量</span>
              </div>
            </div>
            <div class="total_item">
              <img
                class="middle_img"
                src="../../../assets/pages/cockpit/middle.png"
              />
              <div class="total_val_box">
                <span class="val_text">{{
                  identityResolve ? identityResolve : 0
                }}</span>
                <span class="val_name">标识解析总量</span>
              </div>
            </div>
            <div class="total_item">
              <img
                class="middle_img"
                src="../../../assets/pages/cockpit/middle.png"
              />
              <div class="total_val_box">
                <span class="val_text">{{
                  intelligentTerminalCount ? intelligentTerminalCount : 0
                }}</span>
                <span class="val_name">智能终端数量</span>
              </div>
            </div>
          </div>
          <div class="middle_box">
            <iframe
              :src="baseUrl + 'pages/visualization.html'"
              frameborder="0"
              height="100%"
              width="100%"
              scrolling="auto"
              ref="iframeMap"
            ></iframe>
          </div>
        </div>
        <div class="side_box">
          <img
            class="border_box"
            src="../../../assets/pages/cockpit/border.png"
          />
          <div class="side_cont">
            <div class="registration_Trends">
              <div class="title">今年标识注册趋势（个）</div>
              <div class="myChart_class" id="identifyChart"></div>
            </div>
            <div class="visit_top10">
              <div class="title">访问总排行（ Top10 ）</div>
              <div class="top10_cont">
                <div
                  class="top10_item"
                  v-for="(item, index) in resolveRankTotal"
                  :key="index"
                >
                  <div class="index_box" :class="{ top3_name: index < 3 }">
                    {{ index + 1 }}
                  </div>
                  <div class="company_name" :class="{ top3_name: index < 3 }">
                    {{ item.name }}
                  </div>
                  <div class="num_progress">
                    <div class="progress_box">
                      <div
                        class="active_progress"
                        :class="{ top3_progress: index < 3 }"
                        :style="'width:' + getProgressWidth(item.value) + '%;'"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import '../../../common/lib/flexible'
export default {
  data() {
    return {
      itemGap: 15,
      pieRadius: [],
      pieCenter: [],
      legendFontSize: 16,
      baseUrl: process.env.BASE_URL, //基础url
      activeIBtn: 2,
      isFullScreen: false,
      countDayMouthYear: {}, //当天/当月/今年数量
      companyCount: 0, //企业注册总量
      identityCount: 0, //标识注册总量
      identityResolve: 0, //标识解析总量
      intelligentTerminalCount: 0, //智能终端数量
      resolveRankTotal: [], //总访问排行
      maxTotal: 0, //企业最大访问数量
      showContainer: true,
      mapData: "", //地图数据
      longitude: "", //经度
      latitude: "", //纬度
      domHeight:0
    };
  },
  watch: {
    mapData() {
      this.$nextTick(() => {
        if (this.$refs.iframeMap.contentWindow.showMarket) {
          // let arr = JSON.parse(this.mapData);
          let arr = this.mapData;
          this.$refs.iframeMap.contentWindow.showMarket(arr);
        }
      });
    },
    cockpitData() {
      if (this.cockpitData.success && this.cockpitData.data) {
        let obj = this.cockpitData.data;
        // console.log("==================="+obj);
        this.companyCount = obj.companyCount;
        this.identityCount = obj.identityCount;
        this.identityResolve = obj.identityResolve;
        this.countDayMouthYear = obj.countYear;
        this.intelligentTerminalCount = obj.intelligentTerminalCount;

        this.longitude = obj.mapShowPointList.longitude;
        this.latitude = obj.mapShowPointList.latitude;

        this.drawAll(obj); //绘制所有的图

        //企业总访问排行
        this.maxTotal = obj.resolveRankTotal.valueList[0];
        let rankTotal = [];
        let nameObj = obj.resolveRankTotal.xAxisUnitList;
        obj.resolveRankTotal.valueList.map((item, index) => {
          rankTotal.push({
            name: nameObj[index],
            value: item,
          });
        });
        this.resolveRankTotal = rankTotal;
      } else {
        this.companyCount = 0;
        this.identityCount = 0;
        this.identityResolve = 0;
        this.intelligentTerminalCount = 0;
        this.registDrawLine(); //企业注册趋势
        this.identifyChartLine(); //标识注册趋势
        // this.analyticCategory(); //标识解析企业排行
        this.maxTotal = 0;
        this.resolveRankTotal = [];
        this.$message({
          showClose: true,
          message: this.homePageData.message,
          type: "error",
        });
      }
    },
  },
  computed: {
    ...mapState({
      cockpitData: (state) => state.HomeAndCockpit.cockpitData,
      imgUrl: (state) => state.imgUrl,
    }),
  },
  methods: {
    ...mapActions({
      getCockpitData: "HomeAndCockpit/getCockpitData",
    }),
    watchScreenSize(){
      console.log('屏幕分辨率',window.screen.width);
       if (window.screen.width == 1440) {
        this.pieRadius =  ["32%", "58%"];
        this.pieCenter = ["50%", "77%"];
        this.legendFontSize = 12;
         this.itemGap = 14;
      } else if(window.screen.width == 1920){
         this.pieRadius = ["40%", "62%"];
         this.pieCenter = ["50%", "70%"];
         this.legendFontSize = 16;
          this.itemGap = 15;
      }else if(window.screen.width == 1360){
         this.pieRadius =  ["20%", "40%"];
          this.pieCenter = ["50%", "80%"];
          this.legendFontSize = 12;
          this.itemGap = 8;
      }else if(window.screen.width == 1366){
         this.pieRadius =  ["20%", "40%"];
          this.pieCenter = ["50%", "80%"];
          this.legendFontSize = 12;
          this.itemGap = 8;
      }else if(window.screen.width == 1280){
         this.pieRadius =  ["20%", "40%"];
          this.pieCenter = ["50%", "80%"];
          this.legendFontSize = 8;
          this.itemGap = 8;
      }
      window.onresize=()=>{
        let height = document.getElementsByClassName('cockpit_box')[0].clientHeight
        if (this.domHeight == 0) {
          this.domHeight = height
          this.isFullScreen = true
        }else{
          if (height*1 < this.domHeight*1) {
            this.isFullScreen = false
          }else{
            this.isFullScreen = true
          }
        }
        this.domHeight = height
        this.showContainer = false;
        this.$nextTick(() => {
          this.showContainer = true;
          this.$nextTick(() => {
            this.drawAll(this.cockpitData.data);
          });
        });
      }
    },
    getNumText(num) {
      if (!num) {
        return 0;
      } else {
        let str = num;
        if (num > 99999 && num <= 9999999) {
          str = (num / 10000).toFixed(2) + "万";
        } else if (num > 9999999 && num <= 99999999) {
          str = (num / 10000000).toFixed(2) + "千万";
        } else if (num > 99999999) {
          str = (num / 100000000).toFixed(3) + "亿";
        }
        return str;
      }
    },
    getProgressWidth(val) {
      const maxTotal = this.maxTotal;
      if (this.maxTotal == 0) {
        return 0;
      } else {
        return (val / maxTotal) * 100;
      }
    },
    drawAll(obj) {
      //绘制所有图
      this.registDrawLine(obj.companyRegisterYear); //企业注册趋势
      this.identifyChartLine(obj.identityRegisterYear); //标识注册趋势
      // this.analyticCategory(obj.identityResolveYear); //今年标识解析量
      this.mapData = obj.mapShowPointList; //地图数据
    },
    fullScreen() {
      //是否全屏
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        //   console.log(document.getElementsByClassName('dislpayArrow')[0]);
        const dislpayArrow = document.getElementsByClassName("dislpayArrow")[0];
        dislpayArrow.style.display = "none";
      } else {
        const dislpayArrow = document.getElementsByClassName("dislpayArrow")[0];
        dislpayArrow.style.display = "block";
      }
      //重新生成表格
      this.showContainer = false;
      this.$nextTick(() => {
        this.showContainer = true;
        this.$nextTick(() => {
          this.drawAll(this.cockpitData.data);
          console.log();
        });
      });
    },
    keyDown() {
      console.log(document);
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 = e || event || window.event || arguments.callee.caller.arguments[0];
        if (e1 && e1.key == "F11") {
          //空格
          // this.fullScreen()
        }
      };
    },
    changeActiveBtn(index) {
      this.activeIBtn = index;
      let obj = this.cockpitData.data;
      if (index === 0) {
        this.countDayMouthYear = obj.countDay;
      } else if (index === 1) {
        this.countDayMouthYear = obj.countMouth;
      } else if (index === 2) {
        this.countDayMouthYear = obj.countYear;
      }
    },
    // registDrawLine(obj) {
    // 	//企业注册趋势
    // 	let seriesData = [];	//数据
    // 	let dataX = [];	//x轴
    // 	if(obj.xAxisUnitList){
    // 		seriesData = obj.valueList;
    // 		let year = new Date().getFullYear();
    // 		obj.xAxisUnitList.map(item =>{
    // 			dataX.push(`${item}月`);
    // 		})
    // 	}

    // 	let regist_Chart = this.$echarts.init(document.getElementById('regist_Chart'));
    // 	regist_Chart.setOption({
    // 		tooltip: {
    // 			trigger: 'axis',
    // 			axisPointer: {
    // 				type: 'cross',
    // 				label: {
    // 					backgroundColor: '#52657B'
    // 				}
    // 			}
    // 		},
    // 		grid: {
    // 			left: '5%',
    // 			right: '4%',
    // 			bottom: '3%',
    // 			containLabel: true
    // 		},
    // 		xAxis: [
    // 			{
    // 				type: 'category',
    // 				boundaryGap: false,
    // 				data: dataX,
    // 				axisLine: {
    // 					lineStyle: {
    // 						type: 'solid',
    // 						color: '#4D5F76', //左边线的颜色
    // 						width: '1' //坐标线的宽度
    // 					}
    // 				},
    // 				axisTick:{
    // 					show:false,
    // 				},
    // 			}
    // 		],
    // 		yAxis: [
    // 			{
    // 				type: 'value',
    // 				splitLine: {
    // 					lineStyle: {
    // 						type: 'solid',
    // 						color: '#122352', //左边线的颜色
    // 						width: '0.5' //坐标线的宽度
    // 					}
    // 				}, //网格线
    // 				axisLine: {
    // 					show: false,
    // 					lineStyle: {
    // 						type: 'solid',
    // 						color: '#4D5F76', //左边线的颜色
    // 						width: '1' //坐标线的宽度
    // 					}
    // 				},
    // 				axisTick:{
    // 					show:false,
    // 				},
    // 			}
    // 		],
    // 		series: [
    // 			{
    // 				name: '企业注册数量',
    // 				type: 'line',
    // 				stack: '总量',
    // 				itemStyle: {
    // 					normal: {
    // 						areaStyle: {
    // 							type: 'default',
    // 							//渐变色实现===//三种由深及浅的颜色
    // 							color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
    // 								{
    // 									offset: 0,
    // 									color: '#69BAB2'
    // 								},
    // 								{
    // 									offset: 1,
    // 									color: '#000F30'
    // 								}
    // 							])
    // 						},
    // 						lineStyle: {
    // 							//线的颜色
    // 							color: '#69BAB2'
    // 						},
    // 						//以及在折线图每个日期点顶端显示数字
    // 						label: {
    // 							show: true,
    // 							position: 'top',
    // 							fontSize: 12,
    // 							textStyle: {
    // 								color: '#ddd'
    // 							}
    // 						}
    // 					}
    // 				},
    // 				areaStyle: {},
    // 				data:seriesData,
    // 			}
    // 		]
    // 	});
    // },

    registDrawLine(obj) {
      const legendX = 10;
      let regist_Chart = this.$echarts.init(
        document.getElementById("regist_Chart")
      );
      regist_Chart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          itemWidth: 14,
          itemHeight: 5,
          itemGap: this.itemGap, //图例行距
          data: ["多式联运平台","物流管理系统","CIIP系统","海工联网网站-冗余物资库","E通关系统","固定资产管理系统","箱行天下","中集海工云服务平台","九仙云海洋渔业信息服务平台","中集智慧海工建造基地管理平台","箱行天下-集装箱保险模块"],
          left: '0%',
          top:'0%',
          textStyle: {
            fontSize: this.legendFontSize,
            color: '#fff'
          }
        },

        series: [
          {
            type: "pie",
            radius: this.pieRadius,
            center: this.pieCenter,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "CIIP系统" },
              { value: 735, name: "物流管理系统" }, //#91CC75
              { value: 580, name: "海工联网网站-冗余物资库" },
              { value: 484, name: "箱行天下" },
              { value: 484, name: "九仙云海洋渔业信息服务平台" },
              { value: 300, name: "固定资产管理系统" },
              { value: 400, name: "中集海工云服务平台" }, //#00D4C1  #00C2F2 #E602BD #D671A4 #0027E8 #D4D051
              { value: 200, name: "中集智慧海工建造基地管理平台" },
              { value: 120, name: "箱行天下-集装箱保险模块" }, //#9A60B4
              { value: 1048, name: "E通关系统" },
              { value: 735, name: "多式联运平台" }, //#91CC75s
            ],
          },
        ],
      });

      window.addEventListener('resize',function(){
        setTimeout(()=>{
          regist_Chart.resize();
        },400)
      })

      regist_Chart.on("legendselectchanged", function (params) {
        console.log("legend被点击了", params);
        switch (params.name) {
          case "CIIP系统":
            window.open(
              "https://172.50.1.10/wui/index.html#/?logintype=1&_key=4b6eev"
            );
            break;
          case "物流管理系统":
            window.open("http://test.oneconn.com/cimcYard");
            break;
          case "海工联网网站-冗余物资库":
            window.open("http://www.codia.org.cn");
            break;
          case "箱行天下":
            window.open("https://goocontainers.com/");
            break;

          case "九仙云海洋渔业信息服务平台":
            window.open("https://producers.super-sight.com.cn:8000/");
            break;

          case "固定资产管理系统":
            window.open("http://test.oneconn.com/EAMS/views/login.html ");
            break;
          case "中集海工云服务平台":
            window.open("http://ship.sv3d.com.cn/");
            break;
          case "中集智慧海工建造基地管理平台":
            window.open("http://219.146.135.169:9000/");
            break;
          case "箱行天下-集装箱保险模块":
            window.open("www.goocontainers.com");
            break;
          case "E通关系统":
            window.open("http://www.cimceport.com/");
            break;
          case "多式联运平台":
            window.open("http://test.oneconn.com/dsly");
            break;
          // case "冷箱巡检系统":
          //   window.open("http://reefer.ucontainers.com/login.html");
          //   break;
          // case "天然气场站监控":
          //   window.open("http://api.gootanks.com/gas");
          //   break;
          // case "资产管理":
          //   window.open("http://101.132.37.231/EAMS");
          //   break;
          // case "IPOS智慧堆场管理系统":
          //   window.open("http://139.224.33.133/ipos/#/login");
          //   break;
          // case "Oneconn平台":
          //   window.open("http://www.oneconn.net/");
          //   break;
          // case "罐通天下":
          //   window.open("https://www.gootanks.com/");
          //   break;
          // case "冷链天下":
          //   window.open("http://www.goocoldchain.com/login.html");
          //   break;
          // case "e通关":
          //   window.open("http://cimceport.com/");
          //   break;
          // case "智能集装箱技术协会":
          //   window.open("http://www.gscaorg.com/#/indexPage");
          //   break;
          // case "铁集共联":
          //   window.open("http://test.oneconn.com/rtcc/");
          //   break;
          // case "中集e保":
          //   window.open("http://gooinsure.com/");
          //   break;
        }
      });
    },
    identifyChartLine(obj) {
      //标识注册趋势
      let seriesData = []; //数据
      let dataX = []; //x轴
      if (obj.xAxisUnitList) {
        seriesData = obj.valueList;
        let year = new Date().getFullYear();
        obj.xAxisUnitList.map((item) => {
          dataX.push(`${item}月`);
        });
      }

      let identifyChart = this.$echarts.init(
        document.getElementById("identifyChart")
      );
      identifyChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#52657B",
            },
          },
        },
        grid: {
          left: "5%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: dataX,
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#4D5F76", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {
                type: "solid",
                color: "#122352", //左边线的颜色
                width: "0.5", //坐标线的宽度
              },
            }, //网格线
            axisLine: {
              show: false,
              lineStyle: {
                type: "solid",
                color: "#4D5F76", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "标识注册数量",
            type: "line",
            stack: "总量",
            itemStyle: {
              normal: {
                areaStyle: {
                  type: "default",
                  //渐变色实现===//三种由深及浅的颜色
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#69BAB2",
                    },
                    {
                      offset: 1,
                      color: "#000F30",
                    },
                  ]),
                },
                lineStyle: {
                  //线的颜色
                  color: "#69BAB2",
                },
                //以及在折线图每个日期点顶端显示数字
                label: {
                  show: true,
                  position: "top",
                  fontSize: 12,
                  textStyle: {
                    color: "#ddd",
                  },
                },
              },
            },
            areaStyle: {},
            data: seriesData,
          },
        ],
      });

       window.addEventListener('resize',function(){
        setTimeout(()=>{
          identifyChart.resize();
        },400)
      })
    
    },
    analyticCategory(obj) {
      //今年标识解析量
      let seriesData = []; //数据
      let dataX = []; //x轴
      if (obj.xAxisUnitList) {
        seriesData = obj.valueList;
        let year = new Date().getFullYear();
        obj.xAxisUnitList.map((item) => {
          dataX.push(`${item}月`);
        });
      }

      let analyticCharts = this.$echarts.init(
        document.getElementById("analyticCharts")
      );
      analyticCharts.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#52657B",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: dataX,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#4D5F76", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {
                type: "solid",
                color: "#4D5F76", //左边线的颜色
                width: "1", //坐标线的宽度
              },
            },
            splitLine: {
              show: false,
            }, //网格线,
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "标识解析量",
            type: "bar",
            barWidth: "60%",
            data: seriesData,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#4EF0F5" },
                { offset: 0.5, color: "#25A0CC" },
                { offset: 1, color: "#0765AD" },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#0765AD" },
                  { offset: 0.7, color: "#25A0CC" },
                  { offset: 1, color: "#4EF0F5" },
                ]),
              },
            },
          },
        ],
      });
      window.addEventListener('resize',function(){
      setTimeout(()=>{
        analyticCharts.resize();
      },400)
      })
    
    },
    registrationWinEvents() {
      window["handleVueFunction"] = (val) => {
        //点击标记点事件
        if (this.mapData) {
          // console.log('this.mapData');
          // console.log(this.mapData);
          let arr = JSON.parse(JSON.stringify(this.mapData));
          // var arr = eval("(" + this.mapData + ")");
          // let arr = JSON.parse( this.mapData) ;
          // console.log(arr);
          this.$refs.iframeMap.contentWindow.showMarket(arr);
          // console.log(this.$refs.iframeMap.contentWindow.showMarket(arr));
        }

        // if(this.longitude&&this.latitude){
        // console.log('this.$refs.iframeMap.contentWindow');
        // console.log(this.$refs.iframeMap.contentWindow);;
        // }
      };
    },
  },
  mounted() {
    this.watchScreenSize(); //监听屏幕分辨率大小
    this.keyDown(); //监听按键
    this.getCockpitData(); //获取数据
    this.registrationWinEvents();
  },
};
</script>
<style lang="scss">
.el-progress-bar__outer {
  background-color: #002968 !important;
}
.el-progress-bar__innerText {
  color: #ddd !important;
}
</style>
<style lang="scss" scoped>
.cockpit_box {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 9999;
  .bg_img {
    width: 100%;
    height: 100%;
  }
  .title {
    color: #2ed3fc;
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 0;
  }
  .cockpit_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .header_box {
      width: 100%;
      height: 100px;
      position: relative;
      .full_screen_box {
        width: 50px;
        height: 54px;
        position: absolute;
        right: 20px;
        top: 0;
        z-index: 2;
        color: #fff;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .top_img {
        width: 100%;
        height: 100%;
      }
      .title_name_box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: 26px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 4px;
        .title_color1 {
          color: #d5fdfa;
        }
        .title_color2 {
          color: #c0d9fc;
        }
      }
    }
    .cont {
      width: 100%;
      height: calc(100% - 100px);
      box-sizing: border-box;
      padding: 0 20px 20px 20px;
      display: flex;
      flex-direction: row;
      .center_box {
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        padding: 0 30px;
        position: relative;
        .total_box {
          width: 100%;
          height: 0.875rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          top: 0;
          left: 0;
          box-sizing: border-box;
          padding: 0 0.375rem;
          z-index: 99999;
          .total_item {
            width: auto;
            height: 100%;
            position: relative;
            .middle_img {
              height: 100%;
            }
            .total_val_box {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              color: #ddd;
              .val_text {
                color: #6ffcfc;
                font-size: .25rem;
              }
              .val_name{
                font-size: .175rem;
              }
            }
          }
        }
        .middle_box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
        }
      }
      .side_box {
        width: 25%;
        height: 100%;
        position: relative;
        .border_box {
          width: 100%;
          height: 100%;
        }
        .side_cont {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          box-sizing: border-box;
          padding: 20px;
          .btn_box {
            width: 100%;
            height: 0.7rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .btn_item {
              width: auto;
              height: 100%;
              position: relative;
              cursor: pointer;
              .selected_img {
                height: 100%;
              }
              .item_btn_name {
                font-size: .2rem;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                color: #389dcb;
              }
              .active_btn_name {
                color: #fff;
              }
            }
          }
          .data_box {
            width: 100%;
            height: 2.05rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // justify-content: space-around;
            box-sizing: border-box;
            padding: 0.125rem 0;
            .data_box_item {
              width: 50%;
              height: auto;
              display: flex;
              flex-direction: row;
              align-items: center;
              .icon_bg_box {
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 56px;
                background: rgba(106, 190, 230, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                .icon_cont_box {
                  width: 80%;
                  height: 80%;
                  border-radius: 135px;
                  background: rgba(106, 190, 230, 0.1);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .icon_png {
                    width: 0.375rem;
                    height: 0.375rem;
                  }
                }
              }
              .data_right_box {
                width: calc(100% - 0.7rem);
                height: 0.7rem;
                box-sizing: border-box;
                padding: 0.0625rem 0 0.0625rem 0.0625rem;
                color: #536791;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .Num_box {
                  font-size: 0.225rem;
                  font-weight: bold;
                  color: #fff;
                }
                .Num_name{
                  font-size: .175rem;
                }
              }
            }
          }
          .analytic_ranking {
            width: 100%;
            height: calc(70% - 2.75rem);
            box-sizing: border-box;
            position: relative;
            .analytic_Charts {
              width: 100%;
              height: 100%;
            }
          }
          .logic_application {
            width: 100%;
            height: calc(100% - 2.75rem);
            position: relative;
            .title{
              width: 100%;
              margin-top: 0.125rem;
              font-size: 0.2rem;
              line-height: 0.3125rem;
              color: #2ed3fc;
              font-weight: bold;
              position: relative;
              top: 0;
            }
            .myChart_class {
              width: 100%;
              height: calc(100% - 0.4375rem)
            }
            #regist_Chart{
              width: 100%;
              height: calc(100% - 0.2rem)
            }
          }
          .registration_Trends {
            width: 100%;
            height: 30%;
            position: relative;
            .myChart_class {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: -10px;
            }
            .title{
              font-size: .2rem;
            }
          }
          .visit_top10 {
            width: 100%;
            height: 70%;
            box-sizing: border-box;
            padding-top: 0.25rem;
            position: relative;
            .title {
              position: relative;
              font-size: .2rem;
            }
            .top10_cont {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              padding-top: 0.25rem;
              .top10_item {
                width: 100%;
                height: 0.375rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .index_box {
                  width: 0.325rem;
                  height: 100%;
                  box-sizing: border-box;
                  border-bottom: 2px solid #126197;
                  color: #126197;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: bold;
                }
                .company_name {
                  max-width: 1.4125rem;
                  height: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-size: 0.15rem;
                  color: #15a3cf;
                  margin: 0 5px;
                  display: flex;
                  align-items: flex-end;
                }
                .top3_name {
                  color: #cda917;
                }
                .num_progress {
                  width: calc(100% - 2rem);
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  .progress_box {
                    width: 100%;
                    height: 0.2rem;
                    box-sizing: border-box;
                    border: 1px solid #126197;
                    border-radius: 2px;
                    position: relative;
                    .active_progress {
                      position: absolute;
                      left: 0;
                      top: 0;
                      height: 100%;
                      box-sizing: border-box;
                      border: 2px solid #15aad5;
                      border-right: none;
                      background-image: linear-gradient(#15aad5, #15669e);
                    }
                    .top3_progress {
                      border: 2px solid #41cba1;
                      background-image: linear-gradient(#41cba1, #229367);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
