<template>
	<div class="havenLoginHome_box">
		<div class="header_box">
			<Header @clickMenuItem="clickMenuItem"></Header>
		</div>
		<div class="content_box">
			<div class="left_menu_box" id="width" :style="'width:'+leftWidth+'px'">
				<MenuList ref="menuRef" @clickMenuItem="clickMenuItem" @changeWidth="changeWidth"></MenuList>
			</div>
			<div class="right_cont_box" :style="'width:'+rightWidth">
				<div class="router_header">
					<RouterHeader ref="routerTab" @clickTabItem="clickTabItem" @reShowPage="reShowPage"></RouterHeader>
				</div>
				<div class="router_box" v-if="showPage">
					<router-view/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	import Header from './components/header.vue'
	import MenuList from './components/menuList.vue'
	import RouterHeader from './components/routerHeader.vue'
	export default {
		components:{
			Header,
			MenuList,
			RouterHeader,
		},
		data(){
			return {
				showPage:true,
				leftWidth:200,
			}
		},
		watch:{
			mainWidth(){
				
			}
		},
		computed:{
			...mapState({
				userInfo: state => state.userInfo,
				enterpriseStatus: state => state.enterpriseStatus,
			}),
			rightWidth(){
				return `calc(100% - ${this.leftWidth}px)`
			},
		},
		methods:{
			...mapActions({
				getBaseTemplate:'TempManage/getBaseTemplate',
			}),
			changeWidth(val){
				this.leftWidth = val;
			},
			clickMenuItem(obj){
				if(this.$route.path === obj.name){
					this.reShowPage();
				}else{
					this.$refs.routerTab.addTab(obj);
				}
			},
			clickTabItem(value){
				this.$refs.menuRef.changeActive(value);
			},
			reShowPage(){
				this.showPage = false;
				this.$nextTick(()=>{
					this.showPage = true;
				})
			},
		}, 
		mounted() {
			this.getBaseTemplate();
			if(this.userInfo.roleId !== 0 && this.enterpriseStatus === 0){
				this.clickMenuItem({
					name: "/networkAccessApply",
					title: "企业入网申请"
				});
			}else{
				this.$router.replace('/homePage')
			}
			

			
			function domChange(){
				var div = document.getElementById("width");
  		 	 var w = div.offsetWidth;
			// console.log(w)
			}
			domChange();

			// window.onresize = function () {
			// console.log('dom被改变了');
			// domChange();
			// }
			
		}
	}
</script>

<style lang="scss" scoped>
	.havenLoginHome_box{
		width: 100%;
		height: 100vh;
		.header_box{
			width: 100%;
			height: 66px;
		}
		.content_box{
			width: 100%;
			height: calc(100% - 66px);
			display: flex;
			flex-direction: row;
			.left_menu_box{
				// width: 220px;
				height: 100%;
			}
			.right_cont_box{
				// width: calc(100% - 200px);
				// width: calc(100% - 64px);
				// width: auto;
				height: 100%;
				.router_header{
					width: 100%;
					height: 40px;
				}
				.router_box{
					width: 100%;
					height: calc(100% - 40px);
					box-sizing: border-box;
					overflow: auto;
					box-sizing: border-box;
					padding-top: 5px;
				}
			}
		}
	}
</style>
