const menuList3 = [{
		title: '首页',
		name: '/homePage',
		icon: 'el-icon-s-home'
	}, {
		title: '驾驶舱',
		name: '/cockpit',
		icon: 'el-icon-s-grid'
	}, {
		title: '海工装备资料',
		name: '/equipmentData',
		icon: 'el-icon-s-order'
	},  
	
]

export default {
	menuList3,
}