<template>
  <div class="userSettings_box">
    <div class="box">
      <!-- <div class="boxItem">
				<div><el-avatar :size="100" :src="circleUrl"></el-avatar></div>
				<div><el-button type="primary" round class="updatePhoto">更换头像</el-button></div>
			</div> -->
      <div class="boxItem">
        <div>用户名</div>
        <!-- <div>{{ userInfo.username }}</div> -->
        <div>{{ enterpriseObj.username }}</div>
      </div>
      <!-- <div class="boxItem">
        <div>nickname</div>
        <div>{{ enterpriseObj.nickname }}</div>
      </div> -->
      <div class="boxItem">
        <div>企业名称</div>
        <!-- <div>{{ userInfo.companyName }}</div> -->
        <div>{{ enterpriseObj.companyName }}</div>
      </div>
      <div class="boxItem">
        <div>统一社会信用代码</div>
        <!-- <div>{{ userInfo.socialCreditCode }}</div> -->
        <div>
          {{
            enterpriseObj.socialCreditCode
              ? enterpriseObj.socialCreditCode
              : "暂无"
          }}
        </div>
      </div>
      <div class="boxItem">
        <div>手机</div>
        <!-- <div>{{ userInfo.accountTelephone }}</div> -->
        <div>{{ enterpriseObj.accountTelephone }}</div>
      </div>
      <div class="boxItem">
        <div>邮箱</div>
        <!-- <div>{{ userInfo.companyCode }}</div> -->
        <div>{{ enterpriseObj.contactsEmail}}</div>
      </div>
      <div class="boxItem Last_boxItem">
        <div>公司网址</div>
        <!-- <div>{{ enterpriseObj.companyQrUrl }}</div> -->
        <!-- <div class="inputBox"><input type="text" v-model="companyQrUrlValue" class="input" :placeholder='enterpriseObj.companyQrUrl'/></div> -->
        <div>
          <a href="javascript:void(0)" >{{ enterpriseObj.companyQrUrl ? enterpriseObj.companyQrUrl : "暂无" }}</a>&nbsp;&nbsp;<span @click="openORcode">点击查看二维码</span>
          &nbsp;&nbsp;<el-button type="primary" @click="updateViewUrl">修改网址</el-button>
          <!-- dialogVisible2 = true -->
        </div>

        <!-- 二维码 -->
        <!-- <div class="code_box companyUrl" v-if="showCodeBox">
          <div id="qrcode" ref="qrcode"></div>
        </div> -->

      </div>

      <div class="btnBox">
        <!-- <el-button type="primary" @click="dialogVisible2 = true"
          >修改资料</el-button> -->
        <el-button type="primary" @click="showModel = true">修改密码</el-button>
      </div>

      <!-- <div>{{applyEnterprise.data}}</div> -->
    </div>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="showModel" width="800px">
      <div class="dialog_content_box">
        <div class="pwd_item">
          <div class="key">新密码</div>
          <div class="val">
            <el-input
              placeholder="请输入新密码"
              v-model="password"
              show-password
              clearable
            ></el-input>
          </div>
        </div>
        <div class="pwd_item">
          <div class="key">确认密码</div>
          <div class="val">
            <el-input
              placeholder="请再次输入新密码"
              v-model="passwordAgin"
              show-password
              clearable
            ></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModel = false">取消</el-button>
        <el-button type="primary" @click="updatePwd">确定</el-button>
      </span>
    </el-dialog>

    <!-- 修改资料 -->
    <el-dialog
      title="修改信息"
      :visible.sync="dialogVisible2"
      width="30%"
      :before-close="handleClose2"
    >
      <el-form label-width="80px" :model="formUpdate" ref="updateForm">
        <!-- <el-form-item label="id" prop="id">
            <el-input v-model="formUpdate.id" disabled></el-input>
          </el-form-item> -->
          
        <!-- <el-form-item label="昵称" prop="nickname">
          <el-input v-model.trim="formUpdate.nickname" clearable></el-input>
        </el-form-item> -->

        <!-- <el-form-item label="头像" prop="logoUrl">
          <el-input v-model.trim="formUpdate.logoUrl" clearable></el-input>
        </el-form-item> -->

        <el-form-item label="网址" prop="name">
          <el-input v-model.trim="formUpdate.companyQrUrl" clearable></el-input>
          <!-- <el-button type="primary" @click="updateUrl()">修改网址</el-button> -->
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose2">取消</el-button>
        <el-button type="primary" @click="updateUser">修改</el-button>
      </span>
    </el-dialog>

    <!-- 二维码 -->
      <!-- :modal=false -->
    <el-dialog
      :visible.sync="dialogVisible3"
      width="20%"
      :before-close="handleClose3">
      <!-- <span>这是一段信息</span> -->
      <div class="code_box companyUrl" v-if="showCodeBox">
          <div id="qrcode" ref="qrcode"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import QRCode from "qrcodejs2"; // 引入qrcode
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      showModel: false,
      password: "",
      passwordAgin: "",
      enterpriseObj: {},
      showCodeBox: true,
      dialogVisible2: false, //修改框
      dialogVisible3: false, //二维码框
      formUpdate: {
        nickname: "",
        logoUrl: "",
        companyQrUrl: "",
      },
    };
  },
  watch: {
    resetPasswordResult() {
      if (this.resetPasswordResult.success) {
        this.$message({
          showClose: true,
          message: "密码修改成功,请重新登录！",
          type: "success",
        });
        this.loginOutSys();
        this.$router.replace("/login");
      } else {
        this.$message({
          showClose: true,
          message: this.resetPasswordResult.message,
          type: "error",
        });
      }
    },
    updateUserInfo() {
      if (this.updateUserInfo.success) {
        this.$message({
          showClose: true,
          message: this.updateUserInfo.message,
          type: "success",
        });
        this.getApplyEnterprise();
      } else {
        this.$message({
          showClose: true,
          message: this.updateUserInfo.message,
          type: "error",
        });
      }
    },
    applyEnterprise() {
      if (this.applyEnterprise.success) {
        this.$message({
          showClose: true,
          message: this.applyEnterprise.message,
          type: "success",
        });
        this.enterpriseObj = this.applyEnterprise.data;
        console.log("请求成功");
        // console.log(this.enterpriseObj);
        // console.log(this.enterpriseObj.companyQrUrl);
        // replace(this.qrcode())

        // this.qrcode();
      } else {
        this.$message({
          showClose: true,
          message: this.applyEnterprise.message,
          type: "error",
        });
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      userToken: (state) => state.userToken,
      resetPasswordResult: (state) => state.AboutLogin.resetPasswordResult,
      updateUserInfo: (state) => state.SystenManage.updateUserInfo,
      applyEnterprise: (state) => state.SystenManage.applyEnterprise,
    }),
  },
  methods: {
    updateViewUrl(){
      this.dialogVisible2=true;
      this.formUpdate.companyQrUrl = this.enterpriseObj.companyQrUrl;
      
    },
    openORcode(){
      this.dialogVisible3= true;
      this.qrcode();
    },
    //关闭Dialog时清空表单
    resetForm2() {
      //获取ref=addForm的标签
      this.$refs["updateForm"].resetFields();
    },
    handleClose3() {
      this.dialogVisible3 = false;
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.resetForm2();
    },
    ...mapMutations({
      loginOutSys: "loginOutSys",
    }),
    ...mapActions({
      resetPassword: "AboutLogin/resetPassword",
      getUpdateUserInfo: "SystenManage/getUpdateUserInfo",
      getApplyEnterprise: "SystenManage/getApplyEnterprise", //查询企业信息
    }),
    qrcode() {
      this.showCodeBox = false;
      this.$nextTick(() => {
        this.showCodeBox = true;
        this.$nextTick(() => {
          new QRCode("qrcode", {
            // width: 100,
            // height: 100,
            width: 250,
            height: 250,
            text: this.enterpriseObj.companyQrUrl, // 二维码地址
          });
        });
      });

      //   let qrcode = new QRCode("qrcode", {
      //     width: 100,
      //     height: 100, // 高度
      //     text: this.enterpriseObj.companyQrUrl, // 二维码内容
      //     render: "table", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      //     // background: '#f0f',
      //     // foreground: '#ff0'
      // 	correctLevel: QRCode.CorrectLevel.H,

      //   });
    },

    showUpdatePwd() {},
    updatePwd() {
      if (this.passwordAgin != this.password) {
        this.$message({
          showClose: true,
          message: "两次密码输入不一致",
          type: "warning",
        });
        return;
      }
      let params = {
        userToken: this.userToken,
        passwordInfo: this.$aesEncrypt(
          JSON.stringify({
            password: this.password,
          })
        ),
      };
      this.resetPassword(params);
    },
    updateUrl() {
      let params = {
        companyQrUrl: this.formUpdate.companyQrUrl,
      };
      this.getUpdateUserInfo(params);
    },
    updateUser() {
      let params = {
        nickname: this.formUpdate.nickname,
        logoUrl: this.formUpdate.logoUrl,
        companyQrUrl: this.formUpdate.companyQrUrl,
      };
      this.getUpdateUserInfo(params);
      this.dialogVisible2=false;
    },
  },
  mounted() {
    this.getApplyEnterprise();
    // this.qrcode();
    console.log("信息");
    // console.log(this.enterpriseObj.username);
    this.$nextTick(() => {
      // console.log(111111);
      // console.log(this.enterpriseObj);
    });
    this.$nextTick(function () {});
  },
  destroyed() {
    //   this.qrcode()
  },
};
</script>

<style lang="scss" scoped>
.userSettings_box {
  width: 100%;
  height: auto;
  padding-left: 100px;
  box-sizing: border-box;
  .dialog_content_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 30px 30px 30px;
    .pwd_item {
      width: 100%;
      height: 40px;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .key {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .val {
        width: calc(100% - 120px);
        height: 100%;
      }
    }
  }
}

.updatePhoto {
  margin-left: 50px;
}
.el-avatar {
  margin-bottom: -20px;
}

.box {
  width: 800px;
  margin-top: 50px;
}

.boxItem {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  margin-top: 10px;
}
// .box > div:nth-of-type(1) {
// 	align-items: center;
// }
.boxItem > div {
  line-height: 38px;
  height: 38px;
  margin: 5px 0;
}
.boxItem > div:first-of-type {
  height: auto;
}
.boxItem > div:nth-of-type(1) {
  // width: 135px;
  width: 155px;
  text-align: left;
}
.boxItem > div:nth-of-type(2) {
  padding-left: 10px;
  text-align: left;
  border-radius: 9px;
  width: 358px;
  background-color: rgba(229, 229, 229, 1);
}
.boxItem > div:nth-of-type(2):hover {
  cursor: not-allowed;
}

// .boxItem:nth-of-type(1) > div:nth-of-type(1) {
// 	width: 135px;
// 	text-align: left;
// }
// .boxItem:nth-of-type(1) > div:nth-of-type(2) {
// 	background: none;
// 	border: none;
// }
.Last_boxItem div:nth-of-type(2){
  background-color: #fff !important;
  cursor: pointer !important;

}
.code_box {
  // background-color: #fff;
  padding-bottom: 20px; //
  box-sizing: border-box;
	// margin-bottom: 40px !important;
  height: 300px !important;
  display: flex;
  justify-content: center;
  align-items: center;

}
.inputBox {
  padding-left: 0 !important;
  border-radius: 9px;
  width: 358px !important;
}
.input {
  width: 100%;
  height: 100%;
}

.btnBox {
  margin-top: 30px;
}
</style>
