<template>
	<div class="menuList_box">
      <el-aside class="aside" :width="isCollapse ? '64px' : '200px'" >

        <!-- <div class="toggle-button" @click="toggleCollapse">|||</div> -->
		<div  :class="[isCollapse ? 'displayNone':'dislpayArrow']"><a class="picBtn" :class="[isCollapse ? 'iconHiddle':'iconOpen']" href="javascript:void(0);" @click="toggleCollapse(this)"></a></div>
		<el-menu default-active="1-4-1" class="el-menu-vertical-demo" :default-active="activeIndex" :collapse-transition="false"  
		:collapse="isCollapse" background-color="#00508F" text-color="#fff" active-text-color="#fff">
		  <el-menu-item v-for="(item,num) in list" :key="item.name" :index="item.name" @click="clickItem(item)">
		    <i :class="item.icon"></i>
		    <span slot="title">{{item.title}}</span>
		  </el-menu-item>
			<el-submenu v-for="(item,num) in listChild" :key="item.name" :index="item.name">
			  <template slot="title">
			    <i :class="item.icon"></i>
			    <span slot="title">{{item.title}}</span>
			  </template>
				<el-menu-item v-for="(child,index2) in item.childrens" :key="child.name" :index="child.name" @click="clickItem(child)">{{child.title}}</el-menu-item>
			</el-submenu>
		</el-menu>

	  </el-aside>
	</div>
</template>

<script>
	import menu from '../../../common/commonData/menuList.js'
	import menuList_user from '../../../common/commonData/menuList_user.js'
	import {mapActions, mapState} from 'vuex'
	export default {
		data(){
			return {
				isCollapse: false,
				activeIndex:'/indexPage',
				list:[],
				listChild:[],
			}
		},
		computed:{
			...mapState({
				userInfo: state => state.userInfo,
			}),
		},
		methods:{
			toggleCollapse() {
			  this.isCollapse = !this.isCollapse;
			  let val = this.isCollapse?64:200;
			  this.$emit('changeWidth',val);
			},
			clickItem(item){
				this.$emit('clickMenuItem',item);
			},
			changeActive(value){
				this.activeIndex = value;
			},
		},
		mounted() {
			let listArr = [];
			let listChildArr = [];
			let menuList = JSON.parse(JSON.stringify(menu.menuList));
			menuList.map(item =>{
				if(item.childrens){
					listChildArr.push(item);
				}else{
					listArr.push(item);
				}
			});
			this.list = listArr;
			// console.log(this.userInfo.roleId);
			// console.log(listChildArr)
			if(this.userInfo.roleId === 0){
				this.listChild = listChildArr;
			}else if(this.userInfo.roleId === 1){
				let i = -1;
				let j = -1;
				listChildArr.map((item,index) => {
					item.childrens.map((child,index2) => {
						if(child.name === '/userManage'){
							i = index;
							j = index2;
						}
					})
				})
				listChildArr[i].childrens.splice(j,1);
				this.listChild = listChildArr;
			}else{
			// let menuList3 = JSON.parse(JSON.stringify(menuList_user.menuList3));
			let menuList3 = menuList_user.menuList3;
				this.list=menuList3
			}
		}
	}
</script>

<style lang="scss" scoped>
	.menuList_box{
		width: 100%;
		height: 100%;
		background-color: $theam-bgcolor;
		overflow: auto;
		.aside{
			height: 100%;
		}
		.el-menu-item{
			font-size: $base-font-size;
		}
		.el-menu-item i ,.el-submenu__title i{
		  color: #fff;
		}
		.el-menu-item [class^=el-icon-]{
			font-size: 20px;
		}
		.el-submenu [class^=el-icon-]{
			font-size: 20px;
		}
		.el-menu{
			border-right: none;
		}
		.is-active{
			background-color: rgb(0, 64, 114)!important;
		}
	}
	/* 左侧收缩按钮 */
		// .toggle-button {
		// // background-color: #4a5064;
		// background-color: #0b62a5;
		// font-size: 10px;
		// line-height: 24px;
		// text-align: center;
		// letter-spacing: 0.2em;
		// cursor: pointer;
		// color: white;
		// }
		// .toggle-button:hover{
		// 	background: #004072;
		// }

		.dislpayArrow {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 200px;
			width: 0px;
			height: 100%;
			z-index: 10000;
		}
		// .dislpayArrow a {
		// 	position: absolute;
		// 	display: block;
		// 	width: 17px;
		// 	height: 61px;
		// 	top: 50%;
		// 	margin-top: -30px;
		// 	outline: none;
		// }
		
		.displayNone{
			position: absolute;
			top: 0;
			bottom: 0;
			// left: 200px;
			left: 64px !important;
			width: 0px;
			height: 100%;
			z-index: 10000;
		}
		.picBtn{
			position: absolute;
			display: block;
			width: 17px;
			height: 61px;
			top: 50%;
			margin-top: -30px;
			outline: none;
		}
		// .displayNone a {
		// 	position: absolute;
		// 	display: block;
		// 	width: 17px;
		// 	height: 61px;
		// 	top: 50%;
		// 	margin-top: -30px;
		// 	outline: none;
		// }
		.iconOpen{
			background: url(icon_arrow.png) no-repeat -21px 0;
		}
		.iconHiddle {
			background: url(icon_arrow.png) no-repeat 0 0;
			// background-position: 0 -61px;
			background-position: -21px -61px;
		}

</style>
