<template>
  <div class="base_box">
    <div class="content_box">
      <div class="title_name">模板基本信息</div>
      <div class="cont_item">
        <div class="key">
          <span class="relative_key">
            模板ID
            <span class="key_tips">*</span>
          </span>
        </div>
        <div class="val">
          <el-input
            class="input_box"
            placeholder="模板ID(字母/数字/下划线)"
            :disabled="fromFun == 'detail'"
            v-model="dataObj.templateName"
            clearable
          ></el-input>
        </div>
      </div>
      <div class="cont_item">
        <div class="key">
          <span class="relative_key">
            模板名称
            <span class="key_tips">*</span>
          </span>
        </div>
        <div class="val">
          <el-input
            class="input_box"
            placeholder="模板名称"
            :disabled="fromFun == 'detail'"
            v-model="dataObj.templateTitle"
            clearable
          ></el-input>
        </div>
      </div>
      <div class="cont_item">
        <div class="key"><span class="relative_key">模板描述</span></div>
        <div class="val">
          <el-input
            class="input_box"
            placeholder="模板描述"
            :disabled="fromFun == 'detail'"
            type="textarea"
            :rows="2"
            v-model="dataObj.templateDescribe"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="content_box">
      <div class="title_name">自定义模板列</div>
      <div class="temp_table_box">
        <div class="add_header_box">
          <el-button
            type="primary"
            size="small"
            @click="addTheLine"
            v-if="fromFun != 'detail'"
            >添加一行</el-button
          >
        </div>
        <div class="table_box">
          <el-table
            size="mini"
            :max-height="tableMaxHeight"
            :data="tableData"
            border
          >
            <el-table-column
              type="index"
              label="序号"
              width="50"
            ></el-table-column>
            <el-table-column
              v-for="(item, index) in tableHeader"
              :prop="item.prop"
              :label="item.label"
              :key="index"
            >
              <template slot-scope="scope">
                <span v-if="fromFun == 'detail'">{{
                  scope.row[item.prop]
                }}</span>
                <el-input
                  v-else
                  size="small"
                  type="text"
                  v-model="scope.row[item.prop]"
                  placeholder="请输入值"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="80"
              fixed="right"
              v-if="fromFun != 'detail'"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="submit_box" v-if="fromFun != 'detail'">
      <el-button size="small" type="primary" @click="submit"
        >保存模板</el-button
      >
    </div>

    <!-- <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handClose()"
    >
      <span v-for="item in 1" :key="item">这是一段信息</span>
      <span>这是一段信息</span>
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handClose()">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
		dialogVisible: false,
      fromFun: "add",
      dataObj: {
        templateName: "", //模板ID
        templateTitle: "", //模板名称
        templateDescribe: "", //模板描述
      }, //初始obj
      tableData: [
        {
          field: "",
          name: "",
        },
      ],
      tableHeader: [
        {
          prop: "name",
          label: "模板列名(中文)",
        },
        {
          prop: "field",
          label: "模板列名(英文)",
        },
      ],
      addLine: {
        name: "",
        field: "",
      },
    };
  },
  watch: {
    updateAddResult() {
      if (this.updateAddResult.success) {
        this.$message({
          showClose: true,
          message: "保存成功",
          type: "success",
        });
        this.$emit("refreshDataList");
      } else {
        this.$message({
          showClose: true,
          message: this.updateAddResult.message,
          type: "error",
        });
      }
    },
  },
  computed: {
    ...mapState({
      updateAddResult: (state) => state.TempManage.updateAddResult,
    }),
    tableMaxHeight() {
      let h = document.documentElement.clientHeight * 0.9;
      return h - 440;
    },
  },
  methods: {
	  handClose(){
		this.dialogVisible = false;
	  },
    ...mapActions({
      uploadTemplate: "TempManage/uploadTemplate",
      updateTemplate: "TempManage/updateTemplate",
    }),
    init(obj, fromFun) {
      // console.log(fromFun);
      this.fromFun = fromFun;
      if (!obj.id) {
        return;
      }
      this.dataObj = obj;
      // console.log(obj);
      let templateInfo = JSON.parse(obj.templateInfo);
      this.tableData = templateInfo;
    },
    handleDelete(index) {
      // this.tableData.splice(index, 1);
      // this.$message.success("删除成功");

      this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });


    },
    addTheLine() {
      const line = JSON.parse(JSON.stringify(this.addLine));
      this.tableData.push(line);
    },

    repetition() {
      //重名验证
    },
    submit() {

      this.$confirm('确定保存吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning'
        }).then(() => {
      //////////////////////////////////
      // this.dialogVisible = true;
      let list = this.getTemplateInfo();
      var errorArr_China = []; //中文
      var errorArr_Eng = [];
      list.map((item, index) => {
        // console.log(item);
        // console.log(index);
        let nowField = item.field;
        let nowName = item.name;
        for (let i = list.length - 1; i > index; i--) {
          if (nowName == list[i].name) {
            errorArr_China.push({
              fIndex: index + 1,
              lIndex: i + 1,
              errorName: "模板列名（中文）",
            });
          }
          if (nowField == list[i].field) {
            errorArr_Eng.push({
              fIndex: index + 1, //当前行
              lIndex: i + 1, //原本存在的行
              errorScript: "模板列名（英文）",
            });
          }
        }
      });
      //   console.log(errorArr.length);
      //   console.log(...errorArr);
      //   alert(...[errorArr.fIndex]);

      var errorArrC = [...errorArr_China];
      var errorArrE = [...errorArr_Eng];
      // console.log(11111111);
      // console.log(errorArrC);
      //   console.log(errorArrE);

      if (errorArrC.length > 0 || errorArrE.length > 0) {
        // if (errorArrC.length > 0) {
          var desc='';
          // for (var i = 0; i < errorArrC.length; i++) {
          //   // alert("序号" +errorArrC[i].fIndex +"行与序号" +errorArrC[i].lIndex +"行" + errorArrC[i].errorName +"重复");
          //    desc="序号" +errorArrC[i].fIndex +"行与序号" +errorArrC[i].lIndex + "行" +errorArrC[i].errorName +"重复";
           
          //  this.$alert(desc, '提示', {
          //       confirmButtonText: '确定',
          //       type: 'warning',
          //      });
               
          // }
          //  var desc = [];
                for (var i = 0; i <errorArrC.length; i++) {
                    desc +='<p>' +"序号<span  class='ch'>" + errorArrC[i].fIndex + "</span>行与序号<span  class='ch'>" + errorArrC[i].lIndex + "</span>行&nbsp;<span  class='ch'>" + errorArrC[i].errorName + "</span>重复"+"\n"+'</p>';
                }
	              //  console.log(desc)
                // this.$alert(desc, '提示', {
                //   dangerouslyUseHTMLString: true,
                //         confirmButtonText: '确定',
                //         type: 'warning',
                //   });
                  var desc2 = '';
               for (var i = 0; i <errorArrE.length; i++) {
                    desc2 +='<p>' +"序号<span  class='eng'>" + errorArrE[i].fIndex + "</span>行与序号<span  class='eng'>" + errorArrE[i].lIndex + "</span>行&nbsp;<span  class='eng'>" + errorArrE[i].errorScript + "</span>重复"+"\n"+'</p>';
                }
	              //  console.log(desc)
                this.$alert(desc+desc2, '提示', {
                  dangerouslyUseHTMLString: true,
                        confirmButtonText: '确定',
                        type: 'warning',
                  });
        // } else {
           
        // }

        return;
      }

      let params = {
        templateName: this.dataObj.templateName,
        templateTitle: this.dataObj.templateTitle,
        templateDescribe: this.dataObj.templateDescribe,
        templateInfo: list,
        // templateInfo: this.getTemplateInfo(),
      };

      if (this.dataObj.id) {
        //编辑
        params.id = this.dataObj.id;
        this.updateTemplate(params);
      } else {
        //新增
        this.uploadTemplate(params);
      }

          // this.$message({
          //   type: 'success',
          //   message: '保存成功!'
          // });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });

		
    },


    getTemplateInfo() {
      let arr = [];
      this.tableData.map((item, index) => {
        if (item.name && item.field) {
          arr.push({
            name: item.name,
            field: item.field,
            index: index + 1,
          });
      //  console.log(arr[index].name);
      //  console.log(arr[index].field);
        // if(arr[index].field==undefined){
        //   alert('请输入name')
        //   return  
        // }
        //  if(arr[index].name==undefined){
        //   alert('请输入field')
        //   return     
        // }
    
        }
      });
        
      return arr;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.base_box {
  width: 100%;
  height: calc(90vh - 80px);
  overflow: auto;
  box-sizing: border-box;
  padding-bottom: 30px;
  .content_box {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title_name {
      font-size: 15px;
      font-weight: bold;
    }
    .cont_item {
      width: 100%;
      min-height: 40px;
      display: flex;
      align-items: center;
      color: #888;
      margin-top: 20px;
      box-sizing: border-box;
      padding-left: 20px;
      .key {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        .relative_key {
          position: relative;
          .key_tips {
            color: #f82b2b;
            position: absolute;
            right: -8px;
            top: -2px;
          }
        }
      }
      .val {
        width: calc(100% - 140px);
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-items: flex-start;
        position: relative;
        .input_box {
          width: 580px;
        }
      }
    }
    .temp_table_box {
      width: 100%;
      height: auto;
      background-color: #fff;
      .add_header_box {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: flex-end;
      }
      .table_box {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        .cursor_style {
          cursor: pointer;
        }
        .noValClass {
          color: #c4c4cc;
        }
      }
    }
  }
  .submit_box {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
  }
}


</style>

<style>
span.eng {
    color: #007ACC !important;
}
span.ch {
    color: #F56C6C !important;
}
</style>
