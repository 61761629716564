<template>
  <div class="enterprise_register_box">
    <div class="header_step_box">
      <el-steps
        :active="enterpriseStatus == 2 ? 4 : enterpriseStatus + 1"
        simple
        finish-status="success"
      >
        <el-step title="申请登录账户"></el-step>
        <el-step title="提交入网申请"></el-step>
        <el-step title="等待审核"></el-step>
        <el-step title="审核通过,获得企业入网身份ID"></el-step>
      </el-steps>
    </div>
    <div class="content_container" v-if="enterpriseStatus === 0">
      <div class="content_box">
        <div class="title_name">企业基本信息</div>
        <!-- from -->
        <!-- <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"> -->
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              企业标识
              <span class="key_tips">*</span>
            </span>
          </div>
          <!-- @change="jiedian" -->
          <div class="val">
            <el-input
              class="input_box input_biaoshi"
              placeholder="请输入企业二级节点标识(必须跟二级节点系统上的企业标识对应)"
              v-model.trim="formObj.companyCode"
              clearable
              @blur="companyCode"
              :style="thisStyle"
            ></el-input>
          </div>
          <!-- <el-form-item label="企业标识" prop="pass">
						<el-input type="password" v-model.trim="formObj.companyCode" autocomplete="off" ></el-input>
					</el-form-item> -->
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              企业/机构名称
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输入企业/机构完整名称"
              v-model="formObj.companyName"
              clearable
              @blur="companyName"
              :style="thisStyle2"
            ></el-input>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              企业/机构类型
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-select
              class="input_box"
              v-model="formObj.companyType"
              clearable
              placeholder="请选择企业类型"
              @change="companyType"
              :style="thisStyle3"
            >
              <el-option
                v-for="item in companyNatureList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              统一社会信用代码
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输入统一社会信用代码"
              v-model="formObj.socialCreditCode"
              clearable
              @blur="socialCreditCode"
              :style="thisStyle4"
            ></el-input>
          </div>
          <!-- <div class="val">
						<el-select class="select_box" v-model="formObj.companyIDType" clearable placeholder="请选择机构证件类型">
							<el-option v-for="item in companyIDTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
						<el-input class="select_input_box" placeholder="请输机构证件号码" v-model="formObj.socialCreditCode" clearable></el-input>
					</div> -->
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              企业/机构行业
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-cascader
              class="input_box"
              size="large"
              :options="industryTypeOptions"
              v-model="formObj.industryType"
              @change="industryTypeChange"
              placeholder="请选择企业/机构行业类型"
              filterable
              clearable
              :style="thisStyle5"
            ></el-cascader>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              注册地址
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-cascader
              class="input_box"
              size="large"
              :options="registeredAddressOptions"
              v-model="formObj.registeredAddress"
              @change="registeredAddressChange"
              placeholder="请选择注册地址"
              filterable
              clearable
              :style="thisStyle6"
            ></el-cascader>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              企业详细地址
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-input
              class="input_box"
              type="textarea"
              :rows="2"
              placeholder="请输入企业详细地址"
              v-model="formObj.companyAddress"
              @blur="companyAddress"
              :style="thisStyle7"
            ></el-input>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              企业联系电话
              <!-- <span class="key_tips">*</span> -->
            </span>
          </div>
          <!-- <div class="val"><el-input class="input_box" placeholder="请输入企业联系电话" v-model="formObj.contactsPhone" clearable @blur="contactsPhone" :style="thisStyle8"></el-input></div> -->
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输入企业联系电话"
              v-model="formObj.contactsPhone"
              clearable
              :style="thisStyle8"
            ></el-input>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              营业执照
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <div class="upload_box">
              <el-upload
                class="avatar-uploader"
                :action="ActionUrl"
                :show-file-list="false"
                :on-success="companyIDPhotoSuccess"
                :before-upload="companyIDPhotoUpload"
              >
                <img
                  v-if="formObj.licenseUrl"
                  :src="formObj.licenseUrl"
                  class="img_upload"
                />
                <div class="companyID_img_box" v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </div>
            <div class="tips_box">
              <div>注：</div>
              <div>1、照片大小不得超过5MB；</div>
              <div>2、格式应为jpeg/jpg/png。</div>
            </div>
          </div>
        </div>
        <div class="cont_item">
          <div class="key"><span class="relative_key">官方网站</span></div>
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输入企业官网"
              v-model="formObj.officialWebsite"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="cont_item">
          <div class="key"><span class="relative_key">企业简介</span></div>
          <div class="val">
            <el-input
              class="input_box"
              type="textarea"
              :rows="2"
              placeholder="请输入企业简介"
              v-model="formObj.introduce"
            ></el-input>
          </div>
        </div>
        <!-- </el-form> -->
        <!-- form -->
      </div>
      <div class="content_box">
        <div class="title_name">法人信息</div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              法人姓名
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输证件上的用户姓名"
              v-model="formObj.corporationName"
              clearable
              @blur="corporationName"
              :style="thisStyle10"
            ></el-input>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              法人身份证号
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输入法人身份证号"
              v-model="formObj.corporationId"
              clearable
              @blur="corporationId"
              :style="thisStyle11"
            ></el-input>
          </div>
          <!-- <div class="val">
						<el-select class="select_box" v-model="formObj.corpIDType" clearable placeholder="请选择证件类型">
							<el-option v-for="item in contactIDTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
						<el-input class="select_input_box" placeholder="请法人输入证件号码" v-model="formObj.corporationId" clearable></el-input>
					</div> -->
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              法人证件照片
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <div class="upload_box">
              <el-upload
                class="avatar-uploader"
                :action="ActionUrl"
                :show-file-list="false"
                :on-success="corpFrontSuccess"
                :before-upload="corpFrontUpload"
              >
                <img
                  v-if="corpFrontImgUrl"
                  :src="corpFrontImgUrl"
                  class="img_upload"
                />
                <div class="img_box" v-else>
                  <img src="@/assets/pages/systemManage/front.png" alt="" />
                  <img
                    class="add_img"
                    src="@/assets/pages/systemManage/add.png"
                    alt=""
                  />
                </div>
              </el-upload>
            </div>
            <div class="upload_box" style="margin-left: 20px">
              <el-upload
                class="avatar-uploader"
                :action="ActionUrl"
                :show-file-list="false"
                :on-success="corpBackSuccess"
                :before-upload="corpBackUpload"
              >
                <img
                  v-if="corpBackImgUrl"
                  :src="corpBackImgUrl"
                  class="img_upload"
                />
                <div class="img_box" v-else>
                  <img src="@/assets/pages/systemManage/back.png" alt="" />
                  <img
                    class="add_img"
                    src="@/assets/pages/systemManage/add.png"
                    alt=""
                  />
                </div>
              </el-upload>
            </div>
            <div class="tips_box" style="left: 400px">
              <div>注：</div>
              <div>1、照片大小不得超过5MB；</div>
              <div>2、格式应为jpeg/jpg/png。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_box">
        <div class="title_name">联系人基本信息</div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              联系人姓名
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输证件上的联系人姓名"
              v-model="formObj.contacts"
              clearable
              @blur="contacts"
              :style="thisStyle13"
            ></el-input>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              联系人手机号
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <!-- <el-input class="input_box" placeholder="请输入联系人手机号" v-model="formObj.companyTelephone" clearable @blur="companyTelephone" :style="thisStyle14"></el-input> -->
            <el-input
              class="input_box"
              placeholder="请输入联系人手机号"
              v-model="formObj.companyTelephone"
              clearable
              @blur="companyTelephone"
              :style="thisStyle14"
            ></el-input>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              联系人邮箱
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输入联系人邮箱"
              v-model="formObj.contactsEmail"
              clearable
              @blur="contactsEmail"
              :style="thisStyle15"
            ></el-input>
          </div>
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              联系人身份证号
              <span class="key_tips">*</span>
            </span>
          </div>
          <div class="val">
            <el-input
              class="input_box"
              placeholder="请输入联系人身份证号"
              v-model="formObj.contactsId"
              clearable
              @blur="contactsId"
              :style="thisStyle16"
            ></el-input>
          </div>
          <!-- <div class="val">
						<el-select class="select_box" v-model="formObj.contactIDType" clearable placeholder="请选择证件类型">
							<el-option v-for="item in contactIDTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
						<el-input class="select_input_box" placeholder="请输入联系人证件号码" v-model="formObj.contactsId" clearable></el-input>
					</div> -->
        </div>
        <div class="cont_item">
          <div class="key">
            <span class="relative_key">
              证件照片
              <!-- <span class="key_tips">*</span> -->
            </span>
          </div>
          <div class="val">
            <div class="upload_box">
              <el-upload
                class="avatar-uploader"
                :action="ActionUrl"
                :show-file-list="false"
                :on-success="contactFrontSuccess"
                :before-upload="contactFrontUpload"
              >
                <img
                  v-if="contactFrontImgUrl"
                  :src="contactFrontImgUrl"
                  class="img_upload"
                />
                <div class="img_box" v-else>
                  <img src="@/assets/pages/systemManage/front.png" alt="" />
                  <img
                    class="add_img"
                    src="@/assets/pages/systemManage/add.png"
                    alt=""
                  />
                </div>
              </el-upload>
            </div>
            <div class="upload_box" style="margin-left: 20px">
              <el-upload
                class="avatar-uploader"
                :action="ActionUrl"
                :show-file-list="false"
                :on-success="contactBackSuccess"
                :before-upload="contactBackUpload"
              >
                <img
                  v-if="contactBackImgUrl"
                  :src="contactBackImgUrl"
                  class="img_upload"
                />
                <div class="img_box" v-else>
                  <img src="@/assets/pages/systemManage/back.png" alt="" />
                  <img
                    class="add_img"
                    src="@/assets/pages/systemManage/add.png"
                    alt=""
                  />
                </div>
              </el-upload>
            </div>
            <div class="tips_box" style="left: 400px">
              <div>注：</div>
              <div>1、照片大小不得超过5MB；</div>
              <div>2、格式应为jpeg/jpg/png。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bootom_submit_box">
        <el-button type="primary" @click="submit">{{
          applyEnterprise.data.companyCode != null ? "重新提交申请" : "提交申请"
        }}</el-button>
      </div>
    </div>
    <!--  -->
    <div class="step3_box" v-if="enterpriseStatus === 1">
      您已提交企业入网申请，请耐心等待管理员审核，预计需2~3个工作日&nbsp;
      <el-button type="primary" @click="updateInfo">修改提交信息</el-button>
    </div>
    <div class="detail_box" v-if="enterpriseStatus === 2">
      <detailModel v-if="showModel" ref="modelRef"></detailModel>
    </div>
  </div>
</template>

<script>
import { categoryData } from "element-china-category-data";
import { regionData, CodeToText } from "element-china-area-data";
import { mapActions, mapMutations, mapState } from "vuex";
import detailModel from "./user-detail-or-examine.vue";
export default {
  components: {
    detailModel,
  },
  data() {
    return {
      thisStyle: "",
      thisStyle2: "",
      thisStyle3: "",
      thisStyle4: "",
      thisStyle5: "",
      thisStyle6: "",
      thisStyle7: "",
      thisStyle8: "",
      thisStyle9: "",
      thisStyle10: "",
      thisStyle11: "",
      thisStyle12: "",
      thisStyle13: "",
      thisStyle14: "",
      thisStyle15: "",
      thisStyle16: "",
      thisStyle17: "",
      // 	rules: {
      //   name: [
      //     { required: true, message: '请输入活动名称', trigger: 'blur' },
      //     { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
      //   ],
      //   region: [
      //     { required: true, message: '请选择活动区域', trigger: 'change' }
      //   ],
      // },
      showModel: false,
      failPass: false, //是否未通过审核
      industryTypeOptions: categoryData, // 行业下拉选择大数据对象
      registeredAddressOptions: regionData, // 省市区三级联动数据
      contactIDTypeList: [
        {
          label: "身份证",
          value: "1",
        },
      ], // 证件类型列表
      companyNatureList: [
        {
          label: "国有控股",
          value: "国有控股",
        },
        {
          label: "民营控股",
          value: "民营控股",
        },
        {
          label: "外商控股",
          value: "外商控股",
        },
        {
          label: "事业单位",
          value: "事业单位",
        },
        {
          label: "民营非盈利组织",
          value: "民营非盈利组织",
        },
      ], // 企业类型列表
      companyIDTypeList: [
        {
          label: "统一社会信用代码",
          value: "1",
        },
      ], // 企业类型列表
      contactFrontImgUrl: "", //联系人正面 证件照
      contactBackImgUrl: "", //联系人反面 证件照
      corpFrontImgUrl: "", //法人正面 证件照
      corpBackImgUrl: "", //法人反面 证件照
      formObj: {
        contacts: "", // 联系人姓名
        companyTelephone: "", //联系人手机号
        contactsEmail: "", //联系人邮箱
        contactsId: "", // 联系人证件号
        contactIDType: "1", // 请选择证件类型
        corporationName: "", // 法人姓名
        corpIDType: "1", // 法人证件类型
        corporationId: "", // 法人证件号
        companyCode: "", //企业标识
        companyName: "", //企业名称
        companyType: "", // 企业类型
        // companyIDType: '1', //机构证件类型
        socialCreditCode: "", //机构证件号
        licenseUrl: "", // 营业执照照片
        industryType: [], // 行业类型
        registeredAddress: [], // 注册地址
        officialWebsite: "", //官网
        companyAddress: "", //企业详细地址
        introduce: "", //企业简介
        contactsPhone: "", // 企业联系电话
      },
    };
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
      userInfo: (state) => state.userInfo,
      enterpriseStatus: (state) => state.enterpriseStatus,
      applyResult: (state) => state.SystenManage.applyResult,
      applyEnterprise: (state) => state.SystenManage.applyEnterprise,
    }),
    ActionUrl() {
      return `${this.baseUrl}/file/imageUpload`;
    },
  },
  watch: {
    applyResult() {
      console.log(this.applyResult);
      if (this.applyResult.success) {
        this.$message({
          showClose: true,
          message: "申请提交成功",
          type: "success",
        });
        this.setEnterpriseStatus(1);
      } else {
        this.$message({
          showClose: true,
          message: this.applyResult.message,
          type: "error",
        });
      }
    },
    applyEnterprise() {
      const obj = this.applyEnterprise.data;
      if (!obj) {
        return;
      } else if (obj.userStatus === 2) {
        this.showModel = true;
        this.$nextTick(() => {
          this.$refs.modelRef.init(obj, "detail", true);
        });
        return;
      }

      let newObj = {};
      Object.keys(this.formObj).forEach((key) => {
        newObj[key] = obj[key];
      });
      newObj.id = obj.id;

      if (newObj.industryType) {
        newObj.industryType = JSON.parse(newObj.industryType); // 行业数组字符串变为对象
      }
      if (newObj.registeredAddress) {
        newObj.registeredAddress = JSON.parse(newObj.registeredAddress); // 省市区 数组字符串变为对象
      }

      this.formObj = newObj;

      if (obj.contactsIdPhoto) {
        const contactsIdPhoto = obj.contactsIdPhoto.split(",");
        this.contactFrontImgUrl = contactsIdPhoto[0];
        this.contactBackImgUrl = contactsIdPhoto[1];
      }

      if (obj.corporationIdPhoto) {
        const corporationIdPhoto = obj.corporationIdPhoto.split(",");
        this.corpFrontImgUrl = corporationIdPhoto[0];
        this.corpBackImgUrl = corporationIdPhoto[1];
      }
    },
  },
  methods: {
    //企业二级节点标识
    companyCode() {
      let value = this.formObj.companyCode;
      // console.log(value);
      let self = this;
      if (value == null || value == "") {
        self.thisStyle = "border: 1px solid red !important;border-radius: 5px;";
        // alert(111)
        this.$message({
          showClose: true,
          // message:"请输入企业二级节点标识",
          message: "请输入企业标识",
          type: "error",
        });
        //  let isError = document.getElementsByClassName('el-message')   // 适用于element
        // isError[0].querySelector('.el-input__inner').focus()  // 只定位到第一个必填项位置

        return;
      } else if (value != null) {
        self.thisStyle = "border: none !important;";
      }
    },

    companyName() {
      let value = this.formObj.companyName;
      let self = this;
      if (value == null || value == "") {
        self.thisStyle2 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请输入企业名称",
          type: "error",
        });
        return;
      } else if (value != null) {
        self.thisStyle2 = "border: none !important;";
      }
    },

    // companyType(value) {
    companyType() {
      let value = this.formObj.companyType;
      // console.log(value);
      let self = this;
      if (value == null || value == "") {
        self.thisStyle3 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请选择",
          type: "error",
        });

        // return
      } else if (value != null) {
        self.thisStyle3 = "border: none !important;";
      }
    },
    //社会信用代码
    socialCreditCode() {
      let value = this.formObj.socialCreditCode;
      let self = this;
      if (value == null || value == "") {
        self.thisStyle4 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "统一社会信用代码",
          type: "error",
        });
        return;
      } else if (value != null) {
        self.thisStyle4 = "border: none !important;";
      }
    },
    //企业详细地址
    companyAddress() {
      let value = this.formObj.companyAddress;
      let self = this;
      if (value == null || value == "") {
        self.thisStyle7 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请输入详细地址",
          type: "error",
        });
        return;
      } else if (value != null) {
        self.thisStyle7 = "border: none !important;";
      }
    },
    //企业联系电话
    contactsPhone() {
      let value = this.formObj.contactsPhone;
      let self = this;
      // var p1=/^(13[0-9]\d{8}|15[0-35-9]\d{8}|18[0-9]\{8}|14[57]\d{8})$/;
      var p1 = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
      if (value == null || value == "") {
        self.thisStyle8 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请输入企业联系电话",
          type: "error",
        });
        return;
      } else if (p1.test(value) == false) {
        alert("请填写正确电话号码!!");
        self.thisStyle8 =
          "border: 1px solid red !important;border-radius: 5px;";
        return false;
      } else if (value != null) {
        self.thisStyle8 = "border: none !important;";
      }
    },
    //法人姓名
    corporationName() {
      let value = this.formObj.corporationName;
      let self = this;
      if (value == null || value == "") {
        self.thisStyle10 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请输入法人姓名",
          type: "error",
        });
        return;
      } else if (value != null) {
        self.thisStyle10 = "border: none !important;";
      }
    },
    //法人身份证号
    corporationId() {
      let value = this.formObj.corporationId;
      let self = this;
      // var p1=/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      // var p1=/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/;
      // var p1=/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      // var p1=/^\d{6}(19|20)\d{2}([0][1-9]|11|12)([0,1,2][1-9]|[3][0,1])\d{3}([0-9]|X|x)$/;
      var p1 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

      // if(value==null||value==''){
      // 	 self.thisStyle11 = "border: 1px solid red !important;border-radius: 5px;"
      // 	this.$message({
      // 			showClose: true,
      // 			message:"请输入身份证号",
      // 			type: 'error'
      // 		});
      // 		return
      // }
      console.log(p1.test(value));
      // else
      if (p1.test(value) == false) {
        // alert('请填写正确的身份证号码格式');
        this.$message({
          showClose: true,
          message: "请填写正确的身份证号码格式",
          type: "error",
        });
        self.thisStyle11 =
          "border: 1px solid red !important;border-radius: 5px;";
        return false;
      }
      // else
      if (value != null) {
        self.thisStyle11 = "border: none !important;";
      }
    },

    ...mapActions({
      apply: "SystenManage/apply",
      getApplyEnterprise: "SystenManage/getApplyEnterprise",
    }),
    ...mapMutations({
      setEnterpriseStatus: "setEnterpriseStatus",
    }),

    industryTypeChange() {
      //机构行业
      // 行业选择值变化
      let value = this.formObj.industryType;
      // console.log(value);
      let self = this;
      if (value == null || value == "") {
        self.thisStyle5 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请选择行业",
          type: "error",
        });

        return;
      } else if (value != null) {
        self.thisStyle5 = "border: none !important;";
      }
    },
    //注册地址
    // registeredAddressChange(value) {
    registeredAddressChange() {
      let value = this.formObj.registeredAddress;
      // console.log('this.formObj.registeredAddress');
      // console.log(this.formObj.registeredAddress);
      // 省市区选择值变化
      // console.log(value);
      let self = this;
      if (value == null || value == "") {
        self.thisStyle6 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请选择注册地址",
          type: "error",
        });

        return;
      } else if (value != null) {
        self.thisStyle6 = "border: none !important;";
      }
    },
    //证件上联系人姓名
    contacts() {
      let value = this.formObj.contacts;
      let self = this;
      if (value == null || value == "") {
        self.thisStyle13 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请输入联系人姓名",
          type: "error",
        });
        return;
      } else if (value != null) {
        self.thisStyle13 = "border: none !important;";
      }
    },
    //联系人手机号
    companyTelephone() {
      let value = this.formObj.companyTelephone;
      let self = this;
      var p1 = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
      if (value == null || value == "") {
        self.thisStyle14 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请输入联系人手机号",
          type: "error",
        });
        return;
      } else if (p1.test(value) == false) {
        alert("请填写正确电话号码!!");
        self.thisStyle8 =
          "border: 1px solid red !important;border-radius: 5px;";
      } else if (value != null) {
        self.thisStyle14 = "border: none !important;";
      }
    },
    //联系人邮箱
    contactsEmail() {
      let value = this.formObj.contactsEmail;
      let self = this;
      var p1 = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value == null || value == "") {
        self.thisStyle15 =
          "border: 1px solid red !important;border-radius: 5px;";
        this.$message({
          showClose: true,
          message: "请输入联系人邮箱",
          type: "error",
        });
        return;
      } else if (p1.test(value) == false) {
        alert("请填写正确的邮箱");
        self.thisStyle15 =
          "border: 1px solid red !important;border-radius: 5px;";
        return false;
      } else if (value != null) {
        self.thisStyle15 = "border: none !important;";
      }
    },
    //联系人身份证
    contactsId() {
      let value = this.formObj.contactsId;
      let self = this;
      // var p1=/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/;
      // var p1=/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      // var p1=/^\d{6}(19|20)\d{2}([0][1-9]|11|12)([0,1,2][1-9]|[3][0,1])\d{3}([0-9]|X|x)$/;
      var p1 =
        /^\d{6}(19|20)\d{2}([0][1-9]|11|12)([0,1,2][1-9]|[3][0,1])\d{3}([0-9]|X|x)$/;

      // if(value==null||value==''){
      // 	 self.thisStyle16 = "border: 1px solid red !important;border-radius: 5px;"
      // 	this.$message({
      // 			showClose: true,
      // 			message:"请输入联系人身份证号",
      // 			type: 'error'
      // 		});
      // 		return
      // }
      // console.log(p1.test(value));
      // else
      if (p1.test(value) == false) {
        // alert('请填写正确的联系人身份证号');
        this.$message({
          showClose: true,
          message: "请填写正确的联系人身份证号",
          type: "error",
        });
        self.thisStyle16 =
          "border: 1px solid red !important;border-radius: 5px;";
        return false;
      } else if (value != null) {
        self.thisStyle16 = "border: none !important;";
      }
    },
    //

    navigatorTo(path) {
      this.$router.push(path);
    },
    submit() {
      ///////////////////////////////////////////////

      this.companyCode();
      this.companyName();
      this.companyType();
      this.socialCreditCode();
      this.industryTypeChange();
      this.registeredAddressChange();
      this.companyAddress();
      // this.contactsPhone();
      this.corporationName();
      // this.corporationId(); //法人身份证号

      this.contacts();
      // this.companyTelephone();//联系人手机号
      // this.contactsEmail();
      // this.contactsId();//联系人身份证号
      // this.contactsPhone()==false||
      if (
        this.corporationId() == false ||
        this.companyTelephone() == false ||
        this.contactsEmail() == false ||
        this.contactsId() == false
      ) {
        return false;
      }

      // console.log(this.contactFrontImgUrl);

      if (this.corpFrontImgUrl.length == 0 || this.corpBackImgUrl.length == 0) {
        alert("请上传法人证件照片");
        return false;
      }
      // if(this.contactFrontImgUrl.length==0||this.contactBackImgUrl.length==0){
      // 	alert('请上传证件照片')
      // 	return false
      // }

      let params = {
        ...this.formObj,
        corporationIdPhoto: this.corpFrontImgUrl + "," + this.corpBackImgUrl, //法人证件照
        contactsIdPhoto: this.contactFrontImgUrl + "," + this.contactBackImgUrl, //证件照
      };
      params.industryType = JSON.stringify(params.industryType); // 将选择的行业数组变为字符串
      params.registeredAddress = JSON.stringify(params.registeredAddress); // 将省市区数组变为字符串

      // 提交企业申请
      this.apply(params);
    },
    contactFrontSuccess(res, file) {
      //联系人正面 证件照
      if (res.success) {
        this.contactFrontImgUrl = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    contactFrontUpload(file) {
      //联系人正面 证件照
      // console.log(file.type);
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传证件图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传证件大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    contactBackSuccess(res, file) {
      //联系人反面 证件照
      if (res.success) {
        this.contactBackImgUrl = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    contactBackUpload(file) {
      //联系人反面 证件照
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传证件图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传证件大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    corpFrontSuccess(res, file) {
      //法人正面 证件照
      if (res.success) {
        this.corpFrontImgUrl = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    corpFrontUpload(file) {
      //法人正面 证件照
      // console.log(file.type);
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传证件图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传证件大小不能超过 5MB!");
      }
      if (file.length < 1) {
        alert(111);
        return false;
      }
      // console.log(file.length);

      return isJPG && isLt2M;
    },
    corpBackSuccess(res, file) {
      //法人反面 证件照
      if (res.success) {
        this.corpBackImgUrl = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    corpBackUpload(file) {
      //法人反面 证件照
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传证件图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传证件大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    companyIDPhotoSuccess(res, file) {
      if (res.success) {
        //营业执照 照片
        this.formObj.licenseUrl = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    companyIDPhotoUpload(file) {
      //营业执照 照片
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传证件图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传证件大小不能超过 5MB!");
      }

      return isJPG && isLt2M;
    },
    getLoaction() {
      //通过区域编码获取中文地址
      const data = this.applyEnterprise.data;
      let str = "";
      if (data && data.registeredAddress) {
        const arr = JSON.parse(data.registeredAddress);
        arr.map((item) => {
          str += CodeToText[item];
          // console.log(CodeToText[item]);
        });
      }
      str += data.companyAddress;
      return str;
    },
    getLocalTime(date) {
      //转换时间格式
      const time = new Date(date);
      const y = time.getFullYear();
      const m = time.getMonth() + 1;
      const d = time.getDate();
      return y + "年" + m + "月" + d + "日";
    },
    updateInfo() {
      this.setEnterpriseStatus(0);
    },
  },
  mounted() {
    this.getApplyEnterprise(); //  查询企业信息
  },
};
</script>
<style lang="scss">
.el-step__title {
  max-width: 100% !important;
  padding-right: 40px !important;
}
.el-step {
  padding-right: 40px !important;
}
</style>
<style scoped lang="scss">
.header_step_box ::v-deep .el-step {
  margin-top: 30px;
  flex-basis: auto !important;
  padding-right: 14px;
}
.is-flex ::v-deep .el-step__title {
  padding-right: 0px !important;
}
.header_step_box ::v-deep .el-step.is-flex {
  padding-right: 0px;
}
.enterprise_register_box {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  height: auto;
  position: relative;
  .tips_box {
    width: auto;
    height: calc(100% - 20px);
    color: #f82b2b;
    font-size: 12px;
    text-align: left;
    position: absolute;
    bottom: 5px;
    left: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .header_step_box {
    width: 100%;
    height: auto;
    .el-steps--simple {
      padding: 0 0px 30px 0px;
      border-radius: 0;
      background: #fff;
      display: flex;
      flex-direction: row;
      border-bottom: 2px solid #f1f2f3;
    }
    .el-step__title {
      padding-right: 8px;
    }
    .is-flex {
      max-width: 50% !important;
    }
    .el-step__head.is-process {
      color: #0067c4 !important;
      border-color: #0067c4 !important;
    }
  }
  .noLogin_box {
    width: 100%;
    height: calc(100vh - 500px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    .register_text {
      color: #67c23a;
      cursor: pointer;
    }
    .login_text {
      cursor: pointer;
      color: #0067c4;
    }
  }
  .content_container {
    width: 100%;
    height: auto;
  }
  .step3_box {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    .edit_text {
      color: #0067c4;
      cursor: pointer;
    }
  }
  .content_box {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title_name {
      font-size: 15px;
      font-weight: bold;
    }
    .cont_item {
      width: 100%;
      min-height: 40px;
      display: flex;
      align-items: center;
      color: #888;
      margin-top: 20px;
      box-sizing: border-box;
      padding-left: 20px;
      .key {
        width: 150px;
        height: 100%;
        display: flex;
        align-items: center;
        .relative_key {
          position: relative;
          .key_tips {
            color: #f82b2b;
            position: absolute;
            right: -8px;
            top: -2px;
          }
        }
      }
      .val {
        width: calc(100% - 150px);
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-items: flex-start;
        position: relative;
        .select_box {
          margin-right: 20px;
          width: 180px;
        }
        .select_input_box {
          width: 380px;
        }
        .input_box {
          width: 580px;
        }
        .upload_box {
          width: 180px;
          height: 110px;
          .img_box {
            width: 100%;
            height: 100%;
            position: relative;
            .add_img {
              width: 46px;
              height: 46px;
              position: absolute;
              top: calc(50% - 32px);
              left: calc(50% - 23px);
            }
          }
          .img_upload {
            width: 180px;
            height: 110px;
          }
          .companyID_img_box {
            width: 180px;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed #d9d9d9;
            font-size: 28px;
          }
        }
      }
    }
  }
  .bootom_submit_box {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
  }
  .detail_box {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 20px;
  }
}
</style>
<style>
.input_biaoshi input::-webkit-input-placeholder {
  color: #f8aead;
}
</style>
