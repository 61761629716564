<template>
	<div class="base_box">
		<div class="content_box">
			<div class="title_name">基础字段信息</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">标识编码</span>
				</div>
				<div class="val">
					<el-input size="small" class="input_box" :disabled="true"  v-model="dataObj['handle1']" clearable></el-input>
				</div>
			</div>
			
			<div class="cont_item" v-for="(item, index) in baseTemplate" :key="index">
				<div class="key">
					<span class="relative_key">
						{{item.name}}
						<!-- <span class="key_tips">*</span> -->
					</span>
				</div>
				<div class="val">
					<el-input size="small" class="input_box" :disabled="true" v-model="dataObj[item.field]" clearable></el-input>
				</div>
			</div>
		</div>
		<div class="content_box">
			<div class="title_name">自定义字段信息</div>
			<div class="cont_item" v-for="(item, index) in selectTemplateArr" :key="index">
				<div class="key">
					<span class="relative_key">
						{{item.name}}
						<!-- <span class="key_tips">*</span> -->
					</span>
				</div>
				<div class="val">
					<el-input size="small" class="input_box" :disabled="true" v-model="contentObj[item.field]" clearable></el-input>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	export default {
		data(){
			return {
				dataObj:{},
				fromFun:'detail',
				contentObj:{}
			}
		},
		computed:{
			...mapState({
				baseTemplate: state => state.TempManage.baseTemplate,
				selectTemplateArr: state => state.TempManage.selectTemplateArr,
			}),
		}, 
		methods:{
			...mapActions({
				getBaseTemplate:'TempManage/getBaseTemplate',
				selectTemplate:'TempManage/selectTemplate',
			}),
			init(obj, fromFun){
				this.fromFun = fromFun;
				for(let key in obj){
					if(obj[key] === true){
						obj[key] = '是';
					}else if(obj[key] === false){
						obj[key] = '否';
					}
				}
				this.dataObj = obj;
				let params = {
					contentType: obj.contentType,
				}
				this.contentObj = JSON.parse(this.dataObj.content)
				this.selectTemplate(params);
			}
		},
		mounted() {
			if(this.baseTemplate.length < 1){
				this.getBaseTemplate();
				console.log(this.selectTemplateArr);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.base_box{
		width: 100%;
		height: calc(90vh - 80px);
		box-sizing: border-box;
		padding-bottom: 30px;
		overflow: auto;
		.content_box {
			width: 100%;
			height: auto;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.title_name {
				font-size: 15px;
				font-weight: bold;
			}
			.cont_item {
				width: 100%;
				min-height: 40px;
				display: flex;
				align-items: center;
				color: #888;
				margin-top: 5px;
				box-sizing: border-box;
				padding-left: 20px;
				.key {
					width: 120px;
					height: 100%;
					display: flex;
					align-items: center;
					.relative_key {
						position: relative;
						.key_tips {
							color: #f82b2b;
							position: absolute;
							right: -8px;
							top: -2px;
						}
					}
				}
				.val {
					width: calc(100% - 120px);
					height: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					align-items: flex-start;
					position: relative;
					.select_box {
						margin-right: 20px;
						width: 180px;
					}
					.select_input_box {
						width: 380px;
					}
					.input_box {
						width: 580px;
					}
					.upload_box {
						width: 180px;
						height: 110px;
						.img_box {
							width: 100%;
							height: 100%;
							position: relative;
							.add_img {
								width: 46px;
								height: 46px;
								position: absolute;
								top: calc(50% - 32px);
								left: calc(50% - 23px);
							}
						}
						.img_upload {
							width: 180px;
							height: 110px;
							cursor: pointer;
						}
						.companyID_img_box {
							width: 180px;
							height: 110px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px dashed #d9d9d9;
							font-size: 28px;
						}
					}
				}
			}
		}
	}
</style>
