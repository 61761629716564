<template>
	<div class="equipmentData_box">
		<div class="header_search_box">
			<el-input class="input_box" placeholder="请输入完整的物料编码、标识编码" v-model="searchParam" clearable @keyup.enter.native="getDataList"></el-input>
			<el-button type="primary" plain @click="getDataList">搜索</el-button>
			<el-upload
			  class="upload-demo"
				ref="uploadFile"
			  :action="actionUrl"
			  :multiple="false"
			  :limit="1"
				:show-file-list="false"
				:headers="importHeaders"
				:on-success="importSuccess"
				:on-error="importError">
			  <el-button type="primary" plain class="import_box">导入Excel</el-button>
			</el-upload>
			<el-button type="primary" plain @click="onlcickQRcode">打印标识编码</el-button>
		</div>
		<div class="tabel_box">
			<div class="table_cont">
				<el-table v-loading="loading" :data="tableData" style="width: 100%" :height="tableMaxHeight" border size="medium" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="50"></el-table-column>
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="handle1" label="标识编码" width="220"  ></el-table-column>
					<el-table-column prop="materialId" label="物料编码" ></el-table-column>
					<el-table-column prop="companyCode" label="企业组织代码" ></el-table-column>
					<el-table-column prop="categoryCode" label="产品层次代码"></el-table-column>
					<el-table-column prop="unit" label="基本单位" ></el-table-column>
					<el-table-column prop="longitude" label="经度" ></el-table-column>
					<el-table-column prop="latitude" label="纬度" ></el-table-column>
					<el-table-column prop="latitude" label="同步二级节点" width="110">
						<template slot-scope="scope">
							<span :class="[scope.row.uploaded ? 'uploaded':'uploaded2']">{{scope.row.uploaded?'已同步':'未同步'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="companyQrUrl" label="公司网址" width="150"></el-table-column>

					<el-table-column fixed="right" prop="date" label="操作" width="120">
						<template slot-scope="scope">
							<el-button size="mini" @click="showDetail(scope.$index, scope.row, 'detail')">详情</el-button>
							<!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page_box">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
		</div>
		<!-- 二维码生成 -->
		<el-dialog
		  title="二维码生成"
		  :visible.sync="showQRcodeModel"
		  top="5vh"
		  width="1200px">
		  <div class="dialog_content_box">
		    <QRcode v-if="showQRcodeModel" ref="QRcodeRef"></QRcode>
		  </div>
		</el-dialog>
		<!-- 详情/编辑 -->
		<el-dialog
		  :title="DetailEditTitle"
		  :visible.sync="showDetailEditModel"
		  top="5vh"
		  width="1000px">
		  <div class="dialog_content_box">
		    <DetailEdit v-if="showDetailEditModel" ref="DetailEditRef"></DetailEdit>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import QRcode from './QRcode.vue'
import DetailEdit from './equipmentData-detail-edit.vue'
export default {
	components:{
		QRcode,
		DetailEdit,
	},
	data() {
		return {
			searchParam: '',
			tableData: [],
			total:0,
			multipleSelection: [],
			pageIndex:1,
			pageSize:20,
			showQRcodeModel:false,	//二维码
			DetailEditTitle:'物料详情',
			showDetailEditModel:false,	
		};
	},
	watch:{
		dataList(){
			if(this.dataList.success){
				this.tableData = this.dataList.data.list;
				// console.log(111);
				// console.log(this.dataList.data.list);
				this.total = this.dataList.data.total;
			}else{
				this.$message({
					showClose: true,
					message:this.dataList.message,
					type: 'error'
				});
			

			}
		},
	},
	computed: {
		...mapState({
			dataList: state => state.EquipmentData.list,
			// loading: state => state.EquipmentData.loading,
			baseUrl: state => state.baseUrl,
			userToken: state => state.userToken,
		}),
		loading:{
			get(){
				return this.$store.state.EquipmentData.loading
			},
			set(){
				this.$store.state.EquipmentData.loading = true
			}
		},
		tableMaxHeight() {
			let h = document.documentElement.clientHeight;
			return h - 220;
		},
		actionUrl(){
			return `${this.baseUrl}/identity/uploadMaterialBaseExcel`
		},
		importHeaders(){
			return {
				"token":this.userToken,
			}
		},
	},
	methods: {
		...mapActions({
			getList:'EquipmentData/getList'
		}),
		importSuccess(res){
			this.$refs.uploadFile.clearFiles();
			// console.log(res)
			if(res.success){
				this.$message({
					showClose: true,
					message:'上传成功',
					type: 'success'
				});
				this.getDataList();
			}else{
				// this.$message({
				// 	showClose: true,
				// 	message:res.message,
				// 	type: 'error'
				// });
				var desc=res.message;
				// this.$confirm(desc, '提示', {
				// confirmButtonText: '确定',
				// cancelButtonText: '取消',
				// type: 'warning'
				// }).then(() => {
				 
				// }).catch(() => {
				 
				// });

				this.$alert(desc, '提示', {
				confirmButtonText: '确定',
				type: 'warning',
				// callback: action => {
				// this.$message({
				// 	type: 'warning',
       		    //    message: `action: ${ action }`
        	  	//   });
       		  	//  }
     		   });
			}
		},
		importError(res){
			this.$refs.uploadFile.clearFiles();
			// console.log('上传失败');
			// console.log(res)
			this.$message({
				showClose: true,
				message:'发送错误，上传失败',
				type: 'error'
			});
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
			// console.log(val);
		},
		showDetail(index, obj, fromFun) {
			// console.log(obj);
			if(fromFun == 'detail'){
				this.DetailEditTitle = '物料详情'
			}else if(fromFun == 'edit'){
				this.DetailEditTitle = '编辑物料'
			}
			this.showDetailEditModel = true;
			this.$nextTick(()=>{
				this.$refs.DetailEditRef.init(obj, fromFun);
			})
		},
		handleDelete(index, obj) {
			//删除
			// console.log(obj);
		},
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getDataList();
		},
		handleCurrentChange(val) {
			// console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getDataList();
		},
		getDataList(){
			this.loading = true
			let params = {
				searchParam:this.searchParam,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
			}
			this.getList(params);
		},
		onlcickQRcode(){	//生成二维码
			if(this.multipleSelection.length < 1){
				this.$message({
					showClose: true,
					message:'请选择至少一行数据',
					type: 'warning'
				});
				return
			}
			this.showQRcodeModel = true;
			this.$nextTick(() => {
			  this.$refs.QRcodeRef.init(this.multipleSelection);
			})
		}
	},
	mounted() {
		this.getDataList();
	}
};
</script>

<style lang="scss" scoped>
.equipmentData_box {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 10px 20px;
	.header_search_box {
		width: 100%;
		height: 40px;
		display: flex;
		flex-direction: row;
		.import_box{
			margin: 0 10px;
		}
		.input_box {
			width: 300px;
			margin-right: 10px;
		}
	}
	.tabel_box {
		width: 100%;
		height: calc(100% - 40px);
		box-sizing: border-box;
		padding-top: 10px;
		.table_cont {
			width: 100%;
			height: calc(100% - 40px);
		}
		.page_box {
			width: 100%;
			height: 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
}
.uploaded{
	// background:green;
	color: green;
}
.uploaded2{
	// background:red;
	color: red;
}
</style>
