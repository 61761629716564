<template>
	<div class="userManage_box">
		<div class="header_search_box">
			<el-input class="input_box" placeholder="企业名称/用户名/手机号关键字" v-model="searchParam" clearable @keyup.enter.native="getDataList"></el-input>
			<el-button type="primary" @click="getDataList" plain>搜索</el-button>
			<el-button type="primary" plain @click="handleExport">导出Excel</el-button>
		</div>
		<div class="tabel_box">
			<div class="table_cont">
				<el-table :data="tableData" style="width: 100%" :height="tableMaxHeight" border size="medium" @selection-change="handleSelectionChange"  v-loading="loading" element-loading-text="拼命加载中">
					<el-table-column type="selection" width="50"></el-table-column>
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="username" label="用户名" width="120"></el-table-column>
					<el-table-column prop="accountTelephone" label="手机号" width="120"></el-table-column>
					<el-table-column prop="companyName" label="企业名称" width="180"></el-table-column>
					<el-table-column prop="companyCode" label="企业标识" width="80"></el-table-column>
					<el-table-column prop="companyType" label="企业类型" width="120"></el-table-column>
					<el-table-column prop="industryType" label="所属行业" :show-overflow-tooltip='true'>
						<template slot-scope="scope">
							<span>{{getIndustryTypeText(scope.row.industryType)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="userStatus" label="入网申请" width="110">
						<template slot-scope="scope">
							<span>{{getUserStatusText(scope.row.userStatus)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="applicationTime" label="申请时间" width="156"></el-table-column>
					<el-table-column fixed="right" prop="date" label="当前状态" >
						<template slot-scope="scope">
							<!-- <el-button  size="mini" :type="scope.row.accountState==0? 'primary':'danger'" @click="statuBtn(scope.row)">{{scope.row.accountState==0?'正常使用':'禁用'}}</el-button> -->
						<span :class="[scope.row.accountState==0 ? 'accountState':'accountState2']">{{scope.row.accountState==0?'正常使用':'已禁用'}}</span>
						</template>
					</el-table-column>

					<el-table-column fixed="right" prop="date" label="操作" width="380">
						<template slot-scope="scope">
							<el-button  size="mini" :disabled="scope.row.uploaded" :type="scope.row.uploaded?'info':'primary'" @click="changeLoader(scope.row)">{{scope.row.uploaded?'已同步':'同步'}}</el-button>
							<el-button size="mini" @click="showDetailOrExamine(scope.$index, scope.row, 'detail')">详情</el-button>
							<!-- <el-button size="mini" type="warning" @click="handleDisableEnable(scope.$index, scope.row)">停用</el-button> -->
							<!-- <el-button size="mini" v-if="scope.row.userStatus !== 2" :disabled="scope.row.userStatus !== 1" :type="scope.row.userStatus!==1?'info':'primary'" @click="showDetailOrExamine(scope.$index, scope.row, 'examine')">审核</el-button> -->
							<el-button size="mini" 
							v-if="scope.row.userStatus !== 3" :disabled="scope.row.userStatus !== 1" :type="scope.row.userStatus!==1?'info':'primary'" 
							@click="showDetailOrExamine(scope.$index, scope.row, 'examine')">
							 {{scope.row.userStatus == 1?'待审核':scope.row.userStatus == 2 ? '已通过':'未申请'}}
							</el-button>
							<el-button  size="mini" :type="scope.row.accountState==0? 'warning':'success'" @click="statuBtn(scope.row)">{{scope.row.accountState==0?'禁用':'启用'}}</el-button>
							<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
							
						</template>
					</el-table-column>

					
				</el-table>
			</div>
			<div class="page_box">
				<el-pagination
				      @size-change="handleSizeChange"
				      @current-change="handleCurrentChange"
				      :current-page="pageIndex"
				      :page-sizes="[10, 20, 50, 100]"
				      :page-size="pageSize"
				      layout="total, sizes, prev, pager, next, jumper"
				      :total="total">
				    </el-pagination>
			</div>
		</div>
		<!-- 审核 / 详情 -->
		<el-dialog
		  :title="title"
		  :visible.sync="showModel"
		  top="5vh"
		  width="1000px">
		  <div class="dialog_content_box">
		    <ModelPage v-if="showModel" ref="modelRef" @refreshDataList="refreshDataList"></ModelPage>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CodeToText } from 'element-china-category-data';
import ModelPage from './user-detail-or-examine.vue'
export default {
	components:{
		ModelPage,
	},
	data() {
		return {
			// loading:false,
			// StateValue:'true',//是否禁用 true 不禁用 
			stat111:1, //1禁用
			// btnColor:{
			// 	primary:primary,
			// 	danger:danger
			// },
			searchParam: '',
			tableData: [],
			total:0,
			multipleSelection: [],
			pageIndex:1,
			pageSize:20,
			title:'详情',
			showModel:false,
		};
	},
	watch:{
		dataList(){
			if(this.dataList.success){
				this.tableData = this.dataList.data.list;
				this.total = this.dataList.data.total;
			}else{
				this.$message({
					showClose: true,
					message:this.dataList.message,
					type: 'error'
				});
			}
		},
		deleteCompanyResult(){
			if(this.deleteCompanyResult.success){
				this.$message({
					showClose: true,
					message:'删除成功',
					type: 'success'
				});
				this.getDataList();
			}else{
				this.$message({
					showClose: true,
					message:this.deleteCompanyResult.message,
					type: 'error'
				});
			}
		},
		accountState(){
			if(this.accountState.success){
				this.$message({
					showClose: true,
					// message:'成功',
					message:this.accountState.message,
					type: 'success',
				});
				this.loading=false;
				this.getDataList();
			}else{
				this.$message({
					showClose: true,
					message:this.accountState.message,
					type: 'error'
				});
			}
		},
		
	},
	computed: {
		...mapState({
			dataList: state => state.SystenManage.userList,
			deleteCompanyResult: state => state.SystenManage.deleteCompanyResult,
			accountState: state => state.SystenManage.accountState,
			loading:state=>state.SystenManage.loading
		}),
		tableMaxHeight() {
			let h = document.documentElement.clientHeight;
			return h - 220;
		}
	},
	methods: {
		...mapActions({
			getList:'SystenManage/getList',
			deleteCompany:'SystenManage/deleteCompany',
			exportCompanyList:'SystenManage/exportCompanyList',
			getAccountState:'SystenManage/getAccountState',//禁用
			getChangeLoader:'SystenManage/getChangeLoader'
		}),
		statuBtn(row){
				this.loading=true
			// console.log(row.accountState);
			
			if(row.accountState==0){
				row.accountState=1;
			}else{
				row.accountState=0
			}
			let params = {
				// accountState:this.StateValue? '0':'1', //0正常使用 1禁用
				accountState:row.accountState, //0正常使用 1禁用
				username:row.username
			}
			this.getAccountState(params);
		},
		handleExport(){
			let params = {
				searchParam:this.searchParam,
			}
			this.exportCompanyList(params);
		},
		getIndustryTypeText(data){	//行业类型
			let str = '';
			if (data && data.indexOf('[') > -1) {
				const arr = JSON.parse(data);
				arr.map((item, index) => {
					if(index === 0){
						str += CodeToText[item];
					}else {
						str += '/'+CodeToText[item];
					}
				});
			}else {
				str = data;
			}
			return str;
		},
		getUserStatusText(status){
			let str = '暂未申请入网'
			if(status === 1){
				str = '待审核'
			}else if(status === 2){
				str = '审核通过'
			}
			return str
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
			// console.log(val);
		},
		showDetailOrExamine(index, obj, fromFun) {
			//详情
			if(fromFun == 'detail'){
			  this.title = '账户详情';
			}else{
			  this.title = '企业入网审核';
			}
			this.showModel = true;
			this.$nextTick(() => {
			  this.$refs.modelRef.init(obj,fromFun);
			})
		},
		refreshDataList(){
			this.showModel = false;
			this.getDataList();
		},
		handleDelete(index, obj) {
			//删除
			// console.log(obj);
			this.$confirm(`确定进行删除操作?`, '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
			  let params = {
			  	id:obj.id
			  }
			  this.deleteCompany(params);
			}).catch(() => {
			      
			});
		},
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getDataList();
		},
		handleCurrentChange(val) {
			// console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getDataList();
		},
		getDataList(){
			let params = {
				searchParam:this.searchParam,
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
			}
			this.getList(params);
		},
		changeLoader(val){
			const that = this
			let obj = {
				'id':val.id
			}
			this.getChangeLoader(obj).then(()=>{
				that.getDataList();
				this.$message({
					message: '同步成功',
					type: 'success'
				});
			}).catch((res)=>{
				this.$message.error(res);
			})
		}
	},
	mounted() {
		this.getDataList();
	}
};
</script>

<style lang="scss" scoped>
.userManage_box {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 10px 20px;
	.header_search_box {
		width: 100%;
		height: 40px;
		display: flex;
		flex-direction: row;
		.input_box {
			width: 300px;
			margin-right: 10px;
		}
	}
	.tabel_box {
		width: 100%;
		height: calc(100% - 40px);
		box-sizing: border-box;
		padding-top: 10px;
		.table_cont {
			width: 100%;
			height: calc(100% - 40px);
		}
		.page_box {
			width: 100%;
			height: 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
}

.accountState{
	// background:green;
	color: green;
}
.accountState2{
	// background:red;
	color: red;
}
</style>
