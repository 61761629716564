<template>
	<div class="text_base_box">
		<div class="tips_box">提示：选择个数不能超过 {{maxNum}} 个</div>
		<div class="text_cont">
			<div class="content_box">
				<el-checkbox-group v-model="checkBaseList" :max="maxNum">
					<div class="title_name">基础字段信息</div>
					<el-checkbox v-for="(item, index) in baseTemplate" :key="index" :label="(index + 1)+'、'+item.name"></el-checkbox>
					<div class="title_name marginTop">自定义字段信息</div>
					<el-checkbox v-for="(item, index) in selectTemplateArr" :key="index + baseTemplate.length + 1" :label="(index + baseTemplate.length +1)+'、'+item.name"></el-checkbox>
				</el-checkbox-group>
			</div>
		</div>
		<div class="bottom_btn_box">
			<el-button @click="closeTheModel">取消</el-button>
			<el-button type="primary" @click="submit">确定</el-button>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex'
	export default {
		data(){
			return {
				defaultSelectArr:[],	//传递过来的初始选择项
				checkBaseList:[],
				maxNum:6,
			}
		},
		watch:{
			selectTemplateArr(){
				if(this.baseTemplate.length > 0){
					this.setCheckBaseList();
				}
			},
		},
		computed:{
			...mapState({
				baseTemplate: state => state.TempManage.baseTemplate,
				selectTemplateArr: state => state.TempManage.selectTemplateArr,
			}),
		}, 
		methods:{
			...mapActions({
				getBaseTemplate:'TempManage/getBaseTemplate',
				selectTemplate:'TempManage/selectTemplate',
			}),
			setCheckBaseList(){
				let tempArr = this.baseTemplate.concat(this.selectTemplateArr);
				let arr = [];
				this.defaultSelectArr.map((item ,index) => {
					for(let i=0; i<tempArr.length; i++) { 
						if(tempArr[i].field === item.field && tempArr[i].index === item.index) { 
							arr.push((i + 1)+'、'+item.name)
							break; 
						} 
					}
				})
				this.checkBaseList = arr;
			},
			init(obj, selectArr){
				this.defaultSelectArr = selectArr;
				let params = {
					contentType: obj.contentType,
				}
				this.selectTemplate(params);
			},
			closeTheModel(){
				this.$emit('closeModel',true);
			},
			submit(){
				let baseTemplate = this.baseTemplate;
				let selectTemplateArr = this.selectTemplateArr;
				let len = baseTemplate.length;
				let indexArr = [];
				let dataArr = [];
				this.checkBaseList.map(item =>{
					indexArr.push(Number(item.split('、')[0]));
				})
				indexArr.map(item => {
					if(item > len){	//自定义模板里的
						dataArr.push(selectTemplateArr[item - len - 1])
					}else{	//基础模板里的
						dataArr.push(baseTemplate[item - 1])
					}
				})
				this.$emit('changeSelectTextArr',dataArr)
			},
		},
		mounted() {
			if(this.baseTemplate.length < 1){
				this.getBaseTemplate();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.el-checkbox{
		margin-top: 20px;
	}
	.text_base_box{
		width: 100%;
		height: calc(90vh - 80px);
		box-sizing: border-box;
		padding: 0 10px;
		.tips_box{
			width: 100%;
			height: 40px;
			display: flex;
			align-items: center;
			color: red;
		}
		.text_cont{
			width: 100%;
			height: calc(100% - 100px);
			overflow: auto;
			.content_box {
				width: 100%;
				height: auto;
				margin-bottom: 30px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				.title_name{
					width: 100%;
					height: 30px;
					font-size: $base-font-size;
					display: flex;
					align-items: flex-end;
				}
				.marginTop{
					margin-top: 20px;
				}
			}
		}
		.bottom_btn_box{
			width: 100%;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>
