<template>
  <div class="add_standard">
    <el-dialog :title="title" :visible.sync="dialogFormVisible" :before-close="handleClose" :close-on-click-modal="false">
      <el-form :model="form" status-icon :rules="rules"  ref="form" label-width="100px" class="demo-ruleForm"  v-if="mold!='edit' && mold!='editCustom'">
        <el-form-item label="标准名称" v-if="mold=='edit' || mold=='editCustom'">
            <span>{{formDataEdit.standardName}}</span>
        </el-form-item>
        <el-form-item label="标准名称" prop="standardName" v-else>
            <el-input v-model="form.standardName" placeholder="输入标准名称"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="companyName" v-if="mold=='Custom'">
            <el-select v-model="companyName" placeholder="请选择企业名称">
                <el-option
                v-for="item in CompanyList"
                :key="item.value"
                :label="item.companyName"
                :value="item.companyId">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="数量" prop="quantity">
            <el-input type="number" v-model="form.quantity" placeholder="输入数量"></el-input>
        </el-form-item>
        <el-form-item label="金额(元/年)" prop="amount">
            <el-input type="number" v-model="form.amount" placeholder="输入金额"></el-input>
        </el-form-item>
        <el-form-item label="费用类型" prop="pass">
            <el-radio-group v-model="form.costTypeId">
                <el-radio  v-for="(item,index) in CostList" :key="index" :label="item.id">{{item.name}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="收费标准说明" prop="remarks">
            <el-input
            type="textarea"
            :rows="2"
             placeholder="输入说明"
            v-model="form.remarks">
            </el-input>
        </el-form-item>
      </el-form>

    <!-- 编辑部分表单 -->
    <el-form :model="form" status-icon  ref="form" label-width="100px" class="demo-ruleForm" v-if="mold=='edit' || mold=='editCustom'">
        <el-form-item label="标准名称">
            <span>{{formDataEdit.standardName}}</span>
        </el-form-item>
        <el-form-item label="数量" >
            <el-input type="number" v-model="formDataEdit.quantity" placeholder="输入数量"></el-input>
        </el-form-item>
        <el-form-item label="金额(元/年)">
            <el-input type="number" v-model="formDataEdit.amount" placeholder="输入金额"></el-input>
        </el-form-item>
        <el-form-item label="费用类型" prop="pass">
            <el-radio-group v-model="formDataEdit.costTypeId">
                <el-radio  v-for="(item,index) in CostList" :key="index" :label="item.id" :disabled="mold=='edit' || mold=='editCustom'">{{item.name}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="收费标准说明" prop="remarks">
            <el-input
            type="textarea"
            :rows="2"
             placeholder="输入说明"
            v-model="formDataEdit.remarks">
            </el-input>
        </el-form-item>
      </el-form>


      <div slot="footer" class="dialog-footer">
        <el-button class="primary" type="primary" @click="submitForm('form')">保 存</el-button>
      </div>

    <!-- 确认保存 -->
      <el-dialog width="30%" title="编辑确认" :visible.sync="innerVisible" append-to-body>
           <div class="charging">确认修改标准吗，修改后，未交费的企业前缀报表将改用此标准收费!</div> 

           <div  slot="footer" class="dialog-footer">
                <el-button @click="innerVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
          
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { mapState , mapMutations, mapActions } from 'vuex'
import { POST } from '@/axios/axiosHttp.js'
import Url from '@/axios/url.js'
export default {
    data(){
        return {
            formLabelWidth:"500px",
            innerVisible:false,
            companyName:'',
            form: {
                id:'',  // 需要修改的收费标准id 
				standardName:'',  //标准名
				companyId:'',  // 企业id，
                companyName:'',  //企业名称
				quantity:null,  //数量
				amount:null,  //金额（元/年）
				costTypeId:1, //费用类型id 
				remarks:'',  // 收费标准说明 
			},
            rules:{
                standardName:[
                    { required: true, message: '请输入标准名称', trigger: 'blur' },
                ],
                // companyName:[
                //     { required: true, message: '请填入企业名称', trigger: 'blur' },
                // ],
                quantity:[
                    { required: true, message: '数量不能为空', trigger: 'blur' },
                ],
                amount:[
                    { required: true, message: '金额不能为空', trigger: 'blur' },
                ]
            }
        }

    },
    computed:{
        ...mapState({
            dialogFormVisible:state=>state.AddStandard.dialogFormVisible,
            mold:state=>state.AddStandard.mold,
            formDataEdit:state=>state.AddStandard.formDataEdit, //编辑时表单中信息
            userInfo:(state) => state.userInfo,  //用户信息
            CompanyList:(state) => state.AddStandard.CompanyList, // 公司名列表
            CostList:(state) => state.AddStandard.CostList,  //费用类型
            tableUp:(state)=>state.AddStandard.tableUp,
            title:(state)=>state.AddStandard.title,
        })
    },

    methods:{
        ...mapMutations('AddStandard',['changeShow','changeType','changeState']),
        ...mapActions('AddStandard',['getCostList','addList','getList','getListCustom','updateList']),
        // 关闭表单文本框
        handleClose(){
            // 调用vuex中方法进行修改
            this.changeShow(false)
            this.form= {
                id:'',
				standardName:'',  // 需要修改的收费标准id 
				companyId:'',  // 企业id，
				quantity:null,  //数量
				amount:null,  //金额（元/年）
				costTypeId:'', //费用类型id 
				remarks:'',  // 收费标准说明 
			}
            this.$refs['form'].resetFields()  //关闭表单时将所有输入框提示清除
        },
        // 表单保存按钮点击事件
        submitForm(formName){
            // 输入框规则提示
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.innerVisible = true
                } else {
                    return false;
                }
            });
        },


        tableChange(num){
                const obj = {
                    standardName: "",
                    standardType: num,
                    pageNum: 1,
                    pageSize: 20
                };
                if (num==0) {
                    this.getList(obj)
                }else{
                    this.getListCustom(obj)
                }
                this.changeState(false)
        },

        // 确认提交修改
        submit(){
            if (this.mold == 'edit') { //标准页数据修改事件
                const obj = {
                	id:this.formDataEdit.id,  // 需要修改的收费标准id 
                	quantity:this.formDataEdit.quantity,  //数量
                	amount:this.formDataEdit.amount,  //金额（元/年）
                	costTypeId:this.formDataEdit.costTypeId || 1, //费用类型id 
                	remarks:this.formDataEdit.remarks,  // 收费标准说明 
                	standardType:0  // 标准类型（0-默认，1-自定义）
                }
                this.updateList(obj).then(()=>this.tableChange(0))
                
            }else if(this.mold == 'Standard'){ //默认标准页面新增事件
                const obj = {
                    standardName:this.form.standardName,  //标准名称
                	quantity:this.form.quantity,  //数量
                	amount:this.form.amount,  //金额（元/年）
                	costTypeId:this.form.costTypeId || 1, //费用类型id 
                	remarks:this.form.remarks,  // 收费标准说明 
                	standardType:0,  // 标准类型（0-默认，1-自定义）
                }
                
                this.addList(obj).then(()=>this.tableChange(0))
                
            }else if (this.mold == 'Custom') { //自定义页面新增事件
                const obj = {
                    standardName:this.form.standardName,  //标准名称
                	quantity:this.form.quantity,  //数量
                	amount:this.form.amount,  //金额（元/年）
                	costTypeId:this.form.costTypeId || 1, //费用类型id 
                	remarks:this.form.remarks,  // 收费标准说明 
                	standardType:1,  // 标准类型（0-默认，1-自定义）
                }
                const companyName = this.userInfo.companyName
                const CompanyList = this.CompanyList
                let company= CompanyList.filter(item=>{
                    return item.companyName == companyName
                })
                obj.companyId=company[0].companyId
                this.addList(obj).then(()=>this.tableChange(1))
                
            }else if (this.mold == 'editCustom') {
                const obj = {
                	id:this.formDataEdit.id,  // 需要修改的收费标准id 
                	// companyId:this.formDataEdit.companyId,  // 企业id，
                	quantity:this.formDataEdit.quantity,  //数量
                	amount:this.formDataEdit.amount,  //金额（元/年）
                	costTypeId:this.formDataEdit.costTypeId || 1, //费用类型id 
                	remarks:this.formDataEdit.remarks,  // 收费标准说明 
                	standardType:1,  // 标准类型（0-默认，1-自定义）
                }

                const companyName = this.userInfo.companyName
                const CompanyList = this.CompanyList
                let company= CompanyList.filter(item=>{
                    return item.companyName == companyName
                })
                obj.companyId=company[0].companyId

                this.updateList(obj).then(()=>this.tableChange(1))
                
            }
           
            this.form= {
                id:'',
				standardName:'',  // 需要修改的收费标准id 
				companyId:'',  // 企业id，
				quantity:null,  //数量
				amount:null,  //金额（元/年）
				costTypeId:'', //费用类型id 
				remarks:'',  // 收费标准说明 
			}
            this.innerVisible = false
            this.changeShow(false)
            
        }
    }
    
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer{
    text-align: center;
}
 /* ::v-deep .el-button{
     width: 300px;
 } */

.charging{
    font-size: 15px;
    padding-bottom: 10px;
}

</style>