<template>
	<div class="base_box">
		<div class="down_exe_box">
			注意：打印前请先
			<a :href="`${path}pages/lib/lodop/CLodop_Setup_for_Win32NT.exe`" download="CLodop_Setup_for_Win32NT.exe" target='_self'>
			  下载打印插件
			</a>
			,安装成功后请刷新网页
		</div>
		<div class="code_header_box">
			<!-- <span>打印尺寸：</span>
			<el-select v-model="codeWidth" placeholder="请打印尺寸选择">
				<el-option
					v-for="item in codeWidthArr"
					:key="item.value"
					:label="item.label"
					:value="item.value">
				</el-option>
			</el-select> -->
			<el-button class="btn_box" size="small" type="primary" plain @click="handlePrintAll">全部打印</el-button>
			<el-button class="btn_box" size="small" type="primary" plain @click="downloadImgAll">全部下载</el-button>
			<el-button class="btn_box" size="small" type="primary" plain @click="handleSelectText">标签字段选择</el-button>
		</div>
		<div class="code_container">
			<div class="code_cont_box" :style="contWidth" ref="codeAll">
				<div class="code_box" :style="codeHeightWidth" v-for="(item, index) in dataArr" :key="index" :ref="'printId'+ index">
				  <div class="title_box" :style="titleBoxHeight">
						<div class="qrcode"  :id="'qrcode'+index" :ref="'qrcode'+index"></div>
						<div class="code_name">标识编码</div>
						<div class="logo_img_box">
							
							<!-- <img class="logo_code_img" src="../../../assets/pages/equipmentData/logo_code.png" > -->
							<img class="logo_code_img" :src="imgUrl1+'/pages/equipmentData/logo_code.png'" >
						</div>
					</div>
					<div class="code_text_box" :style="textHeight">
						<div class="text_item" v-for="(obj, num) in selectTextArr" :key="num">
							<div class="key">{{obj.name}}：</div>
							<div class="val">{{defaultArr[index][obj.field]?defaultArr[index][obj.field]:''}}</div>
						</div>
					</div>
					<div class="down_box no-print">
						<div class="item_btn" @click="downloadImg(item.materialId, index)">下载</div>
						<div class="item_btn" @click="handlePrint(item)">打印</div>
					</div>

					<div class="firmCode">
						<div class="qrcodeB"  :id="'qrcodeB'+index" :ref="'qrcodeB'+index"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 选择显示内容 -->
		<el-dialog
		  title="选择打印显示的标签内容"
		  :visible.sync="showTextSelectModel"
		  top="5vh"
		  width="1000px"
			append-to-body>
		  <div class="dialog_content_box">
		    <QRcodeTextSelect @closeModel="closeModel" @changeSelectTextArr="changeSelectTextArr" v-if="showTextSelectModel" ref="TextSelectRef"></QRcodeTextSelect>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	var LODOP;
	import QRCode from 'qrcodejs2'; // 引入qrcode
	import { getLodop } from '../../../common/lib/LodopFuncs.js';
	import {mapActions, mapState} from 'vuex';
	import QRcodeTextSelect from './QRcode_text_select.vue';
	import html2canvas from "html2canvas";
	export default {
		components:{
			QRcodeTextSelect,
		},
		data(){
			return {
				path: process.env.BASE_URL,
				// logoImg: require('@/assets/pages/equipmentData/code_img.png'),
				// logoImg: imgUrl1+'/pages/equipmentData/code_img.png',
				defaultArr:[],
				dataArr:[],
				codeWidthArr: [
					{ "value": '210,135', "label": "宽210px 高135px" },
					{ "value": '390,210', "label": "宽390px 高210px" },
				],
				codeWidth: '390,210',
				showTextSelectModel:false,
				selectTextArr:[
					{
						field: "handle1",
						index: 2024,
						name: "标识handle1",
					},
					{
						field: "materialId",
						index: 2000,
						name: "物料编码",
					},{
						field: "describe1",
						index: 2001,
						name: "物料描述1",
					},{
						field: "materialType",
						index: 2007,
						name: "物料类型",
					},{
						field: "model",
						index: 2005,
						name: "型号",
					},{
						field: "describe2",
						index: 2002,
						name: "物料描述2",
					},
					
				],	//默认选择的打印标签内容
			}
		},
		watch:{
			codeWidth(){
				this.dataArr = [];
				this.$nextTick(()=>{
					this.dataArr = this.defaultArr;
					this.$nextTick(()=>{
						this.qrcode(this.dataArr);
						this.qrcod2(this.dataArr);
					})
				})
			},
		},
		computed:{
			...mapState({
				baseUrl: state => state.baseUrl,
				imgUrl1: state => state.imgUrl,
			}),
			textHeight(){
				if(this.codeWidth == '390,210'){
					return 'height:calc(100% - 112px);'
				}else {
					return 'height:calc(100% - 50px);'
				}
			},
			titleBoxHeight(){
				if(this.codeWidth == '390,210'){
					return 'height:112px;'
				}else {
					return 'height:50px;'
				}
			},
			codeHeightWidth(){
				let arr = this.codeWidth.split(',');
				let width = Number(arr[0]) * 1.5;
				let height =  Number(arr[1]) * 1.5;
				return `height:${height}px;width:${width}px;`
			},
			contWidth(){
				let arr = this.codeWidth.split(',');
				let width = Number(arr[0]) * 1.5;
				return `width:${width}px;`
			},
		},
		methods:{
			init(data){
				this.defaultArr = data;
				this.dataArr = data;
				this.$nextTick(() => {
				  this.qrcode(data);
				  this.qrcode2(data);
				});
			},
			handleSelectText(){
				this.showTextSelectModel = true;
				this.$nextTick(()=>{
					this.$refs.TextSelectRef.init(this.defaultArr[0], this.selectTextArr);
				});
			},
			closeModel(){
				this.showTextSelectModel = false;
			},
			changeSelectTextArr(arr){
				this.selectTextArr = arr;
				// console.log(this.defaultArr);
				let textArr = this.selectTextArr;
				let array = []
				for (let i = 0; i < textArr.length; i++) {
					// console.log(textArr[i]);
					if (!textArr[i].hasOwnProperty('value')) {
						array.push(textArr[i].field)
					}
				}
				if (this.defaultArr[0].content) {
					for (let index = 0; index < array.length; index++) {
						this.defaultArr[0][ array[index]] = JSON.parse(this.defaultArr[0].content)[array[index]]
					}
				}
				// console.log(this.defaultArr);
				this.showTextSelectModel = false;
			},
			qrcode(arr) {
			  // 生成二维码
				let width = 100;
				let baseUrl = this.baseUrl;
			  arr.map((item, index) => {
			    new QRCode('qrcode'+index, {/////////////////////////////////////////
			    //   width: width *0.5,
			    //   height: width *0.5,
				// margin: 1,//二维码内边距，默认为4。单位px
				  width: width,
			      height: width,
			      text: baseUrl+'/'+item.handle1,// 二维码地址
				  correctLevel : QRCode.CorrectLevel.L, //容错级别
				  render   : "canvas",//设置渲染方式 （有两种方式 table和canvas，默认是canvas）   
				//   render   : "table",
			 	  correctLevel    : 0,//纠错等级 
				// 	   rendererOpts: {
				//     quality: 0.9
				// },
				typeNumber  : -1,      //计算模式 
			    });
			  });
			 
			},

			qrcode2(arr) {
				// console.log(arr);
			  // 生成二维码
				let width = 100;
				// let baseUrl = this.baseUrl;
			   arr.map((item, index) => {
			    new QRCode('qrcodeB'+index, {/////////////////////////////////////////
			      width: width,
			      height: width,
			      text: item.companyQrUrl, // 二维码内容
			    //   text: '测试', // 二维码内容
				//   correctLevel : QRCode.CorrectLevel.H //容错级别
			    });
				// console.log(item.companyQrUrl)
			  });
			},
			handlePrintAll(){	//打印全部二维码
				this.CreatePrintPage(this.defaultArr);
				LODOP.PREVIEW(); //预览(预览打印无脚标)
				// LODOP.PRINT();  //打印
			},
			handlePrint(obj){	//打印单个二维码
				this.CreatePrintPage([obj]);
				LODOP.PREVIEW(); //预览(预览打印无脚标)
				// LODOP.PRINT();  //打印
			},
			CreatePrintPage(arr) {
				//文本
				let textArr = this.selectTextArr;
				// console.log(textArr);
				
				LODOP.PRINT_INIT("");
				LODOP.SET_PRINT_PAGESIZE(1, 1200, 700, "");
				//循环打印标签页
				arr.map((item, index) => {
					// console.log(item);
					// if (item.content) {
					// 	item['bz'] = JSON.parse(item.content).bz
					// }
					LODOP.NewPage();
					LODOP.SET_PRINT_MODE("PRINT_NOCOLLATE", 1);
					
					LODOP.ADD_PRINT_BARCODE(14, 21, 100, 100, "QRCode", `${this.baseUrl}/${item.handle1}`);
					LODOP.SET_PRINT_STYLEA(0,"QRCodeVersion",7);//设置二维码版本为7
					LODOP.ADD_PRINT_IMAGE(46,144,257,49, "<img border='0' src='"+ this.imgUrl1+'/pages/equipmentData/code_img.png' +"'/>");
					
					LODOP.ADD_PRINT_TEXT(32, 116, 20, 60, "标识编码");
					LODOP.SET_PRINT_STYLEA(0, "FontName", "微软雅黑");
					LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
					LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
					
					LODOP.ADD_PRINT_LINE(110,22,110,382,0,1);
					
					// （纵坐标，横坐标，宽度，高度）
					LODOP.ADD_PRINT_TEXTA("text1", 115, 22, 350, 32, `${textArr[0].name}:    ${item[textArr[0].field]?item[textArr[0].field]:''}`);
					LODOP.SET_PRINT_STYLEA(0, "FontName", "微软雅黑");
					LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
					LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
					LODOP.ADD_PRINT_TEXTA("text2", 138, 22, 350, 32, `${textArr[1].name}:    ${item[textArr[1].field]?item[textArr[1].field]:''}`);
					LODOP.SET_PRINT_STYLEA(0, "FontName", "微软雅黑");
					LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
					LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
					LODOP.ADD_PRINT_TEXTA("text3", 161, 22, 350, 32, `${textArr[2].name}:    ${item[textArr[2].field]?item[textArr[2].field]:''}`);
					LODOP.SET_PRINT_STYLEA(0, "FontName", "微软雅黑");
					LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
					LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
					LODOP.ADD_PRINT_TEXTA("text6", 184, 22, 350, 32, `${textArr[3].name}:    ${item[textArr[3].field]?item[textArr[3].field]:''}`);
					LODOP.SET_PRINT_STYLEA(0, "FontName", "微软雅黑");
					LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
					LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
					LODOP.ADD_PRINT_TEXTA("text5", 207, 22, 350, 32, `${textArr[4].name}:    ${item[textArr[4].field]?item[textArr[4].field]:''}`);
					LODOP.SET_PRINT_STYLEA(0, "FontName", "微软雅黑");
					LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
					LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
					LODOP.ADD_PRINT_TEXTA("text4", 230, 22, 350, 32, `${textArr[5].name}:    ${item[textArr[5].field]?item[textArr[5].field]:''}`);
					LODOP.SET_PRINT_STYLEA(0, "FontName", "微软雅黑");
					LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
					LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
					
					// LODOP.ADD_PRINT_BARCODE(120, 280, 100, 100, "QRCode", `${item.companyQrUrl}`);
					// LODOP.ADD_PRINT_TEXTA("text7", 211, 22, 350, 25, `${textArr[6].name}:    ${item[textArr[6].field]?item[textArr[6].field]:''}`);
					// LODOP.SET_PRINT_STYLEA(0, "FontName", "微软雅黑");
					// LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
					// LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
				})
			},
			downloadImgAll(){	//全部二维码下载
				this.defaultArr.map((item, index) => {
					this.downloadImg(item.materialId, index);
				})
			},
			downloadImg (name, index) {	//单个二维码下载
				let div = eval("this.$refs.printId" +index)[0];
				const clientWidth = div.offsetWidth;
				const clientHeight = div.offsetHeight;
				const kh = [clientWidth, clientHeight];
	
				html2canvas(div, { useCORS: true, logging: true }).then((canvas) => {
					const dataURL = canvas.toDataURL('image/png');
					this.download(dataURL, kh, name);
				});
			},
			download(imgUrl, kh, name) {
				this.getUrlBase64(imgUrl, kh).then((base64) => {
					const link = document.createElement('a');
					link.href = base64;
					link.download = `物料编码${name}.png`;
					link.click();
				});
			},
			getUrlBase64(url, kh) {
				return new Promise((resolve) => {
					let canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');
					const img = new Image();
					img.crossOrigin = 'Anonymous'; // 允许跨域
					img.src = url;
					img.onload = () => {
						canvas.height = kh[1];
						canvas.width = kh[0];
						ctx.drawImage(img, 0, 0, kh[0], kh[1]);
						const dataURL = canvas.toDataURL('image/png');
						canvas = null;
						resolve(dataURL);
					};
				});
			},
		},
		mounted() {
			LODOP = getLodop();
		}
	}
</script>

<style lang="scss" scoped>
	.base_box{
		width: 100%;
		height: calc(90vh - 80px);
		overflow: auto;
		box-sizing: border-box;
		padding-bottom: 50px;
		.down_exe_box{
			background-color: #fff;
			width: 100%;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: red;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 99;
		}
		.code_header_box{
			width: 100%;
			height: 60px;
			display: flex;
			flex-direction: row;
			border-bottom: 4px solid #f1f2f3;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			padding-bottom: 10px;
			.btn_box{
				margin-left: 10px;
			}
		}
		.code_container{
			width: 100%;
			height: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.code_cont_box{
				height: auto;
			}
			.code_box{
				margin-top: 14px;
				padding:14px;
				position: relative;
				box-sizing: border-box;
				background-color: #f1f2f3;
				border-radius: 5px;
				color: #000;
				.title_box{
					width: 100%;
					box-sizing: border-box;
					border-bottom: 1px solid #333;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					padding-bottom: 10px;
					.qrcode{

						// transform: scale(0.5)
					}
					.code_name{
						width: 18px;
						height: auto;
						white-space: normal;
						margin-left: 10px;
					}
					.logo_img_box{
						width: calc(100% - 128px);
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						box-sizing: border-box;
						.logo_code_img{
							height: 35px;
						}
					}
				}
				.code_text_box{
					width: 100%;
					box-sizing: border-box;
					padding-top: 4px;
					.text_item{
						width: 100%;
						margin-top: 8px;
						display: flex;
						flex-direction: row;
						align-items: center;
						.key{
							width: 100px;
						}
						.val{
							width: calc(100% - 100px);
						}
					}
				}
			}
			.down_box{
				position: absolute;
				top: 20px;
				right: -120px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $theam-color;
				.item_btn{
					padding: 5px 10px;
					cursor: pointer;
				}
			}
			.firmCode{
				height: 100px;
				width: 100px;
				position: absolute;
				top: 190px;
				right: 20px;
				// background: bisque;
				// border: 1px solid;

			}
		}
	}
</style>
