<template>
	<div class="routerHeader_box">
		<el-tabs v-model="editableTabsValue" type="card" @tab-click="clickTheTabItem" @tab-remove="removeTab" :closable="editableTabsValue != '/homePage'">
			<el-tab-pane v-for="(item, index) in editableTabs" :key="item.name" :label="item.title" :name="item.name">
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	data() {
		return {
			editableTabsValue: '/homePage',
			editableTabs: [
				{
					title: '首页',
					name: '/homePage',
				}
			],
			tabIndex: 2
		};
	},
	watch:{
		editableTabsValue(){
			this.$router.replace(this.editableTabsValue);
			this.$emit('clickTabItem',this.editableTabsValue)
		},
	},
	computed: {},
	methods: {
		clickTheTabItem(name){
			if(this.$route.path === this.editableTabsValue){
				this.$emit('reShowPage')
			}
		},
		addTab(obj) {
			let isHaven = false;
			this.editableTabs.map(item => {
				if(item.name === obj.name){
					isHaven = true;
				}
			})
			if(!isHaven){
				this.editableTabs.push(obj);
			}
			this.editableTabsValue = obj.name;
		},
		removeTab(targetName) {
			if(targetName === '/homePage'){
				return
			}
			let tabs = this.editableTabs;
			let activeName = this.editableTabsValue;
			let clickIndex = -1;
			if (activeName === targetName) {
				tabs.forEach((tab, index) => {
					if (tab.name === targetName) {
						clickIndex = index;
						let nextTab = tabs[index + 1] || tabs[index - 1];
						if (nextTab) {
							activeName = nextTab.name;
						}
					}
				});
			}
			this.editableTabsValue = activeName;
			this.editableTabs = tabs.filter(tab => tab.name !== targetName);
			
		}
	},
	mounted() {}
};
</script>

<style lang="scss" scoped>
.routerHeader_box {
	width: 100%;
	height: 100%;
}
</style>
