<template>
  <div class="userManage_box">
    <div class="header_search_box">
      <div class="search_item_box">
        <div>操作人：</div>
        <el-input
          class="input_box"
          placeholder="请输入操作人关键字"
          v-model="queryUserName"
          clearable
          @keyup.enter.native="getDataList"
        ></el-input>
      </div>
      <div class="search_item_box">
        <div>操作类型：</div>
        <el-select
          clearable
          class="input_box"
          v-model="queryOperateType"
          placeholder="请选择操类型"
          ><el-option
            v-for="item in operateTypeArr"
            :key="item"
            :label="item"
            :value="item"
          ></el-option
        ></el-select>
      </div>
      <el-date-picker
        class="marginRight"
        v-model="timeSelect"
        type="datetimerange"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        align="right"
      ></el-date-picker>
      <el-button type="primary" @click="getDataList" plain>搜索</el-button>
    </div>
    <div class="tabel_box">
      <div class="table_cont">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="tableMaxHeight"
          border
          size="medium"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="logDescribe"
            label="操作描述"
            width="150"
          ></el-table-column>
          <el-table-column prop="operateUser" label="操作人"></el-table-column>
          <el-table-column
            prop="operateType"
            label="操作类型"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="operateObject"
            label="操作对象"
          ></el-table-column>
          <el-table-column
            prop="operateTime"
            label="操作时间"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
          ></el-table-column>
        </el-table>
      </div>
      <div class="page_box">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      queryUserName: "",
      queryOperateType: "",
      timeSelect: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      operateTypeArr: [],
      tableData: [],
      total: 0,
      multipleSelection: [],
      pageIndex: 1,
      pageSize: 20,
    };
  },
  watch: {
    queryOperateType() {
      this.getDataList();
    },
    timeSelect() {
      this.getDataList();
    },
    logList() {
      console.log(this.logList);
      if (this.logList.success) {
        this.operateTypeArr = this.logList.data.operateDict;
        this.tableData = this.logList.data.pageInfo.list;
        this.total = this.logList.data.pageInfo.total;
      } else {
        this.$message({
          showClose: true,
          message: this.logList.message,
          type: "error",
        });
      }
    },
  },
  computed: {
    ...mapState({
      logList: (state) => state.RecordQuery.logList,
    }),
    tableMaxHeight() {
      let h = document.documentElement.clientHeight;
      return h - 220;
    },
  },
  methods: {
    ...mapActions({
      getLogList: "RecordQuery/getLogList",
    }),

    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val);
    },
    showDetail(index, obj) {
      //详情
      // console.log(obj);
    },
    handleDelete(index, obj) {
      //删除
      // console.log(obj);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageIndex = val;
      this.getDataList();
    },
    getDataList() {
      let time = this.timeSelect;
      let params = {
        startTime: time ? this.$commonApi.getLocalTime(time[0]) : "",
        endTime: time ? this.$commonApi.getLocalTime(time[1]) : "",
        queryUserName: this.queryUserName,
        queryOperateType: this.queryOperateType,
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
      };
      this.getLogList(params);
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>

<style lang="scss" scoped>
.userManage_box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  .header_search_box {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    .search_item_box {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
    }
    .input_box {
      width: 200px;
    }
    .marginRight {
      margin-right: 10px;
    }
  }
  .tabel_box {
    width: 100%;
    height: calc(100% - 40px);
    box-sizing: border-box;
    padding-top: 10px;
    .table_cont {
      width: 100%;
      height: calc(100% - 40px);
    }
    .page_box {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>

<style>
.el-date-table td.end-date span,
.el-date-table td.start-date span:hover {
  background-color: red !important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: red !important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span:nth-of-type(2):hover {
  background-color: #409eff !important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span:nth-of-type(2) {
  background-color: #409eff !important;
}

.el-date-table td.end-date div,
.el-date-table td.start-date div {
  color: #fff;
}
</style>


