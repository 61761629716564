<template>
	<div class="tempManage_box">
		<div class="header_search_box">
			<el-input class="input_box" placeholder="请输入模板ID/模板名称关键字" v-model="searchParam" clearable @keyup.enter.native="getDataList"></el-input>
			<el-button type="primary" @click="getDataList" plain>搜索</el-button>
			<el-button type="primary" plain @click="showDetailEdit({}, 'add')" v-if="userInfo.roleId === 0">新增</el-button>
			<el-button type="primary" @click="showBaseTemplate">通用模板</el-button>
		</div>
		<div class="tabel_box">
			<div class="table_cont">
				<el-table :data="tableData" style="width: 100%" :height="tableMaxHeight" border size="medium" @selection-change="handleSelectionChange">
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="templateName" label="模板ID" width="240"></el-table-column>
					<el-table-column prop="templateTitle" label="模板名称" width="350"></el-table-column>
					<el-table-column prop="templateDescribe" label="模板描述"></el-table-column>
					<el-table-column prop="addTime" label="添加时间" width="154"></el-table-column>
					<el-table-column fixed="right" prop="date" label="操作" :width="userInfo.roleId === 0?400:280">
						<template slot-scope="scope">
							<el-button size="mini" @click="showDetailEdit(scope.row, 'detail')">详情</el-button>
							<el-button size="mini" type="primary" v-if="userInfo.roleId === 0" @click="showDetailEdit(scope.row, 'edit')">编辑</el-button>
							<el-button size="mini" type="danger" v-if="userInfo.roleId === 0" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
							<el-button size="mini" type="primary" @click="exportTemp(scope.$index, scope.row)">导出模板</el-button>
							<el-button size="mini" type="success" @click="getApiTemplate(scope.row)">api示例</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page_box">
				<el-pagination
				      @size-change="handleSizeChange"
				      @current-change="handleCurrentChange"
				      :current-page="pageIndex"
				      :page-sizes="[10, 20, 50, 100]"
				      :page-size="pageSize"
				      layout="total, sizes, prev, pager, next, jumper"
				      :total="total">
				    </el-pagination>
			</div>
		</div>
		<!-- 编辑 / 详情 -->
		<el-dialog
			:close-on-click-modal="false"
		  :title="title"
		  :visible.sync="showModel"
		  top="5vh"
		  width="800px">
		  <div class="dialog_content_box">
		    <ModelPage v-if="showModel" ref="modelRef" @refreshDataList="refreshDataList"></ModelPage>
		  </div>
		</el-dialog>
		<!-- 通用模板 -->
		<el-dialog
		  title="通用模板"
		  :visible.sync="showBase"
		  top="5vh"
		  width="800px">
		  <div class="dialog_content_box">
		    <BaseTemp v-if="showBase" ref="BaseTempRef"></BaseTemp>
		  </div>
		</el-dialog>

		<!-- api示例 -->
		<el-dialog
		  title="api示例"
		  :visible.sync="showApi"
		  top="5vh"
		  width="800px">
		  <div class="dialog_content_box">
			  <!-- {{exampleList}} -->
				 <json-viewer :value="exampleList" :expand-depth=5 :copyable="copyable"></json-viewer>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ModelPage from './templete-detail-edit.vue'
import BaseTemp from './baseTemp.vue'
import JsonViewer from 'vue-json-viewer' //格式化json插件
export default {
	components:{
		ModelPage,
		BaseTemp,
		JsonViewer,
	},
	data() {
		return {
			searchParam: '',
			tableData: [],
			total:0,
			multipleSelection: [],
			pageIndex:1,
			pageSize:20,
			title:'',
			showModel:false,
			showBase:false,	//通用模板
			showApi:false,
			exampleList:[],//示例
			copyable:{copyText:'复制',copiedText:'已复制',timeout:2000},//自定义copyable
		};
	},
	watch:{
		dataList(){
			// console.log(this.dataList);
			if(this.dataList.success){
				this.tableData = this.dataList.data.list;
				this.total = this.dataList.data.total;
			}else{
				this.$message({
					showClose: true,
					message:this.dataList.message,
					type: 'error'
				});
			}
		},
		deleteTemplateResult(){
			if(this.deleteTemplateResult.success){
				this.$message({
					showClose: true,
					message:'删除成功',
					type: 'success'
				});
				this.getDataList();
			}else{
				this.$message({
					showClose: true,
					message:this.deleteTemplateResult.message,
					type: 'error'
				});
			}
		},
		apiExample(){
			if(this.apiExample.success){
				// this.$message({
				// 	showClose: true,
				// 	message:'成功',
				// 	type: 'success'
				// });
				this.exampleList=this.apiExample.data
			}else{
				this.$message({
					showClose: true,
					message:this.apiExample.message,
					type: 'error'
				});
			}
		},
	},
	computed: {
		...mapState({
			userInfo: state => state.userInfo,
			dataList: state => state.TempManage.userList,
			deleteTemplateResult: state => state.TempManage.deleteTemplateResult,
			baseTemplate: state => state.TempManage.baseTemplate,
			apiExample: state => state.TempManage.apiExample,//获取api示例
		}),
		tableMaxHeight() {
			let h = document.documentElement.clientHeight;
			return h - 220;
		}
	},
	methods: {
		...mapActions({
			getList:'TempManage/getList',
			deleteTemplate:'TempManage/deleteTemplate',
			getBaseTemplate:'TempManage/getBaseTemplate',//基础模板
			GetApiExample:'TempManage/GetApiExample',//获取api示例
		}),
		handleSelectionChange(val) {
			this.multipleSelection = val;
			// console.log(val);
		},
		handleDelete(index, obj) {
			//删除
			// console.log(obj);
			this.$confirm(`确定进行删除操作?`, '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
			  let params = {
			  	id:obj.id
			  }
			  this.deleteTemplate(params);
			}).catch(() => {
            
			});
		},
		showDetailEdit(obj, fromFun) {
			//详情 / 编辑
			// console.log(obj);
			if(fromFun == 'detail'){
			  this.title = '模板详情';
			}else if(fromFun == 'edit'){
			  this.title = '编辑模板';
			}else {
				this.title = '新增模板';
			}
			this.showModel = true;
			this.$nextTick(() => {
			  this.$refs.modelRef.init(JSON.parse(JSON.stringify(obj)),fromFun);
			})
		},
		refreshDataList(){
			this.showModel = false;
			this.getDataList();
		},
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getDataList();
		},
		handleCurrentChange(val) {
			// console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getDataList();
		},
		getDataList(){
			let params = {
				searchParam:this.searchParam,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
			}
			this.getList(params);
		},
		showBaseTemplate(){	//线上公共模板
			if(this.baseTemplate.length > 0){
				this.showBase = true;
			}else {
				this.getBaseTemplate();
			}
		},
		exportTemp(index, obj){	//导出单个模板
			if(this.baseTemplate.length < 1){
				this.getBaseTemplate();
				this.$message({
					showClose: true,
					message:'请稍后再试',
					type: 'success'
				});
				return
			}
			// console.log(this.baseTemplate);
			let templateInfo = JSON.parse(obj.templateInfo);
			// console.log(templateInfo);
			let arr = [];
			this.baseTemplate.map(item => {
				arr.push(item.name);
			})
			templateInfo.map(item => {
				arr.push(item.name);
			})
			
			require.ensure([], () => {
				let tHeader = [arr]
			  this.export_json_to_excel({
			    title: [],
			    header: tHeader,
			    data:[],
			    merges:[],
			    filename: `${obj.templateName}`,
			    autoWidth: true,
			    bookType: 'xlsx',
			  });
			});
		},
		getApiTemplate(row){
			this.showApi=true;
			// console.log(row.templateName);//contentType
			let params = {
				contentType:row.templateName,
			}
			this.GetApiExample(params);
		}
	},
	mounted() {
		this.getDataList();
	}
};
</script>

<style lang="scss" scoped>
.tempManage_box {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 10px 20px;
	.header_search_box {
		width: 100%;
		height: 40px;
		display: flex;
		flex-direction: row;
		.input_box {
			width: 300px;
			margin-right: 10px;
		}
	}
	.tabel_box {
		width: 100%;
		height: calc(100% - 40px);
		box-sizing: border-box;
		padding-top: 10px;
		.table_cont {
			width: 100%;
			height: calc(100% - 40px);
		}
		.page_box {
			width: 100%;
			height: 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
}
</style>
