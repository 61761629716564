<template>
	<div class="detail_content_container" :style="detailHeight">
		<div class="content_box" style="margin-top: 0;">
			<div class="title_name">企业基本信息</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						企业标识
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.companyCode" clearable></el-input></div>
			</div> 
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						企业/机构名称
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.companyName" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						企业/机构类型
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val">
					<el-input class="input_box" :disabled="true" v-model="formObj.companyType" clearable></el-input>
				</div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						统一社会信用代码
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.socialCreditCode" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						企业/机构行业
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val">
					<el-input class="input_box" :disabled="true" :value="getIndustryTypeText(formObj.industryType)" clearable></el-input>
				</div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						注册地址
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val">
					<el-input class="input_box" :disabled="true" :value="getAreaText(formObj.registeredAddress)" clearable></el-input>
				</div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						企业详细地址
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" type="textarea" :rows="2" v-model="formObj.companyAddress"></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						企业联系电话
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.contactsPhone" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						营业执照
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val">
					<div class="upload_box">
						<img v-if="formObj.licenseUrl" :src="formObj.licenseUrl" class="img_upload" @click="showImgDetail(formObj.licenseUrl)"/>
						<div class="companyID_img_box" v-else><i class="el-icon-plus avatar-uploader-icon"></i></div>
					</div>
				</div>
			</div>
			<div class="cont_item">
				<div class="key"><span class="relative_key">官方网站</span></div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.officialWebsite" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key"><span class="relative_key">企业简介</span></div>
				<div class="val"><el-input class="input_box" :disabled="true" type="textarea" :rows="2" v-model="formObj.introduce"></el-input></div>
			</div>
		</div>
		<div class="content_box">
			<div class="title_name">法人信息</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						法人姓名
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.corporationName" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						法人身份证号
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.corporationId" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						法人证件照片
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val">
					<div class="upload_box">
						<img v-if="corpFrontImgUrl" :src="corpFrontImgUrl" class="img_upload"  @click="showImgDetail(corpFrontImgUrl)"/>
						<div class="img_box" v-else>
							<img src="@/assets/pages/systemManage/front.png" alt="" />
							<img class="add_img" src="@/assets/pages/systemManage/add.png" alt="" />
						</div>
					</div>
					<div class="upload_box" style="margin-left:20px;">
						<img v-if="corpBackImgUrl" :src="corpBackImgUrl" class="img_upload" @click="showImgDetail(corpBackImgUrl)"/>
						<div class="img_box" v-else>
							<img src="@/assets/pages/systemManage/back.png" alt="" />
							<img class="add_img" src="@/assets/pages/systemManage/add.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content_box">
			<div class="title_name">联系人基本信息</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						联系人姓名
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.contacts" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						联系人手机号
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" v-model="formObj.companyTelephone" :disabled="true" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						联系人邮箱
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.contactsEmail" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						联系人身份证号
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val"><el-input class="input_box" :disabled="true" v-model="formObj.contactsId" clearable></el-input></div>
			</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						证件照片
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val">
					<div class="upload_box">
						<img v-if="contactFrontImgUrl" :src="contactFrontImgUrl" class="img_upload" @click="showImgDetail(contactFrontImgUrl)"/>
						<div class="img_box" v-else>
							<img src="@/assets/pages/systemManage/front.png" alt="" />
							<img class="add_img" src="@/assets/pages/systemManage/add.png" alt="" />
						</div>
					</div>
					<div class="upload_box" style="margin-left:20px;">
						<img v-if="contactBackImgUrl" :src="contactBackImgUrl" class="img_upload" @click="showImgDetail(contactBackImgUrl)"/>
						<div class="img_box" v-else>
							<img src="@/assets/pages/systemManage/back.png" alt="" />
							<img class="add_img" src="@/assets/pages/systemManage/add.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content_box" v-if="fromFun == 'examine'">
			<div class="title_name">入网申请审核</div>
			<div class="cont_item">
				<div class="key">
					<span class="relative_key">
						入网申请
						<span class="key_tips">*</span>
					</span>
				</div>
				<div class="val">
					<el-radio v-model="userState" label="2">同意</el-radio>
					<el-radio v-model="userState" label="0">不同意</el-radio>
				</div>
			</div>
		</div>
		<div class="bootom_submit_box" v-if="fromFun == 'examine'">
			<el-button type="primary" @click="submit">提交审核</el-button>
		</div>
	</div>
</template>

<script>
	import { CodeToText as CodeToText_category } from 'element-china-category-data';
	import { CodeToText as CodeToText_area } from 'element-china-area-data';
	import { mapActions, mapMutations, mapState } from 'vuex';
	export default {
		data(){
			return {
				fromFun:'detail',
				changeHeight: false,
				contactIDTypeList: [
					{
						label: '身份证',
						value: '1'
					}
				], // 证件类型列表
				companyNatureList: [
					{
						label: '国有控股',
						value: '国有控股'
					},
					{
						label: '民营控股',
						value: '民营控股'
					},
					{
						label: '外商控股',
						value: '外商控股'
					},
					{
						label: '事业单位',
						value: '事业单位'
					},
					{
						label: '民营非盈利组织',
						value: '民营非盈利组织'
					}
				], // 企业类型列表
				companyIDTypeList: [
					{
						label: '统一社会信用代码',
						value: '1'
					}
				], // 企业类型列表
				contactFrontImgUrl: '', //联系人正面 证件照
				contactBackImgUrl: '', //联系人反面 证件照
				corpFrontImgUrl: '', //法人正面 证件照
				corpBackImgUrl: '', //法人反面 证件照
				formObj: {
					contacts: '', // 联系人姓名
					companyTelephone: '', //联系人手机号
					contactsEmail: '', //联系人邮箱
					contactsId: '', // 联系人证件号
					contactIDType: '1', // 请选择证件类型
					corporationName: '', // 法人姓名
					corpIDType: '1', // 法人证件类型
					corporationId: '', // 法人证件号
					companyCode: '', //企业标识
					companyName: '', //企业名称
					companyType: '', // 企业类型
					// companyIDType: '1', //机构证件类型
					socialCreditCode: '', //机构证件号
					licenseUrl: '', // 营业执照照片
					industryType: [], // 行业类型
					registeredAddress: [], // 注册地址
					officialWebsite: '', //官网
					companyAddress: '', //企业详细地址
					introduce: '', //企业简介
					contactsPhone: '' ,// 企业联系电话
				},
				userState:'2',	//审核状态
			}
		},
		watch:{
			examineCompanyResult(){
				if(this.examineCompanyResult.success){
					this.$emit('refreshDataList');
					this.$message({
						showClose: true,
						message:'审核完成',
						type: 'success'
					});
				}else{
					this.$message({
						showClose: true,
						message:this.examineCompanyResult.message,
						type: 'error'
					});
				}
			},
		},
		computed:{
			...mapState({
				userInfo: state => state.userInfo,
				examineCompanyResult: state => state.SystenManage.examineCompanyResult,
			}),
			detailHeight(){
				if(this.changeHeight){
					return `height:auto;overflow:hidden;`
				}else{
					return ''
				}
			},
		},
		methods:{
			...mapActions({
				examineCompany:'SystenManage/examineCompany'
			}),
			init(obj, fromFun, changeHeight){
				this.changeHeight = changeHeight;
				this.fromFun = fromFun;
				let newObj = {};
				Object.keys(this.formObj).forEach(key => {
					newObj[key] = obj[key];
				});
				newObj.id = obj.id;
				newObj.username = obj.username;
				
				if (newObj.industryType) {
					newObj.industryType = JSON.parse(newObj.industryType); // 行业数组字符串变为对象
				}
				if (newObj.registeredAddress) {
					newObj.registeredAddress = JSON.parse(newObj.registeredAddress); // 省市区 数组字符串变为对象
				}
				
				this.formObj = newObj;
				
				if (obj.contactsIdPhoto) {
					const contactsIdPhoto = obj.contactsIdPhoto.split(',');
					this.contactFrontImgUrl = contactsIdPhoto[0];
					this.contactBackImgUrl = contactsIdPhoto[1];
				}
				
				if (obj.corporationIdPhoto) {
					const corporationIdPhoto = obj.corporationIdPhoto.split(',');
					this.corpFrontImgUrl = corporationIdPhoto[0];
					this.corpBackImgUrl = corporationIdPhoto[1];
				}
			},
			showImgDetail(url){
				window.open(url);
			},
			getIndustryTypeText(data){	//行业类型
				let str = '';
				if (data) {
					data.map((item, index) => {
						if(index === 0){
							str += CodeToText_category[item];
						}else {
							str += '/'+CodeToText_category[item];
						}
					});
				}else {
					str = data;
				}
				return str;
			},
			getAreaText(data){	//地区
				let str = '';
				if (data) {
					data.map((item, index) => {
						if(index === 0){
							str += CodeToText_area[item];
						}else {
							str += '/'+CodeToText_area[item];
						}
					});
				}else {
					str = data;
				}
				return str;
			},
			submit(){	//审核
				// console.log(this.userState);
				let params = {
					username:this.formObj.username,
					userStatus:Number(this.userState)
				}
				this.examineCompany(params);
			},
		},
	}
</script>

<style lang="scss" scoped>
	.detail_content_container {
		width: 100%;
		height: calc(90vh - 80px);
		overflow: auto;
		.step3_box {
			width: 100%;
			height: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #888;
			.edit_text {
				color: #0067c4;
				cursor: pointer;
			}
		}
		.content_box {
			width: 100%;
			height: auto;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.title_name {
				font-size: 15px;
				font-weight: bold;
			}
			.cont_item {
				width: 100%;
				min-height: 40px;
				display: flex;
				align-items: center;
				color: #888;
				margin-top: 20px;
				box-sizing: border-box;
				padding-left: 20px;
				.key {
					width: 150px;
					height: 100%;
					display: flex;
					align-items: center;
					.relative_key {
						position: relative;
						.key_tips {
							color: #f82b2b;
							position: absolute;
							right: -8px;
							top: -2px;
						}
					}
				}
				.val {
					width: calc(100% - 150px);
					height: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					align-items: flex-start;
					position: relative;
					.select_box {
						margin-right: 20px;
						width: 180px;
					}
					.select_input_box {
						width: 380px;
					}
					.input_box {
						width: 580px;
					}
					.upload_box {
						width: 180px;
						height: 110px;
						.img_box {
							width: 100%;
							height: 100%;
							position: relative;
							.add_img {
								width: 46px;
								height: 46px;
								position: absolute;
								top: calc(50% - 32px);
								left: calc(50% - 23px);
							}
						}
						.img_upload {
							width: 180px;
							height: 110px;
							cursor: pointer;
						}
						.companyID_img_box {
							width: 180px;
							height: 110px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px dashed #d9d9d9;
							font-size: 28px;
						}
					}
				}
			}
		}
		.bootom_submit_box {
			width: 100%;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	
</style>
