<template>
	<div class="header_box">
		<img class="logo_img" src="../../../assets/pages/components/logp_bottom.png" >
		<div class="right_box">
			<!-- <div class="othser_item">
				<div class="red_num">1</div>
				<i class="el-icon-message-solid"></i>
			</div> -->
			<el-dropdown  @command="command">
				<div class="user_icon">
					<i class="el-icon-user-solid"></i>
					<span class="userName">
						<span class="marginRight">{{userInfo.username}}</span>
						<i class="el-icon-arrow-down"></i>
					</span>
				</div> 
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="userSettings" v-if="this.userInfo.roleId !== 2">个人设置</el-dropdown-item>
					<el-dropdown-item command="loginOut" divided>退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapMutations, mapState} from 'vuex'
	export default {
		data(){
			return {
				
			}
		},
		computed:{
			...mapState({
				userInfo: state => state.userInfo,
			}),
		},
		methods:{
			...mapMutations({
				loginOutSys:'loginOutSys',
			}),
			command(e){
				this[e]();
			},
			loginOut(){	//退出登录
				this.loginOutSys();
				this.$router.replace('/');
			},
			userSettings(){	//个人设置
				this.$emit('clickMenuItem',{
					title:'个人设置',
					name:'/userSettings',
				});
			},
		},
		mounted() {
			// console.log(this.userInfo)
		}
	}
</script>

<style lang="scss" scoped>
	.header_box{
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 0 40px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		background-color: $theam-bgcolor;
		color: #fff;
		.logo_img{
			width: 248px;
		}
		.right_box{
			width: auto;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.othser_item{
				width: 60px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				font-size: 22px;
				cursor: pointer;
				margin-right: 20px;
				.red_num{
					position: absolute;
					padding: 2px 6px;
					border-radius: 100px;
					background-color: #2DD1AC;
					top: 8px;
					right: 8px;
					font-size: 12px;
				}
			}
			.user_icon{
				color: #fff;
				font-size: 22px;
				cursor: pointer;
			}
			.userName{
				font-size: $base-font-size;
				margin-left: 10px;
				.marginRight{
					padding-right: 6px;
				}
			}
		}
	}

	.el-dropdown-menu{
	z-index: 9999 !important;

	}
</style>

<style>
</style>