const menuList = [{
		title: '首页',
		name: '/homePage',
		icon: 'el-icon-s-home'
	}, {
		title: '驾驶舱',
		name: '/cockpit',
		icon: 'el-icon-s-grid'
	}, {
		title: '海工装备资料',
		name: '/equipmentData',
		icon: 'el-icon-s-order'
	}, {
		title: '操作记录查询',
		name: '/recordQuery',
		icon: 'el-icon-s-data'
	}, {
		title: '模板管理',
		name: '/tempManage',
		icon: 'el-icon-s-open'
	},
	{
		title: '员工管理',
		name: '/staffManage',
		icon: 'el-icon-user-solid',
		childrens: [{
			title: '资产用户列表',
			name: '/staffManage',
		}, {
			title: '资产领用记录',
			name: '/getLog',
		}, ]
	},
	// {
	// 	title: '员工管理',
	// 	name: '/staffManage',
	// 	icon: 'el-icon-user-solid'
	// }
	 {
		title: '财务管理',
		name: '/chargingStandard',
		icon: 'el-icon-s-goods',
		childrens: [{
			title: '收费标准',
			name: '/chargingStandard',
		}, {
			title: '财务报表',
			name: '/reportForm',
		}, {
			title: '发票管理',
			name: '/invoice',
		}]
	}, {
		title: '系统设置',
		name: '/userSettings',
		icon: 'el-icon-s-tools',
		childrens: [{
			title: '个人设置',
			name: '/userSettings',
		}, {
			title: '用户管理',
			name: '/userManage',
		}, {
			title: '企业入网申请',
			name: '/networkAccessApply',
		}]
	},
	
]

export default {
	menuList,
}