<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="mold == 'add' ? '40%' : '70%'"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
        <div class="header-box">
            <el-row>
                <el-col :span="16"><b>企业名称:</b> {{tableDetail.companyName}}</el-col>
                <el-col :span="8"><b>企业前缀:</b> {{tableDetail.entPrefix}}</el-col>
            </el-row>
        </div>
        <!-- <div v-if="mold == 'add'">
            <el-form :model="form" status-icon :rules="rules"  ref="form" label-width="100px" class="demo-ruleForm">
                <el-form-item label="收费标准id" prop="standardId">
                    <el-input v-model="form.standardId"></el-input>
                </el-form-item>
                <el-form-item label="合同号" prop="contractNo">
                    <el-input v-model="form.contractNo"></el-input>
                </el-form-item>
                <el-form-item label="订单号" prop="orderNo">
                    <el-input v-model="form.orderNo"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="orderNo">
                    <el-select v-model="form.companyId" placeholder="请选择">
                        <el-option
                        v-for="item in companyList"
                        :key="item.value"
                        :label="item.companyName"
                        :value="item.companyId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="有效时间" prop="effectiveDate">
                    <el-date-picker
                        v-model="form.effectiveDate"
                        value-format="yyyy-MM-dd hh:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="是否交费" prop="isPay">
                    <el-radio-group v-model="form.isPay">
                        <el-radio :label="0">未交费</el-radio>
                        <el-radio :label="1">已交费</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div style="padding-bottom:20px"><div class="hold" @click="submit">提交</div></div>
            </el-form>
        </div> -->


        <div v-if="mold == 'select'" class="update-box">
            <div v-for="list in tableData" :key="list.key" class="list-box">
                <p class="standardName"><b>{{list.standardName}}</b></p>
                <p class="amountState">{{list.amount}}{{list.isplay?'（已支付）':'（未支付）'}}</p>
                <div>
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="合同号码">
                            <el-input v-model="list.contractNo" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="订单号码">
                            <el-input v-model="list.orderNo" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="有效期" v-if="list.beginDate != null">
                            <el-date-picker
                            v-model="list.beginDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            :readonly="true"
                            placeholder="选择日期">
                            </el-date-picker>
                            <span style="padding:0 10px">至</span>
                            <el-date-picker
                            v-model="list.endDate"
                            value-format="yyyy-MM-dd"
                            :readonly="true"
                            type="date"
                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="history_title" v-if="tableDetail.financialHistoryInfo.length>0">历史信息</div>
            <div class="history-box">
                <ul>
                    <li v-for="item in tableDetail.financialHistoryInfo" :key="item.key" class="history_line_box">
                        <el-row :gutter="10">
                            <el-col :span="21">{{item.content}}</el-col>
                            <el-col :span="3">{{item.createData}}</el-col>
                        </el-row>
                    </li>
                </ul>
            </div>
            
        </div>


        <div v-if="mold == 'update'" class="update-box">
            <!-- <div class="add-box" @click="addLine">+ 添加</div> -->
            <div v-for="list in tableData" :key="list.key" class="list-box">
                <p class="standardName"><b>{{list.standardName}}</b></p>
                <p class="amountState">{{list.amount}}{{list.isplay?'（已支付）':'（未支付）'}}</p>
                <div>
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="合同号码">
                            <el-input v-model="list.contractNo"></el-input>
                        </el-form-item>
                        <el-form-item label="订单号码">
                            <el-input v-model="list.orderNo"></el-input>
                        </el-form-item>
                        <el-form-item label="有效期" >
                            <el-date-picker
                            v-model="list.beginDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                            </el-date-picker>
                            <span style="padding:0 10px">至</span>
                            <el-date-picker
                            v-model="list.endDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

            <div class="hold" @click="conserve">保存</div>
        </div>
        

        
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
    data(){
        return{
            name:'',
            contractNum:'',
            orderNum:'',
            endTime:'',
            costTotal:'',
            form:{
                id:'',
                standardId:'',
                statementsId:'',
                contractNo:'',
                orderNo:'',
                effectiveDate:[],
                companyName:'',
                // beginDate:'',
                // endDate:'',
                isPay:0
            },
        }
    },
    computed:{
        ...mapState({
            dialogVisible:state=>state.ReportForm.dialogVisible,
            tableDetail:state=>state.ReportForm.tableDetail,
            mold:state=>state.ReportForm.mold,
            id:state=>state.ReportForm.id,
            companyList:state=>state.ReportForm.companyList,
            title:state=>state.ReportForm.title,
        }),
        tableData:function(){
            return this.tableDetail.financialDetails
        }
        
    },
 
    mounted(){
        
    },
    methods:{
        ...mapMutations('ReportForm',['changeShow']),
        ...mapActions('ReportForm',['getFinancialList','getFinancialDetail','updateFinancialDetail','addFinancialDetail']),

        handleClose(){   //弹出框点击关闭事件
          this.changeShow(false)
        },

        
        getList(){     // 查询表单数据
            const obj = {
				companyName:'',
				entPrefix:'',
				pageNum:1,
				pageSize:20
			}
			// 财务报表列表
			this.getFinancialList(obj)
        },

        // 修改列表提交事件
        conserve(){

            for (let i = 0; i < this.tableData.length; i++) {
                const time = JSON.parse(JSON.stringify(this.tableData[i].beginDate))
                if (Array.isArray(time)) {
                    this.tableData[i].beginDate = time[0].substr(0,10),
                    this.tableData[i].endDate = time[1].substr(0,10)
                }
            }
            const obj = {
                list: this.tableData
            }
            this.updateFinancialDetail(obj).then(()=>{
                this.getList()
                this.changeShow(false)
            })
        },

        changeTime(row){
            if (row.beginDate != null && row.endDate != null) {
                return row.beginDate + ' 到 ' + row.endDate
            }else{
                return '无'
            }
            
        },
        // 修改交费状态数据
        changePay(row){
            if (row.isPay == 0) {
                return '未收费'
            }else if(row.isPay == 1){
                return '已收费'
            }else{
                return
            }
        },
        // 修改到期状态数据
        changeState(row){
            if (row.isPay == 0) {
                return '未到期'
            }else if (row.isPay == 1){
                return '已到期'
            }else{
                return
            }
        },
        // 
        changeStyle({row, column, rowIndex, columnIndex}){
            if(row.isPay == 0 && columnIndex == 6){
                return 'background:red;color:#ffffff'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.add-box{
    font-size: 16px;
    font-weight: 550;
    color: #00508f;
    float: right;
    padding: 8px 0;
}
.update-box{
    padding-bottom: 20px;
    width: 90%;
    margin: 0 auto;
    .list-box{
        position: relative;
        .amountState{
        position: absolute;
            right: 0;
            top: 0;
            color: #e6a23c;
        }
        .standardName{
            height: 40px;
            font-size: 16px;
            color: #409eff;
            line-height: 40px;
            border-bottom: #ddd 1px solid;
            b{
                border-bottom: #409eff 3px solid;
                padding-bottom: 10px;
            }
        }
    }
}
.header-box{
    width: 90%;
    height: 60px;
    line-height: 60px;
    margin: 0 auto;
    font-size: 15px;
}
.hold{
    width: 150px;
    height: 35px;
    line-height: 35px;
    background-color: #409eff;
    color: #fff;
    text-align: center;
    margin: 30px auto 0;
    border-radius: 5px;
}
.history_title{
    height: 40px;
    line-height: 40px;
    background-color: #eee;
    margin-top: 10px;
}
.history-box{
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;
    font-size: 15px;
    .history_line_box{
        line-height: 30px;
        height: 30px;
        list-style-type:none;
        border-bottom: 1px solid #ccc;
    }
}
.table-edit{
    width: 100%;
    text-align: left;
    tr{
        height: 35px;
        line-height: 35px;
        border-bottom: #aaa solid 1px;
        input{
            width: 80%;
            height: 30px;
            font-size: 15px;
            margin: 0 auto;
            border: none;
            outline: none;
        }
    }
    td{
        background-color: #409eff;
        color: #fff;
    }
    th{
        border-bottom: #aaa solid 1px;
    }
}

::v-deep .el-table th.el-table__cell{
    background-color: #409eff;
    color: #fff;
    
}
</style>