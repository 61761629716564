<template>
	<div class="base_box">
		<div class="table_box">
			<el-table size="mini" :max-height="tableMaxHeight" :data="baseTemplate" border>
				<el-table-column type="index" label="序号" width="50"></el-table-column>
				<el-table-column v-for="(item, index) in tableHeader" :prop="item.prop" :label="item.label" :key="index">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	data() {
		return {
			tableHeader:[
				{
					prop: 'name',
					label: '模板列名（中文）',
				},
				{
					prop: 'field',
					label: '模板列名（英文）',
				},
			],
		};
	},
	computed: {
		...mapState({
			baseTemplate: state => state.TempManage.baseTemplate,
		}),
		tableMaxHeight(){
			let h = document.documentElement.clientHeight * 0.9;
			return h - 120;
		},
	},
	methods: {

	},
	mounted() {}
};
</script>

<style lang="scss" scoped>
.base_box {
	width: 100%;
	height: calc(90vh - 80px);
	overflow: auto;
	box-sizing: border-box;
	padding-bottom: 30px;
	.table_box {
		width: 100%;
		height: auto;
		box-sizing: border-box;
		.cursor_style {
			cursor: pointer;
		}
		.noValClass {
			color: #c4c4cc;
		}
	}
}
</style>
