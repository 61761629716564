<template>
  <div class="homePage_box">
    <div class="tatal_data_box">
      <div class="title">数据汇总</div>
      <div class="data_cont">
        <div class="data_item" v-if="userInfo.roleId === 0">
          <span>前缀总数</span>
          <span class="val">{{
            totalObj.prefixCount ? totalObj.prefixCount : "0"
          }}</span>
        </div>
        <div class="data_item" v-if="userInfo.roleId === 0">
          <span>企业总数</span>
          <span class="val">{{
            totalObj.companyCount ? totalObj.companyCount : "0"
          }}</span>
        </div>
        <div class="data_item" v-if="userInfo.roleId === 0">
          <span>申请总数</span>
          <span class="val">{{
            totalObj.applicationCount ? totalObj.applicationCount : "0"
          }}</span>
        </div>
        <div class="data_item">
          <span>智能终端数量</span>
          <span class="val">{{
            totalObj.intelligentTerminalCount ? totalObj.intelligentTerminalCount : "0"
          }}</span>
        </div>
        <div class="data_item">
          <span>标识注册量</span>
          <span class="val">{{
            totalObj.identityCount ? totalObj.identityCount : "0"
          }}</span>
        </div>
        <div class="data_item">
          <span>标识存量</span>
          <span class="val">{{
            totalObj.identityStock ? totalObj.identityStock : "0"
          }}</span>
        </div>
        <div class="data_item">
          <span>标识解析量</span>
          <span class="val">{{
            totalObj.identityResolve ? totalObj.identityResolve : "0"
          }}</span>
        </div>
      </div>
    </div>
    <div class="other_cont_box">
      <div class="charts_box">
        <div class="title_charts">今年标识注册量（个）</div>
        <div class="myChart_class" id="identifyChart"></div>
      </div>
      <div class="message_box">
        <div class="message_item_box">
          <div class="title">前缀注册最新记录（前20条）</div>
          <div class="message_cont_box">
            <div
              class="cont_item"
              v-for="(item, index) in recntIdentity"
              :key="index"
            >
              <div class="text_box">{{ item.identity }}</div>
              <div class="time_box">{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div class="message_item_box">
          <div class="title">标识托管最新记录（前20条）</div>
          <div class="message_cont_box">
            <div
              class="cont_item"
              v-for="(item, index) in recentPrefix"
              :key="index"
            >
              <div class="text_box">{{ item.prefix }}</div>
              <div class="time_box">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      totalObj: {}, //数据汇总
      recentPrefix: [], //标识托管最新记录(前20)
      recntIdentity: [], //前缀注册最新记录(前20)
    };
  },
  watch: {
    homePageData() {
      if (this.homePageData.success && this.homePageData.data) {
        const obj = this.homePageData.data;
        const {
          applicationCount,
          companyCount,
          identityCount,
          identityRegister,
          identityResolve,
          identityStock,
          prefixCount,
          recentPrefix,
          recntIdentity,
          intelligentTerminalCount
        } = obj;
        this.totalObj = {
          applicationCount,
          companyCount,
          identityCount,
          identityResolve,
          identityStock,
          prefixCount,
          intelligentTerminalCount
        };
        this.recentPrefix = recentPrefix;
        this.recntIdentity = recntIdentity;
        this.identifyChartLine(identityRegister); //标识注册趋势
      } else {
        this.totalObj = {};
        this.recentPrefix = [];
        this.recntIdentity = [];
        this.identifyChartLine(); //标识注册趋势
        this.$message({
          showClose: true,
          message: this.homePageData.message,
          type: "error",
        });
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      homePageData: (state) => state.HomeAndCockpit.homePageData,
    }),
  },
  methods: {
    ...mapActions({
      getHomePageData: "HomeAndCockpit/getHomePageData",
    }),
    identifyChartLine(obj) {
      let seriesData = []; //数据
      let dataX = []; //x轴
      if (obj.xAxisUnitList) {
        seriesData = obj.valueList;
        let year = new Date().getFullYear();
        obj.xAxisUnitList.map((item) => {
          dataX.push(`${year}年 ${item}月`);
        });
      }
      //标识注册趋势
      let identifyChart = this.$echarts.init(
        document.getElementById("identifyChart")
      );
      identifyChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#49E7F0",
            },
          },
        },
        xAxis: {
          type: "category",
          data: dataX,
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            data: seriesData,
            type: "line",
            smooth: true,
            lineStyle: {
              //线的颜色
              color: "#00508F",
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.getHomePageData();
  },
};
</script>

<style lang="scss" scoped>
.homePage_box {
  width: 100%;
  height: 100%;
  background-color: #eef4f9;
  box-sizing: border-box;
  padding: 20px;
  .title {
    width: 100%;
    height: 34px;
    color: $theam-bgcolor;
    font-size: 18px;
    font-weight: bold;
  }
  .tatal_data_box {
    width: 100%;
    height: 160px;
    background-color: #fff;
    box-shadow: 0 0 8px 0 #ddd;
    box-sizing: border-box;
    padding: 20px;
    .data_cont {
      width: 100%;
      height: calc(100% - 34px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .data_item {
        width: 15%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .val {
          margin-top: 10px;
          font-size: 30px;
        }
      }
    }
  }
  .other_cont_box {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 10px 20px 20px 20px;
    .charts_box {
      width: 100%;
      height: 300px;
      position: relative;
      .title_charts {
        width: auto;
        height: auto;
        color: $theam-bgcolor;
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        top: 12px;
        left: 0;
      }
      .myChart_class {
        width: 100%;
        height: 100%;
      }
    }
    .message_box {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .message_item_box {
        width: calc(50% - 20px);
        height: 100%;
        .message_cont_box {
          width: 100%;
          height: 356px;
          box-sizing: border-box;
          padding: 5px 20px;
          background-color: #eee;
          overflow: auto;
          .cont_item {
            width: 100%;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .text_box {
              width: calc(100% - 150px);
              height: 100%;
              line-height: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .time_box {
              width: 150px;
              height: auto;
              color: #999;
            }
          }
        }
      }
    }
  }
}
</style>
