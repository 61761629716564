<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="'70%'"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-descriptions class="margin-top" :column="1" v-if="mold != 'add'">
        <el-descriptions-item label="企业名称">{{
          invoiceDetail[0].buyCompanyName
        }}</el-descriptions-item>
        <el-descriptions-item label="发票编号">{{
          invoiceDetail[0].invoiceNo
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="发票信息"></el-descriptions-item> -->
      </el-descriptions>

      <div  class="form-box">
        <el-form
          :model="form"
          :rules="rules"
          status-icon
          ref="form"
          label-width="120px"
          class="demo-ruleForm"
          size="small"
        >
          <div class="title-box">发票信息</div>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="订单号" prop="orderNo">
                <el-input v-model="form.orderNo"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发票号码" prop="invoiceNo">
                <el-input v-model="form.invoiceNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="发票代码" prop="invoiceCode">
                <el-input v-model="form.invoiceCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票时间" prop="createTime">
                <el-date-picker
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="form.createTime"
                  type="datetime"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="货物或应税劳务" prop="goods">
                <el-input v-model="form.goods"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单价" prop="unitPrice">
                <el-input v-model="form.unitPrice"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="金额" prop="amount">
                <el-input v-model="form.amount"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="税额" prop="tax">
                <el-input v-model="form.tax"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="价税" prop="priceTax">
                <el-input v-model="form.priceTax"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发票种类" prop="invoiceType">
                <el-radio-group v-model="form.invoiceType" class="radio-box">
                  <el-radio :label="'普通发票'">普通发票</el-radio>
                  <el-radio :label="'专用发票'">专用发票</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="发票附件" prop="amount">
                <div class="upload_box">
                  <el-upload
                    class="avatar-uploader"
                    :action="invoiceFileUpload"
                    :show-file-list="false"
                    :on-success="companyIDPhotoSuccess"
                    :before-upload="companyIDPhotoUpload"
                  >
                    <img
                      v-if="form.fileUrl"
                      :src="form.fileUrl"
                      class="img_upload"
                    />
                    <div class="companyID_img_box" v-else>
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="title-box">购买方信息</div>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="单位名称" prop="buyCompanyName">
                <el-select v-model="form.buyCompanyName" style="width:100%" size="medium" placeholder="请选择">
                  <el-option
                        v-for="item in companyList"
                        :key="item.companyId"
                        :label="item.companyName"
                        :value="item.companyName">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纳税人识别号" prop="buyIdentityNo">
                <el-input v-model="form.buyIdentityNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="title-box">销售方信息</div>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="单位名称" prop="saleCompanyName">
                <el-select v-model="form.saleCompanyName" style="width:100%" size="medium" placeholder="请选择">
                  <el-option
                        v-for="item in companyList"
                        :key="item.companyId"
                        :label="item.companyName"
                        :value="item.companyName">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纳税人识别号" prop="saleIdentityNo">
                <el-input v-model="form.saleIdentityNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="开户人及账号" prop="saleAccount">
                <el-input v-model="form.saleAccount"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="noEmpty">
                <el-input v-model="form.remarks"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="title-box">快递信息</div>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="快递公司" prop="expressCompany">
                <el-input v-model="form.expressCompany"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="快递号码" prop="expressNo">
                <el-input v-model="form.expressNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="发件人" prop="sender">
                <el-input v-model="form.sender"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发件人地址" prop="senderAddress">
                <el-input v-model="form.senderAddress"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="发件人手机号" prop="senderPhone">
                <el-input v-model="form.senderPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收件人" prop="operate">
                <el-input v-model="form.operate"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="收件人地址" prop="operateAddress">
                <el-input v-model="form.operateAddress"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收件人手机号" prop="phoneNo">
                <el-input v-model="form.phoneNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item size="large">
            <div class="hold-box">
              <el-button class="submit-box" @click="reset('form')"
                >取消</el-button
              >
              <el-button
                type="primary"
                class="submit-box"
                @click="submit('form')"
                >提交</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>

      <!-- <div v-if="mold == 'select'">
        <el-table :data="invoiceDetail">
          <el-table-column
            property="invoiceNo"
            label="发票编号"
          ></el-table-column>
          <el-table-column
            property="invoiceType"
            label="发票种类"
          ></el-table-column>
          <el-table-column
            property="buyCompanyName"
            label="公司名称"
          ></el-table-column>
          <el-table-column
            property="buyIdentityNo"
            label="纳税人识别号"
          ></el-table-column>
          <el-table-column property="goods" label="货物"></el-table-column>
          <el-table-column property="unitPrice" label="单价"></el-table-column>
          <el-table-column property="amount" label="金额"></el-table-column>
          <el-table-column
            property="createTime"
            label="开票时间"
            min-width="100"
          ></el-table-column>
          <el-table-column property="remarks" label="备注"> </el-table-column>
        </el-table>
        <div style="height: 20px"></div>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Url from "@/axios/url.js";
export default {
  data() {
    return {
      form: {
        orderNo: "",
        invoiceNo: "",
        goods: "",
        unitPrice: null,
        amount: null,
        invoiceType: "",
        buyCompanyName: "",
        buyIdentityNo: "",
        remarks: "",
        invoiceState: 0,
        createTime: "",
        invoiceCode: "",
        price: null,
        tax: null,
        priceTax: null,
        fileUrl: "",
        saleCompanyName: "",
        saleIdentityNo: "",
        saleAccount: "",
        expressCompany: "",
        expressNo: "",
        sender: "",
        senderAddress: "",
        senderPhone: "",
        operate: "",
        operateAddress: "",
        phoneNo: "",
      },
      fileList: [{ name: "", url: "" }],
      rules: {
        orderNo: [{ required: true, message: "请输入订单号", trigger: "blur" }],
        invoiceNo: [
          { required: true, message: "请输入发票号码", trigger: "blur" },
        ],
        invoiceCode: [
          { required: true, message: "请输入发票代码", trigger: "blur" },
        ],
        createTime: [
          { required: true, message: "请选择开票时间", trigger: "blur" },
        ],
        goods: [
          { required: true, message: "请输入货物或应税劳务", trigger: "blur" },
        ],
        unitPrice: [
          { required: true, message: "请输入整数单价", trigger: "blur" },
        ],
        amount: [{ required: true, message: "请输入金额", trigger: "blur" }],
        // priceTax:[
        //     { required: true, message: '请输入 数字 + % 结尾的税率', trigger: 'blur', pattern : /^[0-9]+%$/},
        // ],
        tax: [{ required: true, message: "请输入税额", trigger: "blur" }],
        priceTax: [{ required: true, message: "请输入价税", trigger: "blur" }],
        invoiceType: [
          { required: true, message: "请选择发票种类", trigger: "blur" },
        ],
        buyCompanyName:[
          { required: true, message: "请填入购买方公司名", trigger: "blur" },
        ],
        buyIdentityNo:[
          { required: true, message: "请填入购买方纳税人识别号", trigger: "blur" },
        ],
        saleCompanyName:[
          { required: true, message: "请填入销售方公司名", trigger: "blur" },
        ],
        saleIdentityNo:[
          { required: true, message: "请填入销售方纳税人识别号", trigger: "blur" },
        ],
        saleAccount:[
          { required: true, message: "请填入开户人及账号", trigger: "blur" },
        ],
        // expressCompany:[
        //   { required: true, message: "请填入快递公司名称", trigger: "blur" },
        // ],
        // expressNo:[
        //   { required: true, message: "请填入快递编号", trigger: "blur" },
        // ],
        // sender:[
        //   { required: true, message: "请填入发件人", trigger: "blur" },
        // ],
        // senderAddress:[
        //   { required: true, message: "请填入发件地址", trigger: "blur" },
        // ],
        // senderPhone:[
        //   { required: true, message: "请填入发件人手机号", trigger: "blur" },
        // ],
        // operate:[
        //   { required: true, message: "请填入收件人", trigger: "blur" },
        // ],
        // operateAddress:[
        //   { required: true, message: "请填入收件人地址", trigger: "blur" },
        // ],
        // phoneNo:[
        //   { required: true, message: "请填入收件人手机号", trigger: "blur" },
        // ],
      },
    };
  },
  computed: {
    ...mapState({
      dialogVisible: (state) => state.Invoice.dialogVisible,
      mold: (state) => state.Invoice.mold,
      title: (state) => state.Invoice.title,
      invoiceTableData: (state) => state.Invoice.invoiceTableData,
      invoiceDetail: (state) => {
        const arr = [];
        arr.push(state.Invoice.invoiceDetail);
        return arr;
      },
      companyList: (state) => state.Invoice.companyList,
    }),
    invoiceFileUpload: () => {
      return Url.invoiceFileUpload;
    },
  },
  watch:{
    // 给不同弹出框表单不同初始内容
    mold:function(){
      if (this.mold == 'add') {
        this.form = {
          orderNo: "",
          invoiceNo: "",
          goods: "",
          unitPrice: null,
          amount: null,
          invoiceType: "",
          buyCompanyName: "",
          buyIdentityNo: "",
          remarks: "",
          invoiceState: 0,
          createTime: "",
          invoiceCode: "",
          price: null,
          tax: null,
          priceTax: null,
          fileUrl: "",
          saleCompanyName: "",
          saleIdentityNo: "",
          saleAccount: "",
          expressCompany: "",
          expressNo: "",
          sender: "",
          senderAddress: "",
          senderPhone: "",
          operate: "",
          operateAddress: "",
          phoneNo: "",
        }
      }
    },
    invoiceDetail:function (params) {
      this.form = this.invoiceDetail[0]
    },
  
  },
  methods: {
    ...mapMutations("Invoice", ["changeShow", "changeMold"]),
    ...mapActions("Invoice", [
      "addFinancialList",
      "updateFinancialList",
      "getFinancialList",
    ]),
    clean(){
      
    },
    handleClose() {
      this.changeShow(false);
      this.$refs["form"].clearValidate();
    },
    // updateSubmit() {
    //   const obj = this.invoiceDetail[0];
    //   this.updateFinancialList(obj);
    //   this.changeShow(false);
    // },
    reset() {
      //取消添加
      this.$refs["form"].clearValidate();
      this.changeShow(false);
    },
    getList() {
      //获取发票列表
      const obj = {
        orderNo: "",
        invoiceNo: "",
        buyCompanyName: "",
        pageNum: 1,
        pageSize: 10,
      };
      this.getFinancialList(obj);
    },
    companyIDPhotoUpload(file) {
      //发票上传
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传证件图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传证件大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    companyIDPhotoSuccess(res, file) {
      if (res.success) {
        //发票图片上传
        this.form.fileUrl = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认提交", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let _this = this;
              if (this.mold == 'add') {
                this.addFinancialList(this.form).then(() => this.getList());
              }else{
                this.updateFinancialList(this.form).then(() => this.getList());
              }
              
              (_this.form = {
                orderNo: "",
                invoiceNo: "",
                goods: "",
                unitPrice: null,
                amount: null,
                invoiceType: "",
                buyCompanyName: "",
                buyIdentityNo: "",
                remarks: "",
                invoiceState: 0,
                createTime: "",
                invoiceCode: "",
                price: null,
                tax: null,
                priceTax: null,
                fileUrl: "",
                saleCompanyName: "",
                saleIdentityNo: "",
                saleAccount: "",
                expressCompany: "",
                expressNo: "",
                sender: "",
                senderAddress: "",
                senderPhone: "",
                operate: "",
                operateAddress: "",
                phoneNo: "",
              }),
                this.$message({
                  type: "success",
                  message: "提交成功!",
                });
              this.changeShow(false);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
              });
            });
        } else {
          this.$message({
            type: "info",
            message: "请按规则填写",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-top{
  width: 95%;
  margin: 10px auto;
  font-size: 16px;
}
.form-box {
  width: 95%;
  margin: 0 auto;
  .title-box {
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    background-color: #eee;
    margin-bottom: 20px;
    padding-left: 10px;
  }
}
.upload_box {
  width: 180px;
  height: 110px;
  .img_box {
    width: 100%;
    height: 100%;
    position: relative;
    .add_img {
      width: 46px;
      height: 46px;
      position: absolute;
      top: calc(50% - 32px);
      left: calc(50% - 23px);
    }
  }
  .img_upload {
    width: 180px;
    height: 110px;
  }
  .companyID_img_box {
    width: 180px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d9d9d9;
    font-size: 28px;
  }
}
.hold {
  width: 150px;
  height: 35px;
  line-height: 35px;
  background-color: #409eff;
  color: #fff;
  text-align: center;
  margin: 30px auto 0;
  border-radius: 5px;
}
.hold-box {
  width: 350px;
  margin: 0 auto;
  display: flex;
}
.el-button--primary {
  width: 150px;
  margin-bottom: 20px;
}
.submit-box {
  margin: 20px auto;
  width: 150px;
}
.radio-box {
  margin-top: 9px;
}
.update-box {
  input {
    border: none;
    height: 30px;
    font-size: 15px;
    outline: none;
  }
}
::v-deep .el-table th.el-table__cell {
  background-color: #409eff;
  color: #fff;
}
::v-deep .el-form-item__content {
  display: flex;
  // justify-content: center;
}
</style>
